// import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
// import BannerProduct from "../components/BannerProduct";
// import { CategoryList } from "../components/CategoryList";
// import HorizontalCardProduct from "../components/HorizontalCardProduct";
// import VerticalCardProduct from "../components/VerticalCardProduct";
// import BannerProduct2 from "../components/BannerProduct2";
// import { MarketContext } from "../context/MarketContext";
// import LocalMarketModal from "../helpers/LocalMarketSelector";
// import ScrollVerticalProductCard from "../components/ScrollVerticalProductCard";
// import CookieConsentBanner from "../helpers/CookieConsentBanner";
// import CustomerNotice from "../components/CustomerNotice";

// const Home = () => {
//   const { localMarket, updateMarket } = useContext(MarketContext);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [blockPage, setBlockPage] = useState(false); // State to block page interactions
//   const [showCustomerNotice, setShowCustomerNotice] = useState(true); // Control CustomerNotice banner
//   const scrollVerticalRef = useRef();

//   const handleScroll = useCallback(() => {
//     if (scrollVerticalRef.current) {
//       scrollVerticalRef.current.loadMore();
//     }
//   }, []);

//   useEffect(() => {
//     if (!localMarket) {
//       setIsModalOpen(true);
//     } else {
//       setIsModalOpen(false);
//     }
//   }, [localMarket]);

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div className="relative">
//       {/* Overlay for blocking interactions */}
//       {blockPage && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 z-40 pointer-events-none"></div>
//       )}

//         {/* Customer Notice */}
//         {showCustomerNotice && (
//         <CustomerNotice
//           message="🎉 HomeMarket2U launches on 1 January 2025 for customers! Stay tuned for an amazing experience."
//           onClose={() => setShowCustomerNotice(false)}
//         />
//       )}

//       {/* Modal for local market selection */}
//       {isModalOpen && (
//         <LocalMarketModal setMarket={updateMarket} closeModal={closeModal} />
//       )}

//       {/* Page Content */}
//       {localMarket && (
//         <>
//           <CookieConsentBanner setBlockPage={setBlockPage} />
//           <BannerProduct />
//           <CategoryList localMarket={localMarket} />

//           <VerticalCardProduct
//             key={`vertical-${localMarket}-1`}
//             category={"Electronics"}
//             heading={"Best Technology"}
//             localMarket={localMarket}
//             onScroll={handleScroll}
//             ref={scrollVerticalRef}
//           />

//           <ScrollVerticalProductCard
//             key={`verticalScroll-${localMarket}-1`}
//             category={["Arts & Crafts", "Accessories", "Services"]}
//             heading={"Beauty Arts of Home"}
//             localMarket={localMarket}
//           />

//           <VerticalCardProduct
//             key={`vertical-${localMarket}-2`}
//             category={"Food"}
//             heading={"Food and food stuffs"}
//             localMarket={localMarket}
//           />
//           <HorizontalCardProduct
//             key={`horizontal-${localMarket}-1`}
//             category={"Food"}
//             heading={"Best Food Deal"}
//             localMarket={localMarket}
//             ref={scrollVerticalRef}
//           />
//           <HorizontalCardProduct
//             key={`horizontal-${localMarket}-2`}
//             category={"Electronics"}
//             heading={"Best Quality Electronics Best Deal"}
//             localMarket={localMarket}
//             ref={scrollVerticalRef}
//           />
//           <BannerProduct2 />
//           <VerticalCardProduct
//             key={`vertical-${localMarket}-3`}
//             category={["Custom-made", "Clothing", "Beauty Care"]}
//             heading={"General Goods"}
//             localMarket={localMarket}
//           />
//           <VerticalCardProduct
//             key={`vertical-${localMarket}-4`}
//             category={["Clothing", "Beauty Care"]}
//             heading={"General Goods"}
//             localMarket={localMarket}
//           />
//           <HorizontalCardProduct
//             key={`horizontal-${localMarket}-3`}
//             category={"Food"}
//             heading={"Best Food Deal"}
//             localMarket={localMarket}
//             ref={scrollVerticalRef}
//           />
//         </>
//       )}
//     </div>
//   );
// };

// export default Home;

import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import BannerProduct from "../components/BannerProduct";
import { CategoryList } from "../components/CategoryList";
import HorizontalCardProduct from "../components/HorizontalCardProduct";
import VerticalCardProduct from "../components/VerticalCardProduct";
import { MarketContext } from "../context/MarketContext";
import LocalMarketModal from "../helpers/LocalMarketSelector";
import ScrollVerticalProductCard from "../components/ScrollVerticalProductCard";
import CookieConsentBanner from "../helpers/CookieConsentBanner";
import CustomerNotice from "../components/CustomerNotice";
import summaryApi from "../common";


const Home = () => {
  const { localMarket, updateMarket } = useContext(MarketContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockPage, setBlockPage] = useState(false); // State to block page interactions
  const [showCustomerNotice, setShowCustomerNotice] = useState(true); // Control CustomerNotice banner
  const [banner1Data, setBanner1Data] = useState([]);
  const [banner2Data, setBanner2Data] = useState([]);
  const scrollVerticalRef = useRef();

  const handleScroll = useCallback(() => {
    if (scrollVerticalRef.current) {
      scrollVerticalRef.current.loadMore();
    }
  }, []);

  const fetchBanner = async (bannerName) => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.append("bannerName", bannerName);
      queryParams.append("market", localMarket);
  
      const url = `${summaryApi.fetchBanner.url}?${queryParams.toString()}`;
      const response = await fetch(url, {
        method: summaryApi.fetchBanner.method,
      });
  
      if (!response.ok) {
        throw new Error(`Error fetching banners: ${response.statusText}`);
      }
  
      const data = await response.json();
      // Assuming the API response contains an array of banner objects
      return data.data.map((banner) => banner.bannerImage).flat(); 
      // Extract and flatten all banner images
    } catch (error) {
      console.error(`Failed to fetch banner ${bannerName}:`, error);
      return [];
    }
  };
  

  useEffect(() => {
    if (localMarket) {
      const loadBanners = async () => {
        try {
          const webBanner = await fetchBanner("Banner1web");
          const mobileBanner = await fetchBanner("Banner1mobile");
  
          // Set banners directly
          setBanner1Data(webBanner);
          setBanner2Data(mobileBanner);
        } catch (error) {
          console.error("Error loading banners:", error);
        }
      };
  
      loadBanners();
    } else {
      setIsModalOpen(true);
    }
  }, [localMarket]);
  

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div className="relative bg-red-25">
      {/* Overlay for blocking interactions */}
      {blockPage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40 pointer-events-none"></div>
      )}

      {/* Customer Notice */}
      {showCustomerNotice && (
        <CustomerNotice
          message="🎉 Free Delivery to USA and UK, minimum order N100,000  ***   We shall soon open USA to Nigeria market and UK to Nigeria market***"
          onClose={() => setShowCustomerNotice(false)}
        />
      )}

      {/* Modal for local market selection */}
      {isModalOpen && (
        <LocalMarketModal setMarket={updateMarket} closeModal={closeModal} />
      )}

      {/* Page Content */}
      {localMarket && (
        <>
          <CookieConsentBanner setBlockPage={setBlockPage} />

          {/* Show BannerProduct based on screen size */}
          {banner1Data.length > 0 || banner2Data.length > 0 ? (
            <BannerProduct
              bannerData={window.innerWidth >= 768 ? banner1Data : banner2Data}
              fullHeight = {false}
            />
          ) : null}

          <CategoryList localMarket={localMarket} />
          <VerticalCardProduct
            key={`vertical-${localMarket}-3`}
            category={["Food", "Food-stuff-Box", "Wholesale Market"]}
            heading={"Your Homie Goodies"}
            localMarket={localMarket}
          />
         

          <ScrollVerticalProductCard
            key={`verticalScroll-${localMarket}-1`}
            category={["Arts & Crafts", "Accessories", "Custom-made"]}
            heading={"Beauty Arts of Home"}
            localMarket={localMarket}
          />

          <VerticalCardProduct
            key={`vertical-${localMarket}-2`}
            category={"Food"}
            heading={"Food and food stuffs"}
            localMarket={localMarket}
          />
          <HorizontalCardProduct
            key={`horizontal-${localMarket}-1`}
            category={"Food-stuff-Box"}
            heading={"Your Food Box Deals"}
            localMarket={localMarket}
            ref={scrollVerticalRef}
            className="mb-10"
          />
           {banner1Data.length > 0 || banner2Data.length > 0 ? (
            <BannerProduct
              bannerData={window.innerWidth >= 768 ? banner1Data : banner2Data}
            />
          ) : null}
          <HorizontalCardProduct
            key={`horizontal-${localMarket}-2`}
            category={"Clothing"}
            heading={"Bringing Home to Your Home"}
            localMarket={localMarket}
            ref={scrollVerticalRef}
          />

          <VerticalCardProduct
            key={`vertical-${localMarket}-1`}
            category={"Food"}
            heading={"All Native Foods"}
            localMarket={localMarket}
            onScroll={handleScroll}
            ref={scrollVerticalRef}
          />
         
          <VerticalCardProduct
            key={`vertical-${localMarket}-4`}
            category={["Clothing", "Beauty Care"]}
            heading={"General Goods"}
            localMarket={localMarket}
          />
          <HorizontalCardProduct
            key={`horizontal-${localMarket}-3`}
            category={"Furniture"}
            heading={"Amazing Designs"}
            localMarket={localMarket}
            ref={scrollVerticalRef}
          />
        </>
      )}
    </div>
  );
};

export default Home;








