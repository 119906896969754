//before added pagination, filter by role and email search
// import React, { useEffect, useState } from 'react'
// import summaryApi from '../../common'
// import { toast } from "react-toastify";
// import moment from 'moment'
// import { FaUserEdit } from "react-icons/fa";
// import ChangeUserRole from '../../components/ChangeUserRole';
// import { useNavigate } from 'react-router-dom';


// function AllUsers() {
//     const [allUser, setAllUser] = useState([])
//     const [openUpdateRole, setOpenUpdateRole] = useState(false)
//     const [updateUserDetails, setUpdateUserDetails] = useState({
//         name : "",
//         email : "",
//         role : "",
//         _id : "",
//     })
//     const navigate = useNavigate();

//     const fetchAllUser = async() =>{
//         try{
//             const fetchData = await fetch(summaryApi.allUsers.url,{
//                 method : summaryApi.allUsers.method,
//                 credentials : "include",
//             })
    
//             const dataResponse = await fetchData.json()

//             if(dataResponse.success){
//                 setAllUser(dataResponse.data || []);
//             } else{
//                 toast.error(dataResponse.message || "failed to fetch users")
//                 navigate('/error-fallback');
//             }
       
//         }catch(error){
//             console.error("Error fetching allUsers:", error)
//             toast.error("Failed to fetch users");
//             navigate('/error-fallback');

//         }
         
//     }

//     useEffect(()=>{
//         fetchAllUser()
//     },[])

//   return (
//     <div className='bg-white pb-4 h-[100vh] overflow-auto'>
//         <table className='w-full userTable'>
//             <thead>
//                 <tr className='bg-slate-800 text-white'>
//                     <th>Sr</th>
//                     <th>Name</th>
//                     <th>Email</th>
//                     <th>Mobile</th>
//                     <th>Role</th>
//                     <th>Created Date</th>
//                     <th>Action</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {
//                     allUser.map((el, index) =>{
//                         return (
//                             <tr key={el._id || index}>
//                                 <td>{index+1}</td>
//                                 <td>{el?.name}</td>
//                                 <td>{el?.email}</td>
//                                 <td>{el?.mobileNumber}</td>
//                                 <td>{el?.role}</td>
//                                 <td>{moment(el?.createdAt).format('ll')}</td>
//                                 <td>
//                                     <button className='rounded-full hover:bg-green-500 cursor-pointer p-2' 
//                                     onClick={() => {
//                                         setUpdateUserDetails(el)
//                                         setOpenUpdateRole(true)}}>
//                                         <FaUserEdit />
//                                     </button>
//                                 </td>
//                             </tr>
//                         )
//                     })
//                 }
//             </tbody>

           
//         </table>

//         {
//            openUpdateRole && (
//             <ChangeUserRole 
//             onClose={()=>setOpenUpdateRole(false)} 
//             name= {updateUserDetails.name}
//             email={updateUserDetails.email}
//             role={updateUserDetails.role}
//             userId = {updateUserDetails._id}
//             callFunc={fetchAllUser}
//             />
//            )
//         }
//     </div>
//   )
// }

// export default AllUsers

import React, { useEffect, useState } from 'react';
import summaryApi from '../../common';
import { toast } from "react-toastify";
import moment from 'moment';
import { FaUserEdit } from "react-icons/fa";
import ChangeUserRole from '../../components/ChangeUserRole';
import { useNavigate } from 'react-router-dom';

function AllUsers() {
    const [allUser, setAllUser] = useState([]);
    const [openUpdateRole, setOpenUpdateRole] = useState(false);
    const [updateUserDetails, setUpdateUserDetails] = useState({
        name: "",
        email: "",
        role: "",
        _id: "",
    });
    const [filters, setFilters] = useState({ email: "", role: "all", page: 0, limit: 20 });
    const [totalUsers, setTotalUsers] = useState(0);
    const navigate = useNavigate();

    const fetchAllUser = async () => {
        try {
            const query = new URLSearchParams(filters).toString();
            const fetchData = await fetch(`${summaryApi.allUsers.url}?${query}`, {
                method: summaryApi.allUsers.method,
                credentials: "include",
            });

            if (!fetchData.ok) {
                if (fetchData.status >= 500) {
                    navigate("/error-fallback");
                } else {
                    const errorData = await fetchData.json();
                    toast.error(errorData.message || "Error during login");
                    
                }
                return;
              }

            const dataResponse = await fetchData.json();

            if (dataResponse.success) {
                setAllUser(dataResponse.data || []);
                setTotalUsers(dataResponse.totalUsers || 0);
            } 
        } catch (error) {
            console.error("Error fetching allUsers:", error);
            toast.error("Failed to fetch users");
        }
    };

    useEffect(() => {
        fetchAllUser();
    }, [filters]);

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value, page: 0 });
    };

    const handlePageChange = (newPage) => {
        setFilters({ ...filters, page: newPage });
    };

    return (
        <div className='bg-white pb-4 h-[100vh] overflow-auto'>
            <div className="p-4">
                <input
                    type="text"
                    name="email"
                    value={filters.email}
                    placeholder="Search by email"
                    onChange={handleFilterChange}
                    className="border p-2 mr-4"
                />
                <select
                    name="role"
                    value={filters.role}
                    onChange={handleFilterChange}
                    className="border p-2"
                >
                    <option value="all">All</option>
                    <option value="Customer">Customer</option>
                    <option value="Seller">Seller</option>
                    <option value="ADMIN">ADMIN</option>
                </select>
            </div>
            <table className='w-full userTable'>
                <thead>
                    <tr className='bg-slate-800 text-white'>
                        <th>Sr</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Role</th>
                        <th>Created Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {allUser.map((el, index) => (
                        <tr key={el._id || index}>
                            <td>{index + 1 + filters.page * filters.limit}</td>
                            <td>{el?.name}</td>
                            <td>{el?.email}</td>
                            <td>{el?.mobileNumber}</td>
                            <td>{el?.role}</td>
                            <td>{moment(el?.createdAt).format('ll')}</td>
                            <td>
                                <button
                                    className='rounded-full hover:bg-green-500 cursor-pointer p-2'
                                    onClick={() => {
                                        setUpdateUserDetails(el);
                                        setOpenUpdateRole(true);
                                    }}
                                >
                                    <FaUserEdit />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="p-4 flex justify-between">
                <button
                    onClick={() => handlePageChange(Math.max(0, filters.page - 1))}
                    disabled={filters.page === 0}
                    className="bg-gray-300 p-2"
                >
                    Previous
                </button>
                <span>Page {filters.page + 1}</span>
                <button
                    onClick={() => handlePageChange(filters.page + 1)}
                    disabled={(filters.page + 1) * filters.limit >= totalUsers}
                    className="bg-gray-300 p-2"
                >
                    Next
                </button>
            </div>
            {openUpdateRole && (
                <ChangeUserRole
                    onClose={() => setOpenUpdateRole(false)}
                    name={updateUserDetails.name}
                    email={updateUserDetails.email}
                    role={updateUserDetails.role}
                    userId={updateUserDetails._id}
                    callFunc={fetchAllUser}
                />
            )}
        </div>
    );
}

export default AllUsers;
