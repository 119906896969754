


import summaryApi from "../common";
import { toast } from "react-toastify";


const addToCart = async (e, id, selectedSize, color, selectedPriceType, shippingCost) => {
    e?.stopPropagation();
    e?.preventDefault();

    try {
        const response = await fetch(summaryApi.addToCardProduct.url, {
            method: summaryApi.addToCardProduct.method,
            credentials: "include", // Ensure cookies are included in the request
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                productId: id, // Send product ID
                size: selectedSize,
                color,
                shippingCost,
                selectedPriceType 
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to add to cart");
        }

        const responseData = await response.json();

        if (responseData.success) {
            toast.success(responseData.message);
          
        } else {
            toast.error(responseData.message);
        }

        return responseData
        
    } catch (error) {
        console.error("Failed to add to cart:", error);
        toast.error(error.message || "An error occurred while adding to cart.");
    }

   
};

export default addToCart;



