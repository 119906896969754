

// import { useState, useEffect } from "react";
// import { SketchPicker } from 'react-color';

// const sizeOptions = {
//   'Letters e.g XS-XXXL': ["XS", "S", "M", "L", "XL", "XXL", "XXXL"],
//   'Numbers e.g 36-45': ["36", "37", "38", "39", "40", "41", "42", "43", "44", "45"],
//   'Numbers e.g 1-16': ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"],
//   'Create Your Weight or Size Type': []
// };

// export default function ProductSizeColorSelector({ sizes: initialSizes, onSizeChange }) {
//   const [selectedType, setSelectedType] = useState("");
//   const [sizes, setSizes] = useState(initialSizes || []);
//   const [customSize, setCustomSize] = useState("");
//   const [displayColorPicker, setDisplayColorPicker] = useState(false);
//   const [currentColor, setCurrentColor] = useState(null);
//   const [currentSizeIndex, setCurrentSizeIndex] = useState(null);
//   const [pendingColor, setPendingColor] = useState(null); // Stores the color before confirmation

//   useEffect(() => {
//     setSizes(initialSizes || []);
//   }, [initialSizes]);

//   const handleAddSize = () => { 
//     if (!selectedType || !customSize) return;

//     const newSize = { size: customSize, stockLevel: 0, colors: [] };

//     // Add the new size at the TOP instead of the bottom
//     const updatedSizes = [newSize, ...sizes];

//     setSizes(updatedSizes);
//     onSizeChange(updatedSizes);
//     setCustomSize("");
// };


//   const handleAddColor = (index) => {
//     setCurrentSizeIndex(index);
//     setPendingColor(null); // Reset pending color before opening
//     setCurrentColor(sizes[index].colors.length > 0 ? sizes[index].colors[sizes[index].colors.length - 1].color : "#ffffff"); // Use last color or default
//     setDisplayColorPicker(true);
//   };

//   const handleColorChange = (selectedColor) => {
//     setPendingColor(selectedColor.hex); // Store color temporarily without applying it
//   };

//   const handleConfirmColor = () => {
//     if (pendingColor === null || currentSizeIndex === null) return;
    
//     const updatedSizes = [...sizes];
//     if (!updatedSizes[currentSizeIndex].colors) {
//       updatedSizes[currentSizeIndex].colors = [];
//     }
    
//     updatedSizes[currentSizeIndex].colors.push({ color: pendingColor, stockLevel: 0 });
//     setSizes(updatedSizes);
//     onSizeChange(updatedSizes);
//     setDisplayColorPicker(false); // Close the picker after confirming
//   };

//   const handleCloseColorPicker = () => {
//     setDisplayColorPicker(false);
//   };

//   const handleStockChange = (sizeIndex, colorIndex, value) => {
//     const updatedSizes = [...sizes];
//     updatedSizes[sizeIndex].colors[colorIndex].stockLevel = Number(value);
//     setSizes(updatedSizes);
//     onSizeChange(updatedSizes);
//   };

//   const handleRemoveColor = (sizeIndex, colorIndex) => {
//     const updatedSizes = [...sizes];
//     updatedSizes[sizeIndex].colors.splice(colorIndex, 1);
//     setSizes(updatedSizes);
//     onSizeChange(updatedSizes);
//   };

//   const handleRemoveSize = (index) => {
//     const updatedSizes = sizes.filter((_, i) => i !== index);
//     setSizes(updatedSizes);
//     onSizeChange(updatedSizes);
//   };
  
//   // Updating the size selection logic
//   const handleSizeSelection = (e) => {
//     const newSize = { size: e.target.value, stockLevel: 0, colors: [] };
//     handleAddSizeToTop(newSize); // Add the new size at the top
//   };
  
//   const handleAddSizeToTop = (size) => {
//     const updatedSizes = [size, ...sizes]; // Add new size at the top
//     setSizes(updatedSizes);
//     onSizeChange(updatedSizes);
//   };


  
//   return (
//     <div className="p-4 border rounded relative">
//       {/* Size Type Selection */}
//       <label>Product Size Type:</label>
//       <select 
//         value={selectedType} 
//         onChange={(e) => setSelectedType(e.target.value)} 
//         className="p-2 border rounded w-full"
//       >
//         <option value="">Select Size Type</option>
//         {Object.keys(sizeOptions).map((type) => (
//           <option key={type} value={type}>{type}</option>
//         ))}
//       </select>
  
//       {/* Size Selection */}
//       {selectedType && selectedType !== "Create Your Weight or Size Type" && (
//         <div className="mt-3">
//           <label>Select Size:</label>
//           <select className="p-2 border rounded w-full" onChange={handleSizeSelection}>
//             <option value="">Choose Sizes</option>
//             {sizeOptions[selectedType].map((size) => (
//               <option key={size} value={size}>{size}</option>
//             ))}
//           </select>
//         </div>
//       )}
  
//       {/* Custom Size Input */}
//       {selectedType === "Create Your Weight or Size Type" && (
//         <div className="mt-3 flex items-center gap-2">
//           <input 
//             type="text" 
//             placeholder="Enter custom size" 
//             value={customSize} 
//             onChange={(e) => setCustomSize(e.target.value)} 
//             className="p-2 border rounded w-full"
//           />
//           <button 
//             type="button" 
//             onClick={() => handleAddSize(true)} // Pass true to add custom size at the top
//             className="px-3 py-2 bg-blue-500 text-white rounded"
//           >
//             Add
//           </button>
//         </div>
//       )}

  
//       {/* Color Picker */}
//       {displayColorPicker && (
//         <div className=" mt-3 fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 wax-w-[300px]">
//           <div className=" bg-white p-4 rounded shadow-lg flex items-center gap-2 justify-between">
//             <SketchPicker color={pendingColor || currentColor} onChange={handleColorChange} />
            
//             {/* Buttons to the right of the color picker */}
//             <div className=" top-0 right-0 flex flex-col gap-2">
//               <button 
//                 onClick={handleConfirmColor} 
//                 className="px-3 py-1 bg-green-500 text-white rounded"
//               >
//                 Click to Select
//               </button>
//               <button 
//                 onClick={handleCloseColorPicker} 
//                 className="px-3 py-1 bg-red-500 text-white rounded"
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
  
//       {/* Display Sizes, Colors, and Stock */}
//       {sizes.length > 0 && (
//         <div className="mt-4">
//           <h3 className="font-bold bg-green-600 p-1.5 rounded">Sizes, Colors & Stock</h3>
//           {sizes.map((size, sizeIndex) => (
//             <div key={sizeIndex} className="mt-2 border p-2 rounded">
//               <h4 className="max-w-[160px] p-2 rounded-full border border-orange-400 bg-slate-200 text-center">{size.size}</h4>
              
//               {/* Add Color Button */}
//               <button 
//                 type="button" 
//                 onClick={() => handleAddColor(sizeIndex)} 
//                 className="px-2 py-1 bg-green-500 text-white rounded mt-2"
//               >
//                 Add Color
//               </button>
  
//               {/* Delete Size Button */}
//               <button 
//                 type="button" 
//                 onClick={() => handleRemoveSize(sizeIndex)} 
//                 className="px-2 py-1 bg-red-500 text-white rounded ml-2"
//               >
//                 Delete Size
//               </button>
  
//               {/* Colors & Stock Inputs */}
//               {size.colors.length > 0 && size.colors.map((color, colorIndex) => (
//                 <div key={colorIndex} className="flex items-center gap-2 mt-2">
//                   <span className="px-3 py-1 rounded" style={{ backgroundColor: color.color, color: '#fff' }}>{color.color}</span>
//                   <input 
//                     type="number" 
//                     placeholder="Stock Level" 
//                     value={color.stockLevel} 
//                     onChange={(e) => handleStockChange(sizeIndex, colorIndex, e.target.value)} 
//                     className="p-2 border rounded w-20"
//                   />
//                   <button 
//                     type="button" 
//                     onClick={() => handleRemoveColor(sizeIndex, colorIndex)} 
//                     className="px-2 py-1 bg-red-500 text-white rounded"
//                   >
//                     X
//                   </button>
//                 </div>
//               ))}
  
//               {/* If no colors are added */}
//               {size.colors.length === 0 && (
//                 <p className="text-gray-500">No colors added yet.</p>
//               )}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
  
  
// }

import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";

const sizeOptions = {
  "Letters e.g XS-XXXL": ["XS", "S", "M", "L", "XL", "XXL", "XXXL"],
  "Numbers e.g 36-45": [
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45"
  ],
  "Numbers e.g 1-16": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16"
  ],
  "Create Your Weight or Size Type": []
};

export default function ProductSizeColorSelector({
  sizes: initialSizes,
  onSizeChange
}) {
  const [selectedType, setSelectedType] = useState("");
  const [sizes, setSizes] = useState(initialSizes || []);
  const [customSize, setCustomSize] = useState("");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState(null);
  const [currentSizeIndex, setCurrentSizeIndex] = useState(null);
  const [pendingColor, setPendingColor] = useState(null); // Temporarily stores the color before confirmation

  useEffect(() => {
    setSizes(initialSizes || []);
  }, [initialSizes]);

  // Add a new size manually (for custom type)
  const handleAddSize = () => {
    if (!selectedType || !customSize) return;

    const newSize = { size: customSize, stockLevel: 0, colors: [] };

    // Add the new size at the TOP
    const updatedSizes = [newSize, ...sizes];
    setSizes(updatedSizes);
    onSizeChange(updatedSizes);
    setCustomSize("");
  };

  // For pre-defined sizes: when selecting from a dropdown
  const handleSizeSelection = (e) => {
    if (!e.target.value) return;
    const newSize = { size: e.target.value, stockLevel: 0, colors: [] };
    handleAddSizeToTop(newSize);
  };

  const handleAddSizeToTop = (size) => {
    const updatedSizes = [size, ...sizes];
    setSizes(updatedSizes);
    onSizeChange(updatedSizes);
  };

  // Open the color picker for a given size index
  const handleAddColor = (index) => {
    setCurrentSizeIndex(index);
    setPendingColor(null); // Reset pending color before opening
    // If the size already has colors, default to the last color; otherwise, use a default color.
    setCurrentColor(
      sizes[index].colors.length > 0
        ? sizes[index].colors[sizes[index].colors.length - 1].color
        : "#ffffff"
    );
    setDisplayColorPicker(true);
  };

  const handleColorChange = (selectedColor) => {
    setPendingColor(selectedColor.hex);
  };

  const handleConfirmColor = () => {
    if (pendingColor === null || currentSizeIndex === null) return;

    const updatedSizes = [...sizes];
    if (!updatedSizes[currentSizeIndex].colors) {
      updatedSizes[currentSizeIndex].colors = [];
    }
    // Add the new color with an initial stockLevel of 0
    updatedSizes[currentSizeIndex].colors.push({
      color: pendingColor,
      stockLevel: 0
    });
    // Optionally, you could recalc parent's stock here, but since colors have 0, parent's remains the same.
    setSizes(updatedSizes);
    onSizeChange(updatedSizes);
    setDisplayColorPicker(false);
  };

  const handleCloseColorPicker = () => {
    setDisplayColorPicker(false);
  };

  // Update a specific color's stock and then recalc the parent's stock level.
  const handleStockChange = (sizeIndex, colorIndex, value) => {
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].colors[colorIndex].stockLevel = Number(value);
    // Recalculate parent's stockLevel as the sum of its color stock levels.
    updatedSizes[sizeIndex].stockLevel = updatedSizes[sizeIndex].colors.reduce(
      (total, color) => total + color.stockLevel,
      0
    );
    setSizes(updatedSizes);
    onSizeChange(updatedSizes);
  };

  // Update the parent's stock level manually if no colors exist.
  const handleSizeStockChange = (sizeIndex, value) => {
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].stockLevel = Number(value);
    setSizes(updatedSizes);
    onSizeChange(updatedSizes);
  };

  // Remove a color and then recalc parent's stock level.
  const handleRemoveColor = (sizeIndex, colorIndex) => {
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].colors.splice(colorIndex, 1);
    // Recalculate parent's stock level
    updatedSizes[sizeIndex].stockLevel = updatedSizes[sizeIndex].colors.reduce(
      (total, color) => total + color.stockLevel,
      0
    );
    setSizes(updatedSizes);
    onSizeChange(updatedSizes);
  };

  const handleRemoveSize = (index) => {
    const updatedSizes = sizes.filter((_, i) => i !== index);
    setSizes(updatedSizes);
    onSizeChange(updatedSizes);
  };

  return (
    <div className="p-4 border rounded relative">
      {/* Size Type Selection */}
      <label>Product Size Type:</label>
      <select
        value={selectedType}
        onChange={(e) => setSelectedType(e.target.value)}
        className="p-2 border rounded w-full"
      >
        <option value="">Select Size Type</option>
        {Object.keys(sizeOptions).map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>

      {/* Size Selection */}
      {selectedType && selectedType !== "Create Your Weight or Size Type" && (
        <div className="mt-3">
          <label>Select Size:</label>
          <select
            className="p-2 border rounded w-full"
            onChange={handleSizeSelection}
          >
            <option value="">Choose Sizes</option>
            {sizeOptions[selectedType].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Custom Size Input */}
      {selectedType === "Create Your Weight or Size Type" && (
        <div className="mt-3 flex items-center gap-2">
          <input
            type="text"
            placeholder="Enter custom size"
            value={customSize}
            onChange={(e) => setCustomSize(e.target.value)}
            className="p-2 border rounded w-full"
          />
          <button
            type="button"
            onClick={handleAddSize}
            className="px-3 py-2 bg-blue-500 text-white rounded"
          >
            Add
          </button>
        </div>
      )}

      {/* Color Picker */}
      {displayColorPicker && (
        <div className="mt-3 fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 max-w-[300px]">
          <div className="bg-white p-4 rounded shadow-lg flex items-center gap-2 justify-between">
            <SketchPicker
              color={pendingColor || currentColor}
              onChange={handleColorChange}
            />
            {/* Buttons for confirmation */}
            <div className="flex flex-col gap-2">
              <button
                onClick={handleConfirmColor}
                className="px-3 py-1 bg-green-500 text-white rounded"
              >
                Click to Select
              </button>
              <button
                onClick={handleCloseColorPicker}
                className="px-3 py-1 bg-red-500 text-white rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Display Sizes, Colors, and Stock */}
      {sizes.length > 0 && (
        <div className="mt-4">
          <h3 className="font-bold bg-green-600 p-1.5 rounded">
            Sizes, Colors & Stock
          </h3>
          {sizes.map((size, sizeIndex) => (
            <div key={sizeIndex} className="mt-2 border p-2 rounded">
              <h4 className="max-w-[160px] p-2 rounded-full border border-orange-400 bg-slate-200 text-center">
                {size.size}
              </h4>

              {/* Parent Stock Level Input */}
              <div className="flex items-center gap-2 mt-2">
                <label className="whitespace-nowrap">
                  Size Stock Level:
                </label>
                <input
                  type="number"
                  placeholder="Stock Level"
                  value={size.stockLevel}
                  onChange={(e) =>
                    handleSizeStockChange(sizeIndex, e.target.value)
                  }
                  // Disable manual update if colors exist (stock auto-updated)
                  disabled={size.colors.length > 0}
                  className="p-2 border rounded w-20"
                />
              </div>

              {/* Add Color Button */}
              <button
                type="button"
                onClick={() => handleAddColor(sizeIndex)}
                className="px-2 py-1 bg-green-500 text-white rounded mt-2"
              >
                Add Color
              </button>

              {/* Delete Size Button */}
              <button
                type="button"
                onClick={() => handleRemoveSize(sizeIndex)}
                className="px-2 py-1 bg-red-500 text-white rounded ml-2"
              >
                Delete Size
              </button>

              {/* Colors & Stock Inputs */}
              {size.colors.length > 0 &&
                size.colors.map((color, colorIndex) => (
                  <div key={colorIndex} className="flex items-center gap-2 mt-2">
                    <span
                      className="px-3 py-1 rounded"
                      style={{ backgroundColor: color.color, color: "#fff" }}
                    >
                      {color.color}
                    </span>
                    <input
                      type="number"
                      placeholder="Stock Level"
                      value={color.stockLevel}
                      onChange={(e) =>
                        handleStockChange(
                          sizeIndex,
                          colorIndex,
                          e.target.value
                        )
                      }
                      className="p-2 border rounded w-20"
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleRemoveColor(sizeIndex, colorIndex)
                      }
                      className="px-2 py-1 bg-red-500 text-white rounded"
                    >
                      X
                    </button>
                  </div>
                ))}

              {/* Message if no colors are added */}
              {size.colors.length === 0 && (
                <p className="text-gray-500">No colors added yet.</p>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}


