import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaCheckCircle } from 'react-icons/fa';
import Context from '../context';

const PaymentSuccess = () => {
    const [searchParams] = useSearchParams();
    const purchaseId = searchParams.get('purchaseId');
    const { fetchUserdetails, fetchUserAddToCartCount } = useContext(Context);
 
      // Fetch cart count when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            await fetchUserdetails();
            await fetchUserAddToCartCount(); // Ensure it runs properly
        };

        fetchData();
    }, [fetchUserdetails, fetchUserAddToCartCount]); // Dependencies ensure it runs only when these functions change


    return (
        <motion.div
            className="w-full flex flex-col items-center justify-center min-h-screen bg-gray-100"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
        >
            {/* Success Icon */}
            <motion.div
                className="text-green-500 text-6xl mb-6"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
                <FaCheckCircle />
            </motion.div>

            {/* Heading */}
            <motion.h1
                className="text-3xl font-bold text-orange-600 mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
            >
                Payment Successful!
            </motion.h1>

            {/* Message */}
            <motion.p
                className="text-lg text-gray-700 mb-6 text-center max-w-md"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
            >
                Thank you for your purchase. Your Purchases hasve been sent to your email <strong className="text-gray-900">{purchaseId}</strong>
            </motion.p>

            {/* Continue Shopping Button */}
            <motion.a
                href="/"
                className="px-6 py-2 bg-green-500 text-white rounded shadow hover:bg-green-600 transition"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
            >
                Continue Shopping
            </motion.a>
        </motion.div>
    );
};

export default PaymentSuccess;

