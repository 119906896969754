import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import summaryApi from '../common';
import AllVerticalProductCard from '../components/AllVerticalProductCard';

const SearchProduct = () => {
    const query = useLocation();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const searchParams = new URLSearchParams(query.search);
    const market = searchParams.get('market');
    const category = searchParams.get('category');
    const itemName = searchParams.get('itemName');
    //const price = searchParams.get('price');

    const fetchProducts = async () => {
        setLoading(true);
        setProducts([]); // Clear previous products on each new search
        setError(null);  // Clear previous errors

        try {
            const product = await fetch(
                `${summaryApi.searchProduct.url}?market=${market}&category=${category}&itemName=${itemName}`, 
                { headers: { "Content-Type": "application/json" } }
            );

            // Handle non-2xx responses
            if (!product.ok) {
                if (product.status >= 500) {
                    console.log('server error 501:')
                    navigate('/error-fallback');
                } else {
                    // Client-side error
                    const errorData = await product.json();
                    setError(errorData.message || "Error fetching products");
                }
                return;
            }

            const response = await product.json();

            // Handle empty product data
            if (response.data.length === 0) {
                setProducts([]); // Set empty products
                setError("No products found for your search"); // Show user-friendly message
            } else {
                setProducts(response.data); // Set products if found
            }

        } catch (error) {
            console.error("Network or server error:", error);
            navigate('/error-fallback'); // Navigate to error page for critical errors
        } finally {
            setLoading(false);  // Always set loading to false after fetch completes
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [query]); // Re-run the effect if the search query changes

    if (loading) return <p>Loading products...</p>;
    if (error) return <p>{error}</p>; // Show error if present

    return (
      <div className='container customMin-300:max-w-full mx-auto  p-4 customMax-800:mt-11'>
          {loading && (
              <p className='text-lg text-center'>Loading .....</p>
          )}
  
          <h1 className='text-lg font-semibold my-3'>Search Results : {products.length}</h1>
  
          {!loading && products.length === 0 && (
              <p className='bg-white text-lg text-center p-4'>No products found ....</p>
          )}
  
          {!loading && products.length !== 0 && (
              <AllVerticalProductCard loading={loading} data={products} />
          )}
         
      </div>
  );
  
}

export default SearchProduct;



// import summaryApi from '../common'
// import React from "react"

// const SearchProduct = async (market, category, itemName, page = 1) => {
//     try {
//       const product = await fetch(`${summaryApi.searchProduct.url}?page=${page}`, {
//         method: summaryApi.searchProduct.method,
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify({ market, category, itemName })
//       });

//         // Check if the response is successful
//         if (!product.ok) {
//         throw new Error(`Error: ${product.status} - ${product.statusText}`);
//         }

//         const response = await product.json(); // Parse the response
//         if (response.success) {
//           console.log("Products:", response.data);
//           console.log("Current Page:", response.currentPage);
//           console.log("Total Pages:", response.totalPages);
//           console.log("Total Products:", response.totalProducts);
//         }

//     } catch (error) {
//         console.error("Error fetching product:", error);
//         throw error; // Throw the error to handle it elsewhere
//     }
//   return(
//     <div>
        
//     </div>
//   )
// };

// export default  SearchProduct;
