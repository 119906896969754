import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import summaryApi from "../../common";
import { toast } from "react-toastify";
import ExpandableRow from "../../components/updatePurchaseProductCard";
import { useSelector } from 'react-redux';

const SoldProducts = () => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [statusFilter, setStatusFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const limit = 25;
  const currentUser = useSelector((state) => state.user.user);


  const fetchSoldProducts = async (currentPage = 1, status = "all") => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: currentPage + 1, // API may expect 1-based indexing
        limit: limit,
        ...(status !== "all" && { status }),
      });

      const url = `${summaryApi.getOrder.url}?${queryParams.toString()}`;
      const response = await fetch(url, {
        method: summaryApi.getOrder.method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
            if (response.status >= 500) {
                navigate("/error-fallback");
            } else {
                const errorData = await response.json();
                toast.error(errorData.message || "Error during login");
            }
            return;
          }

      const dataResponse = await response.json();
      if (dataResponse.success) {
        setOrders(dataResponse?.data || []);
        setTotalPages(dataResponse.pagination?.totalPages || 1);
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSoldProducts(page, statusFilter);
  }, [page, statusFilter]);

 
  return (
    <div className="container mx-auto p-4 max-h-[100vh] overflow-auto">
      <div className="flex justify-between items-center bg-white p-4 shadow-md rounded-md mb-4">
        <h2 className="font-bold text-xl">Sold Products</h2>
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="border rounded py-2 px-3"
        >
          <option value="all">All</option>
          <option value="pending">Pending</option>
          <option value="dispatched">Dispatched</option>
          <option value="delivered">Delivered</option>
        </select>
      </div>

      <div className="overflow-x-auto bg-white shadow-md rounded-lg p-4">
        {loading ? (
          <p className="text-center text-gray-500">Loading...</p>
        ) : (
          <table className="min-w-full border border-gray-300 rounded-lg">
            <thead className="bg-gray-100">
              <tr>
                <th className="border px-4 py-2">Order Date</th>
                <th className="border px-4 py-2">Order ID</th>
                <th className="border px-4 py-2">Buyer Name</th>
                <th className="border px-4 py-2">Address</th>
                <th className="border px-4 py-2">Total Items</th>
                <th className="border px-4 py-2">Payment Status</th>
                <th className="border px-4 py-2">Status</th>
                <th className="border px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.length > 0 ? (
                orders.map((order) => (
                  <ExpandableRow
                    key={order?._id}
                    product={order}
                    currentSellerId={currentUser?._id} // or however you get the seller's ID
                    role={currentUser?.role} 
                    fetchOrderDetails={() => fetchSoldProducts(page, statusFilter)}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center py-4 text-gray-500">
                    No orders found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      <div className="flex justify-center items-center gap-4 py-4">
        <button
          onClick={() => setPage(page - 1)}
          disabled={page === 0}
          className={`py-2 px-4 rounded ${page === 0 ? "bg-gray-300" : "bg-blue-500 text-white"}`}
        >
          Prev
        </button>
        <span className="text-lg">Page {page + 1} of {totalPages}</span>
        <button
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages - 1}
          className={`py-2 px-4 rounded ${page === totalPages - 1 ? "bg-gray-300" : "bg-blue-500 text-white"}`}
        >
          Next
        </button>
      </div>
    </div>
    
  );
};

export default SoldProducts;

