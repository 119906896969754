// src/App.js

import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import {  toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import summaryApi from './common';
import Context from './context';
import { useDispatch } from 'react-redux';
import { setUserDetails } from './store/userSlice';
import { MarketProvider } from './context/MarketContext'; // Import MarketProvider
import ErrorBoundary from './helpers/ErrorBoundary';
import { useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { setFreelancerDetails } from './store/freelancerSlice';


function App() {
  const dispatch = useDispatch();
  const [cartProductCount, setCartProductCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false); // Track authentication status
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const fetchUserdetails = async () => {
    try {
      setLoading(true);
  
      const dataResponse = await fetch(summaryApi.current_user.url, {
        method: summaryApi.current_user.method,
        credentials: 'include',
      });
  
      if (dataResponse.status === 401) {
        setAuthenticated(false); // Mark user as not authenticated
        dispatch(setUserDetails(null));
  
        // 🚨 Don't navigate to /login unless it's a protected route
        if (currentLocation.pathname.startsWith("/dashboard")) {
          toast.error("Session expired. Please log in again.");
          navigate("/login");
        }
  
        return;
      }
  
      if (!dataResponse.ok) {
        setAuthenticated(false);
        dispatch(setUserDetails(null));
  
        // Allow guests to continue (don't navigate to /login)
        return;
      }

  
      const dataApi = await dataResponse.json();
  
      if (dataApi.success) {
        dispatch(setUserDetails(dataApi.data));
        setAuthenticated(true);
  
      } else {
        setAuthenticated(false);
        dispatch(setUserDetails(null));
      }
    } catch (error) {
      console.error("Error verifying login session:", error);
      setAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchFreelancerdetails = async () => {
    try {
      const dataResponse = await fetch(summaryApi.getFreelancerDetail.url, {
        method: summaryApi.getFreelancerDetail.method,
        credentials: 'include',
      });

      if (!dataResponse.ok) {
        throw new Error("Failed to fetch freelancer details");
      }

      const dataApi = await dataResponse.json();
      if (dataApi.success) {
        dispatch(setFreelancerDetails(dataApi.data));
      }
    } catch (error) {
      console.error("Error fetching freelancer details:", error);
    }
  };

  const fetchUserAddToCartCount = async () => {
    const dataResponse = await fetch(summaryApi.addToCartProductCount.url, {
      method: summaryApi.addToCartProductCount.method,
      credentials: "include",
    });

    const dataApi = await dataResponse.json();
    setCartProductCount(dataApi?.data?.totalItems);
  };

  useEffect(() => {
    const fetchData = async () => {
     
      if (authenticated) {
        await fetchFreelancerdetails(); // Fetch freelancer details only if authenticated
      }
      
    };
  
    fetchData();
  }, [authenticated]); // Runs whenever `authenticated` changes
  
  // useEffect(() => {
  //   fetchUserdetails();
  //   fetchUserAddToCartCount();
  // }, []);

  useEffect(() => {
    if (authenticated) {
        fetchFreelancerdetails();
    }
  }, [authenticated]);

  useEffect(()=>{
    fetchUserdetails();
    fetchUserAddToCartCount();
  },[])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentLocation.pathname]);

 
  return (
    <>
      <ErrorBoundary>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <MarketProvider>
            <Context.Provider
              value={{
                fetchUserdetails,
                fetchFreelancerdetails,
                cartProductCount,
                fetchUserAddToCartCount,
              }}
            >
              <ToastContainer position="top-center" />
              <Header />
              <main className="min-h-[calc(100vh-100px)] pt-16">
                <Outlet />
              </main>
              <Footer />
            </Context.Provider>
          </MarketProvider>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;


// function App() {
//   const dispatch = useDispatch();
//   const [cartProductCount, setCartProductCount] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const currentLocation = useLocation()
//   const navigate = useNavigate();

//   const fetchUserdetails = async () => {
//     try {
//         setLoading(true); // Start loading state

//         const dataResponse = await fetch(summaryApi.current_user.url, {
//             method: summaryApi.current_user.method,
//             credentials: 'include',
//         });

//         if (dataResponse.status === 401) { 
//             dispatch(setUserDetails(null));

//             if (currentLocation.pathname !== "/login") {
//                 toast.error("Session expired. Please log in again.");
//                 navigate("/login");
//             }

//             return;
//         }

//         if (!dataResponse.ok) { 
//             dispatch(setUserDetails(null));
//             toast.error("Something went wrong. Redirecting...");
//             navigate("/");
//             return;
//         }

//         const dataApi = await dataResponse.json();

//         if (dataApi.success) {
//             dispatch(setUserDetails(dataApi.data));
//         } else {
//             dispatch(setUserDetails(null));
//             toast.error(dataApi.message || "An error occurred");
//             navigate("/");
//         }

//     } catch (error) {
//         console.error("Error verifying login session:", error);
//         dispatch(setUserDetails(null));

//         if (currentLocation.pathname !== "/login") {
//             toast.error("Network error. Please check your connection.");
//             navigate("/login");
//         }
//     } finally {
//         setLoading(false); // Ensure loading state is false after fetch
//     }
//   };

//   const { userDetails } = useSelector((state) => state.user);

//   if (loading) {
//       return <div>Loading...</div>; // Prevents rendering while user data is loading
//   }
  
//   if (!userDetails) {
//       return <Navigate to="/login" replace />; // Prevents fallback errors when user is missing
//   }
  

//   //fetch current freelancer details
//   const fetchFreelancerdetails = async () => {
    
//     const dataResponse = await fetch(summaryApi.getFreelancerDetail.url, {
//       method: summaryApi.getFreelancerDetail.method,
//       credentials: 'include',
//     });
//     const dataApi = await dataResponse.json();
  
//     if (dataApi.success) {
//       dispatch(setFreelancerDetails(dataApi.data));
//       return dataApi.data; // Ensure the data is returned
//     }
//   };

//   const fetchUserAddToCartCount = async () => {
//     const dataResponse = await fetch(summaryApi.addToCartProductCount.url, {
//       method: summaryApi.addToCartProductCount.method,
//       credentials: 'include',
//     });

//     const dataApi = await dataResponse.json();
//     setCartProductCount(dataApi?.data?.totalItems);
//   };

//   useEffect(() => {
//     fetchUserdetails();
//     fetchFreelancerdetails();
//     fetchUserAddToCartCount();
//   }, []);

//   useEffect(() => {
//     // Scroll to top on route change
//     window.scrollTo(0, 0);
//   }, [currentLocation.pathname]);

//   return (
//     <>
//       {/* Wrap the entire app with MarketProvider */}
//       <ErrorBoundary>
//       <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
//       <MarketProvider>
//         <Context.Provider
//           value={{
//             fetchUserdetails,
//             fetchFreelancerdetails,
//             cartProductCount,
//             fetchUserAddToCartCount,
//           }}
//         >
//           <ToastContainer position="top-center" />
//             <Header /> {/* Header will now consume MarketContext */}
//             <main className="min-h-[calc(100vh-100px)] pt-16">
            
//               <Outlet /> {/* Render home or other routes */}
              
//             </main>
//             <Footer />
//         </Context.Provider>
//       </MarketProvider>
//       </GoogleOAuthProvider>
//       </ErrorBoundary>
//     </>
//   );
// }

// export default App;


