//code with product search but no pegination

// import React, { useEffect, useRef, useState } from 'react';
// import { UploadProduct } from '../../components/UploadProduct';
// import summaryApi from '../../common';
// import ProductCard from '../../components/ProductCard';
// import EditProduct from '../../components/EditProduct';
// import { useLocation, useNavigate } from 'react-router-dom';
// import SellerDialog from '../Admin/SellerNotice';
// import { toast } from 'react-toastify';
// import { FaSearch } from 'react-icons/fa';

// function AllProducts() {
//   const [openUploadProduct, setOpenUploadProduct] = useState(false);
//   const [openEditProduct, setOpenEditProduct] = useState(false);
//   const [allProduct, setAllProduct] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [openSellerDialog, setOpenSellerDialog] = useState(false);
//   const [page, setPage] = useState(0);
//   const [limit] = useState(20);
//   const [totalPages, setTotalPages] = useState(1);
//   const [loading, setLoading] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [showSearch, setShowSearch] = useState(false);
//   const navigate = useNavigate();
//   const productListRef = useRef(null);
//   const location = useLocation();

//   // Get search parameter from URL (if any)
//   const searchParams = new URLSearchParams(location.search);
//   const itemName = searchParams.get('itemName');

//   // Fetch all products (with pagination)
//   const fetchProducts = async (currentPage = 0) => {
//     setLoading(true);
//     try {
//       const response = await fetch(
//         `${summaryApi.allProduct.url}?page=${currentPage}&limit=${limit}`,
//         {
//           method: summaryApi.allProduct.method,
//           credentials: 'include',
//           headers: { 'content-type': 'application/json' },
//         }
//       );

//       if (!response.ok) {
//         if (response.status >= 500) navigate("/error-fallback");
//         else {
//           const errorData = await response.json();
//           toast.error(errorData.message || "Error fetching products");
//         }
//         return;
//       }

//       const dataResponse = await response.json();
//       if (dataResponse.success) {
//         setAllProduct(dataResponse?.data || []);
//         if (dataResponse.totalProductCount) {
//           setTotalPages(Math.ceil(dataResponse.totalProductCount / limit));
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//       setTimeout(() => {
//         if (productListRef.current) {
//           window.scrollTo({
//             top: productListRef.current.offsetTop - 80,
//             behavior: 'smooth',
//           });
//         }
//       }, 100);
//     }
//   };

//   // Search API call: fetch products matching the query
//   const searchProduct = async (queryStr, currentPage = 0) => {
//     setLoading(true);
//     try {
//       const response = await fetch(
//         `${summaryApi.dashboardSearchProduct.url}?itemName=${queryStr}&page=${currentPage + 1}`,
//         {
//           method: 'GET',
//           credentials: 'include',
//           headers: { "Content-Type": "application/json" },
//         }
//       );
      
//       if (!response.ok) {
//         if (response.status >= 500) navigate("/error-fallback");
//         else {
//           const errorData = await response.json();
//           toast.error(errorData.message || "Error fetching search results");
//         }
//         return;
//       }

//       const dataResponse = await response.json();
//       if (dataResponse.success) {
//         setAllProduct(dataResponse.data || []);
//         // Update totalPages based on search API response
//         setTotalPages(dataResponse.totalPages);
//       }
//     } catch (error) {
//       console.error("Error searching product:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Handle search form submission: update URL so the search effect is triggered
//   const handleSearch = (e) => {
//     e.preventDefault();
//     if (searchQuery) {
//       navigate(`?itemName=${encodeURIComponent(searchQuery)}`);
//     } else {
//       // If search input is cleared, remove search query from URL and reload all products.
//       navigate(location.pathname);
//     }
//   };

//   // Decide which fetch to perform based on URL query param "itemName"
//   useEffect(() => {
//     if (itemName) {
//       searchProduct(itemName, page);
//     } else {
//       fetchProducts(page);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [page, itemName]);

//   // Pagination handlers (only active if not in search mode)
//   const handleNextPage = () => {
//     if (page < totalPages - 1) {
//       setPage(page + 1);
//     }
//   };

//   const handlePrevPage = () => {
//     if (page > 0) {
//       setPage(page - 1);
//     }
//   };

//   // Open edit modal for selected product
//   const handleEditProduct = (product) => {
//     setSelectedProduct(product);
//     setOpenEditProduct(true);
//   };

//   const handleModalClose = () => {
//     setOpenEditProduct(false);
//   };

//   // Update product list after an edit
//   const updateProductInList = (updatedProduct) => {
//     setAllProduct((prevProducts) =>
//       prevProducts.map((product) =>
//         product._id === updatedProduct._id ? { ...updatedProduct } : product
//       )
//     );
//   };

//   // Remove a deleted product from the list
//   const updateDeleteProductInList = (deletedProductId) => {
//     setAllProduct((prevProducts) =>
//       prevProducts.filter((product) => product._id !== deletedProductId)
//     );
//   };

//   return (
//     <div>
//       <div
//         ref={productListRef}
//         className="bg-white flex justify-between py-2 px-4 items-center customMax430:py-1 customMax430:px-1 customMax430:font-[10px]"
//       >
//         <h2 className="font-bold text-lg customMax425:px-0 customMax425:text-sm">
//           All Products
//         </h2>
//         {/* Seller Tutorial Button */}
//         <div className="flex justify-center py-2">
//           <button
//             onClick={() => setOpenSellerDialog(true)}
//             className="bg-orange-500 text-white px-3 py-2.5 text-lg rounded hover:bg-blue-600 transition-all animate-pulse customMax430:text-base customMax430:py-2 customMax430:px-1.5"
//           >
//             Seller Tutorial
//           </button>
//         </div>
//         {/* Search Section */}
//         <div className="flex items-center gap-2">
//           {showSearch ? (
//             <form
//               onSubmit={handleSearch}
//               className="flex items-center border-2 border-orange-500 rounded-full px-2 py-1 hover:bg-orange-600 hover:text-white transition-all"
//             >
//               <input
//                 type="text"
//                 name="search"
//                 className="outline-none px-2"
//                 placeholder="Search products..."
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//               />
//               <button type="submit" className="text-orange-500">
//                 <FaSearch />
//               </button>
//             </form>
//           ) : (
//             <button
//               onClick={() => setShowSearch(true)}
//               className="py-2 px-3 border-2 border-orange-500 rounded-full hover:bg-orange-600 hover:text-white"
//             >
//               <FaSearch />
//             </button>
//           )}
//           {/* Upload Product Button */}
//           <button
//             className="py-2 px-3 border-2 border-orange-500 rounded-full hover:bg-orange-600 hover:text-white transition-all customMax430:text-sm customMax430:px-1.5"
//             onClick={() => setOpenUploadProduct(true)}
//           >
//             Upload Product
//           </button>
//         </div>
//       </div>

//       {/* Product List Container */}
//       <div className="relative">
//         {loading && (
//           <div className="absolute inset-0 bg-gray-200 bg-opacity-50 flex justify-center items-center">
//             <span className="text-lg font-semibold">Loading...</span>
//           </div>
//         )}
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-4 px-4">
//           {allProduct.map((product) => (
//             <ProductCard
//               data={product}
//               key={product._id}
//               onEdit={() => handleEditProduct(product)}
//               updateProductInList={updateDeleteProductInList}
//             />
//           ))}
//         </div>
//       </div>

//       {/* Pagination Controls (hide if search mode is active) */}
//       {!itemName && (
//         <div className="flex justify-center gap-4 py-4 items-center">
//           <button
//             onClick={handlePrevPage}
//             disabled={page === 0}
//             className={`py-2 px-4 rounded ${
//               page === 0 ? 'bg-gray-200' : 'bg-orange-500 text-white'
//             }`}
//           >
//             Prev
//           </button>
//           <span>
//             Page {page + 1} of {totalPages}
//           </span>
//           <button
//             onClick={handleNextPage}
//             disabled={page === totalPages - 1}
//             className={`py-2 px-4 rounded ${
//               page === totalPages - 1 ? 'bg-gray-200' : 'bg-orange-500 text-white'
//             }`}
//           >
//             Next
//           </button>
//         </div>
//       )}

//       {/* Upload Product Component */}
//       {openUploadProduct && (
//         <UploadProduct onClose={() => setOpenUploadProduct(false)} fetchProducts={fetchProducts} />
//       )}

//       {/* Edit Product Component */}
//       {openEditProduct && selectedProduct && (
//         <EditProduct
//           onClose={handleModalClose}
//           updateProductInList={updateProductInList}
//           ProductData={selectedProduct}
//         />
//       )}

//       {/* Seller Information Modal */}
//       <SellerDialog
//         isOpen={openSellerDialog}
//         onClose={() => setOpenSellerDialog(false)}
//         onRedirect={() =>
//           window.open(
//             'https://www.youtube.com/results?search_query=how+to+take+pictures+for+ecommerce+site+products',
//             '_blank'
//           )
//         }
//       />
//     </div>
//   );
// }

// export default AllProducts;


import React, { useEffect, useRef, useState } from 'react';
import { UploadProduct } from '../../components/UploadProduct';
import summaryApi from '../../common';
import ProductCard from '../../components/ProductCard';
import EditProduct from '../../components/EditProduct';
import { useLocation, useNavigate } from 'react-router-dom';
import SellerDialog from '../Admin/SellerNotice';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';

function AllProducts() {
  const [openUploadProduct, setOpenUploadProduct] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const [allProduct, setAllProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openSellerDialog, setOpenSellerDialog] = useState(false);
  const [page, setPage] = useState(0); // zero-based page index for frontend
  const [limit] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  const productListRef = useRef(null);
  const location = useLocation();

  // Read the search parameter from the URL, if any.
  const searchParams = new URLSearchParams(location.search);
  const itemName = searchParams.get('itemName');

  // Fetch all products with pagination (for non-search mode)
  const fetchProducts = async (currentPage = 0) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${summaryApi.allProduct.url}?page=${currentPage}&limit=${limit}`,
        {
          method: summaryApi.allProduct.method,
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
        }
      );

      if (!response.ok) {
        if (response.status >= 500) navigate("/error-fallback");
        else {
          const errorData = await response.json();
          toast.error(errorData.message || "Error fetching products");
        }
        return;
      }

      const dataResponse = await response.json();
      if (dataResponse.success) {
        setAllProduct(dataResponse.data || []);
        if (dataResponse.totalProductCount) {
          setTotalPages(Math.ceil(dataResponse.totalProductCount / limit));
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setTimeout(() => {
        if (productListRef.current) {
          window.scrollTo({
            top: productListRef.current.offsetTop - 80,
            behavior: 'smooth',
          });
        }
      }, 100);
    }
  };

  // Fetch search results with pagination
  const searchProduct = async (queryStr, currentPage = 0) => {
    setLoading(true);
    try {
      // API expects a one-based page index so we send (page + 1)
      const response = await fetch(
        `${summaryApi.dashboardSearchProduct.url}?itemName=${encodeURIComponent(queryStr)}&page=${currentPage + 1}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: { "Content-Type": "application/json" },
        }
      );
      
      if (!response.ok) {
        if (response.status >= 500) navigate("/error-fallback");
        else {
          const errorData = await response.json();
          toast.error(errorData.message || "Error fetching search results");
        }
        return;
      }

      const dataResponse = await response.json();
      if (dataResponse.success) {
        setAllProduct(dataResponse.data || []);
        // Update totalPages based on search API response
        setTotalPages(dataResponse.totalPages);
      }
    } catch (error) {
      console.error("Error searching product:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle search form submission: reset page and update URL with the search query.
  const handleSearch = (e) => {
    e.preventDefault();
    setPage(0);
    if (searchQuery) {
      navigate(`?itemName=${encodeURIComponent(searchQuery)}`);
    } else {
      navigate(location.pathname);
    }
  };

  // Fetch data when page or search parameter changes.
  useEffect(() => {
    if (itemName) {
      searchProduct(itemName, page);
    } else {
      fetchProducts(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, itemName]);

  // Pagination handlers (active for both search and non-search mode)
  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  // Open edit modal for selected product
  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setOpenEditProduct(true);
  };

  const handleModalClose = () => {
    setOpenEditProduct(false);
  };

  // Update product list after an edit
  const updateProductInList = (updatedProduct) => {
    setAllProduct((prevProducts) =>
      prevProducts.map((product) =>
        product._id === updatedProduct._id ? { ...updatedProduct } : product
      )
    );
  };

  // Remove a deleted product from the list
  const updateDeleteProductInList = (deletedProductId) => {
    setAllProduct((prevProducts) =>
      prevProducts.filter((product) => product._id !== deletedProductId)
    );
  };

  // return (
  //   <div>
  //     <div
  //       ref={productListRef}
  //       className="bg-white flex justify-between py-2 px-4 items-center customMax430:py-1 customMax430:px-1 customMax430:font-[10px]"
  //     >
  //       <h2 className="font-bold text-lg customMax425:px-0 customMax425:text-sm">
  //         All Products
  //       </h2>
  //       {/* Seller Tutorial Button */}
  //       <div className="flex justify-center py-2">
  //         <button
  //           onClick={() => setOpenSellerDialog(true)}
  //           className="bg-orange-500 text-white px-3 py-2.5 text-lg rounded hover:bg-blue-600 transition-all animate-pulse customMax430:text-base customMax430:py-2 customMax430:px-1.5"
  //         >
  //           Seller Tutorial
  //         </button>
  //       </div>
  //       {/* Search Section */}
  //       <div className="flex items-center gap-2">
  //         {showSearch ? (
  //           <form
  //             onSubmit={handleSearch}
  //             className="flex items-center border-2 border-orange-500 rounded-full px-2 py-1 hover:bg-orange-600 transition-all"
  //           >
  //             <input
  //               type="text"
  //               name="search"
  //               className="outline-none px-2"
  //               placeholder="Search products..."
  //               value={searchQuery}
  //               onChange={(e) => setSearchQuery(e.target.value)}
  //             />
  //             <button type="submit" className="text-orange-500">
  //               <FaSearch />
  //             </button>
  //           </form>
  //         ) : (
  //           <button
  //             onClick={() => setShowSearch(true)}
  //             className="py-2 px-3 border-2 border-orange-500 rounded-full hover:bg-orange-600 hover:text-white"
  //           >
  //             <FaSearch />
  //           </button>
  //         )}
  //         {/* Upload Product Button */}
  //         <button
  //           className="py-2 px-3 border-2 border-orange-500 rounded-full hover:bg-orange-600 hover:text-white transition-all customMax430:text-sm customMax430:px-1.5"
  //           onClick={() => setOpenUploadProduct(true)}
  //         >
  //           Upload Product
  //         </button>
  //       </div>
  //     </div>

  //     {/* Product List Container */}
  //     <div className="relative">
  //       {loading && (
  //         <div className="absolute inset-0 bg-gray-200 bg-opacity-50 flex justify-center items-center">
  //           <span className="text-lg font-semibold">Loading...</span>
  //         </div>
  //       )}
  //       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-4 px-4">
  //         {allProduct.map((product) => (
  //           <ProductCard
  //             data={product}
  //             key={product._id}
  //             onEdit={() => handleEditProduct(product)}
  //             updateProductInList={updateDeleteProductInList}
  //           />
  //         ))}
  //       </div>
  //     </div>

  //     {/* Pagination Controls */}
  //     {totalPages > 1 && (
  //       <div className="flex justify-center gap-4 py-4 items-center">
  //         <button
  //           onClick={handlePrevPage}
  //           disabled={page === 0}
  //           className={`py-2 px-4 rounded ${
  //             page === 0 ? 'bg-gray-200' : 'bg-orange-500 text-white'
  //           }`}
  //         >
  //           Prev
  //         </button>
  //         <span>
  //           Page {page + 1} of {totalPages}
  //         </span>
  //         <button
  //           onClick={handleNextPage}
  //           disabled={page === totalPages - 1}
  //           className={`py-2 px-4 rounded ${
  //             page === totalPages - 1 ? 'bg-gray-200' : 'bg-orange-500 text-white'
  //           }`}
  //         >
  //           Next
  //         </button>
  //       </div>
  //     )}

  //     {/* Upload Product Component */}
  //     {openUploadProduct && (
  //       <UploadProduct onClose={() => setOpenUploadProduct(false)} fetchProducts={fetchProducts} />
  //     )}

  //     {/* Edit Product Component */}
  //     {openEditProduct && selectedProduct && (
  //       <EditProduct
  //         onClose={handleModalClose}
  //         updateProductInList={updateProductInList}
  //         ProductData={selectedProduct}
  //       />
  //     )}

  //     {/* Seller Information Modal */}
  //     <SellerDialog
  //       isOpen={openSellerDialog}
  //       onClose={() => setOpenSellerDialog(false)}
  //       onRedirect={() =>
  //         window.open(
  //           'https://www.youtube.com/results?search_query=how+to+take+pictures+for+ecommerce+site+products',
  //           '_blank'
  //         )
  //       }
  //     />
  //   </div>
  // );

  return (
    <div>
      {/* Top Section */}
      <div
        ref={productListRef}
        className="bg-white flex justify-between items-center py-2 px-4 flex-wrap gap-2 customMax450:py-1 customMax450:px-1 customMax450:font-[10px]"
      >
        {/* Title */}
        <h2 className="font-bold text-lg customMax450:px-0 customMax450:text-sm">All Products</h2>
  
        {/* Seller Tutorial Button */}
        <div className="flex justify-center">
          <button
            onClick={() => setOpenSellerDialog(true)}
            className="bg-orange-500 text-white px-3 py-2.5 text-lg rounded hover:bg-blue-600 transition-all animate-pulse"
          >
            Seller Tutorial
          </button>
        </div>
  
        {/* Upload Product Button */}
        <button
          className="py-2 px-3 border-2 border-orange-500 rounded-full hover:bg-orange-600 transition-all customMax450:text-sm customMax450:px-1.5"
          onClick={() => setOpenUploadProduct(true)}
        >
          Upload Product
        </button>
      </div>
  
      {/* Search Section - Moves below on small screens */}
      <div className="flex justify-center mt-2 md:mt-0 md:flex-row">
        {showSearch ? (
          <form
            onSubmit={handleSearch}
            className="flex items-center border-2 border-orange-500 rounded-full px-2 py-1 hover:bg-orange-600  transition-all w-full sm:w-auto"
          >
            <input
              type="text"
              name="search"
              className="outline-none px-2 w-full sm:w-auto"
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button type="submit" className="text-orange-500">
              <FaSearch />
            </button>
          </form>
        ) : (
          <button
            onClick={() => setShowSearch(true)}
            className="py-2 px-3 border-2 border-orange-500 rounded-full hover:bg-orange-600 hover:text-white"
          >
            <FaSearch />
          </button>
        )}
      </div>
  
      {/* Product List Container */}
      <div className="relative">
        {loading && (
          <div className="absolute inset-0 bg-gray-200 bg-opacity-50 flex justify-center items-center">
            <span className="text-lg font-semibold">Loading...</span>
          </div>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-4 px-4">
          {allProduct.map((product) => (
            <ProductCard
              data={product}
              key={product._id}
              onEdit={() => handleEditProduct(product)}
              updateProductInList={updateDeleteProductInList}
            />
          ))}
        </div>
      </div>
  
      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="flex justify-center gap-4 py-4 items-center">
          <button
            onClick={handlePrevPage}
            disabled={page === 0}
            className={`py-2 px-4 rounded ${
              page === 0 ? "bg-gray-200" : "bg-orange-500 text-white"
            }`}
          >
            Prev
          </button>
          <span>
            Page {page + 1} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={page === totalPages - 1}
            className={`py-2 px-4 rounded ${
              page === totalPages - 1 ? "bg-gray-200" : "bg-orange-500 text-white"
            }`}
          >
            Next
          </button>
        </div>
      )}
  
      {/* Upload Product Component */}
      {openUploadProduct && (
        <UploadProduct onClose={() => setOpenUploadProduct(false)} fetchProducts={fetchProducts} />
      )}
  
      {/* Edit Product Component */}
      {openEditProduct && selectedProduct && (
        <EditProduct
          onClose={handleModalClose}
          updateProductInList={updateProductInList}
          ProductData={selectedProduct}
        />
      )}
  
      {/* Seller Information Modal */}
      <SellerDialog
        isOpen={openSellerDialog}
        onClose={() => setOpenSellerDialog(false)}
        onRedirect={() =>
          window.open(
            "https://www.youtube.com/results?search_query=how+to+take+pictures+for+ecommerce+site+products",
            "_blank"
          )
        }
      />
    </div>
  );
  

}

export default AllProducts;


//old working code before search product

// import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { UploadProduct } from '../../components/UploadProduct';
// import summaryApi from '../../common';
// import ProductCard from '../../components/ProductCard';
// import EditProduct from '../../components/EditProduct'; // Import EditProduct
// import { useLocation, useNavigate } from 'react-router-dom';
// import SellerDialog from '../Admin/SellerNotice';
// import { toast } from 'react-toastify';
// import { FaSearch } from "react-icons/fa";

// function AllProducts() {
//   const [openUploadProduct, setOpenUploadProduct] = useState(false);
//   const [openEditProduct, setOpenEditProduct] = useState(false); // Control EditProduct modal
//   const [allProduct, setAllProduct] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState(null); // Track the selected product for editing
//   const [openSellerDialog, setOpenSellerDialog] = useState(false); // Control SellerDialog modal
//   const [page, setPage] = useState(0); // Track current page
//   const [limit] = useState(20); // Limit can be a constant or set dynamically
//   const [totalPages, setTotalPages] = useState(1); // Track total pages
//   const [loading, setLoading] = useState(false); 
//   const navigate = useNavigate();
//   const [data, setData] = useState("")

//   const productListRef = useRef(null);
//   const query = useLocation();

//   // Fetch products from the server with pagination
//   const fetchProducts = async (currentPage = 0) => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${summaryApi.allProduct.url}?page=${currentPage}&limit=${limit}`, {
//         method: summaryApi.allProduct.method,
//         credentials: 'include',
//         headers: {
//           'content-type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         if (response.status >= 500) {
//             navigate("/error-fallback");
//         } else {
//             const errorData = await response.json();
//             toast.error(errorData.message || "Error during login");
//         }
//         return;
//       }

//       const dataResponse = await response.json();

//       if (dataResponse.success) {
//         setAllProduct(dataResponse?.data || []);
//         if (dataResponse.totalProductCount) {
//           setTotalPages(Math.ceil(dataResponse.totalProductCount / limit));
//         }
//       } 
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//       // setTimeout(() => {
//       //   if (productListRef.current) {
//       //     productListRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
//       //   }
//       // }, 100);
//       setTimeout(() => {
//         if (productListRef.current) {
//           window.scrollTo({
//             top: productListRef.current.offsetTop - 80, // Adjust for header height
//             behavior: 'smooth',
//           });
//         }
//       }, 100);

//     }
//   };
 

//   // Fetch products on initial load or when page changes
//   useEffect(() => {
//     fetchProducts(page);
//   }, [page]);

//   // Handle next page
//   const handleNextPage = () => {
//     if (page < totalPages - 1) {
//       setPage(page + 1);
//     }
//   };

//   // Handle previous page
//   const handlePrevPage = () => {
//     if (page > 0) {
//       setPage(page - 1);
//     }
//   };

//  // Update product in the product list after editing
//  const updateProductInList = (updatedProduct) => {
//   setAllProduct((prevProducts) => {
//     const newProducts = prevProducts.map((product) =>
//       product._id === updatedProduct._id ? { ...updatedProduct } : product
//     );
//     return [...newProducts]; // Ensures a new reference
//   });
// };


//   const updateDeleteProductInList = (deletedProductId) => {
//     setAllProduct((prevProducts) =>
//       prevProducts.filter((product) => product._id !== deletedProductId)
//     );
//   };
 
  
//   // Open edit product modal with selected product
//   const handleEditProduct = (product) => {
 
//     setSelectedProduct(product);
//     setOpenEditProduct(true);
//   };

//   const handleModalClose = () => {
//     setOpenEditProduct(false);
//   };

//   const searchParams = new URLSearchParams(query.search);
//   const itemName = searchParams.get('itemName');

//   const searchProduct = async () =>{
//       try {
//         const response = await fetch(`${summaryApi.dashboardSearchProduct.url}?itemName=${itemName}`, 
//           { headers: { "Content-Type": "application/json" } },
//           {credentials: 'include'},
          
//         );
  
//         if (!response.ok) {
//           if (response.status >= 500) {
//               navigate("/error-fallback");
//           } else {
//               const errorData = await response.json();
//               toast.error(errorData.message || "Error during login");
//           }
//           return;
//         }
  
//         const dataResponse = await response.json();
  
//         if (dataResponse.success) {
//             toast.success(dataResponse?.message);

//         } 
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     }

//     const handlechange = (e)=>{
//       const {name, value} = e.target
//       setData((preve) => ({
//         ...preve,
//         [name] : value
//       }))
//     }

    
//   return (
//     <div>
//       <div ref={productListRef} className='bg-white flex justify-between  py-2 px-4 items-center customMax430:py-1 customMax430:px-1 customMax430:font-[10px]' >
//         <h2 className='font-bold text-lg customMax425:px-0 customMax425:text-sm'>All Products</h2>
//          {/* Button to Trigger SellerDialog */}
//         <div className="flex justify-center py-2">
//           <button
//             onClick={() => setOpenSellerDialog(true)}
//             className="bg-orange-500 text-white px-3 py-2.5 text-lg rounded hover:bg-blue-600 transition-all animate-pulse customMax430:text-base customMax430:py-2 customMax430:px-1.5 "
//           >
//             Seller Tutorial
//           </button>
//         </div >
//         <div className='py-2 px-3 border-2 border-orange-500 rounded-full hover:bg-orange-600 hover:text-white'> 
//           < FaSearch />
//         </div>
     
//         <button
//           className='py-2 px-3 border-2 border-orange-500 rounded-full hover:bg-orange-600 hover:text-white transition-all customMax430:text-sm customMax430:px-1.5'
//           onClick={() => setOpenUploadProduct(true)}
//         >
//           Upload Product
//         </button>
//       </div>

    
//       {/* Product List Container */}
//       <div className="relative" >
//         {loading && (
//           <div className="absolute inset-0 bg-gray-200 bg-opacity-50 flex justify-center items-center">
//             <span className="text-lg font-semibold">Loading...</span>
//           </div>
//         )}
        
//         <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-4 px-4'>
//           {allProduct.map((product, index) => (
//             <ProductCard
//               data={product}
//               key={product._id}  // Use product._id for uniqueness
//               onEdit={() => handleEditProduct(product)}
//               updateProductInList={updateDeleteProductInList}
//             />
//           ))}
//         </div>
//       </div>

//       {/* Pagination controls */}
//       <div className='flex justify-center gap-4 py-4 items-center'>
//         <button
//           onClick={handlePrevPage}
//           disabled={page === 0}
//           className={`py-2 px-4 rounded ${page === 0 ? 'bg-gray-200' : 'bg-orange-500 text-white'}`}
//         >
//           Prev
//         </button>
//         <span>
//           Page {page + 1} of {totalPages}
//         </span>
//         <button
//           onClick={handleNextPage}
//           disabled={page === totalPages - 1}
//           className={`py-2 px-4 rounded ${page === totalPages - 1 ? 'bg-gray-200' : 'bg-orange-500 text-white'}`}
//         >
//           Next
//         </button>
//       </div>

//       {/* Upload product component */}
//       {openUploadProduct && (
//         <UploadProduct onClose={() => setOpenUploadProduct(false)} fetchProducts={fetchProducts} />
//       )}

//       {/* Edit product component */}
//       {openEditProduct &&  selectedProduct && (
//         <EditProduct
//           onClose={handleModalClose}
//           updateProductInList={updateProductInList} // Pass update function to EditProduct
//           ProductData={selectedProduct}
//         />
//       )}

//       {/* Seller Information Modal */}
//       <SellerDialog
//         isOpen={openSellerDialog}
//         onClose={() => setOpenSellerDialog(false)}
//         onRedirect={() =>
//           window.open(
//             'https://www.youtube.com/results?search_query=how+to+take+pictures+for+ecommerce+site+products',
//             '_blank'
//           )
//         }
//       />
//     </div>
//   );
// }

// export default AllProducts;





