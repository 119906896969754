

// import React, { useState } from 'react';
// import { CiEdit } from "react-icons/ci";
// import EditProduct from './EditProduct';
// import displayPrice from '../helpers/FormatPrice';

// const ProductCard = ({ data, updateProductInList }) => {
//     const [editProduct, setEditProduct] = useState(false);

//     const handleEditClick = () => {
//         setEditProduct(true);
//     };

//     const handleEditClose = () => {
//         setEditProduct(false);
//     };

//     return (
//         <div className='bg-white p-4 rounded border border-white w-full max-w-md sm:max-w-full sm:px-4 md:px-0 mx-auto mb-4'>
//             <div className='w-full px-2'>
//                 {/* Product Image */}
//                 <div className='w-full h-48 flex justify-center items-center'>
//                     <img 
//                         src={data?.productImage[0]}
//                         alt={data?.itemName}
//                         className='mx-auto object-contain h-full w-full rounded'
//                     />
//                 </div>

//                 {/* Product Details */}
//                 <h1 className='mt-4 text-lg font-semibold text-ellipsis line-clamp-1'>{data?.itemName}</h1>
//                 <p className='text-sm text-gray-500 text-ellipsis line-clamp-1'>{data?.itemDescription}</p>

//                 {/* Admin Comment (only for rejected products) */}
//                 {data.adminComment && (
//                     <p className='mt-2 text-sm text-red-600'>
//                         <strong>Admin Comment:</strong> {data.adminComment}
//                     </p>
//                 )}

//                 {/* Product Price and Edit Button */}
//                 <div className='mt-2 flex justify-between items-center'>
//                     <div className='font-semibold text-xl'>
//                         {displayPrice(data?.price, data?.currency)}
//                     </div>
//                     <div
//                         className='w-fit bg-green-100 hover:bg-green-600 hover:text-white p-2 rounded cursor-pointer'
//                         onClick={handleEditClick}
//                     >
//                         <CiEdit className="text-lg" />
//                     </div>
//                 </div>
//             </div>

//             {/* Edit Product Modal */}
//             {editProduct && (
//                 <EditProduct 
//                     ProductData={data}
//                     onClose={handleEditClose}
//                     updateProductInList={updateProductInList}
//                 />
//             )}
//         </div>
//     );
// };

// export default ProductCard;

import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { MdDelete, MdEmail } from "react-icons/md";
import EditProduct from "./EditProduct";
import displayPrice from "../helpers/FormatPrice";
import ContactSellerModal from "../helpers/ContactSellerModal";
import { useNavigate } from "react-router-dom";
import summaryApi from "../common";
import { toast } from "react-toastify";
import ROLE from '../common/role';
import { useSelector } from 'react-redux';

const ProductCard = ({ data, updateProductInList }) => {
  const [editProduct, setEditProduct] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [sellerInfo, setSellerInfo] = useState({ name: "", email: "", mobile: "" });
  const user = useSelector(state => state?.user?.user);
  const [showActions, setShowActions] = useState(false);

  //const handleEditClick = () => setEditProduct(true);
  const handleEditClose = () => setEditProduct(false);

  const navigate =useNavigate();

  // When user clicks on the edit icon, toggle the dropdown.
  const toggleActions = () => {
    setShowActions((prev) => !prev);
  };

  // Open the edit modal and hide the dropdown.
  const handleEditClick = () => {
    setEditProduct(true);
    setShowActions(false);
  };


  const handleContactClick = () => {
    if (data?.createdBy) {
      setSellerInfo({
        name: data.createdBy.name || "N/A",
        email: data.createdBy.email || "N/A",
        mobile: data.createdBy.mobileNumber || "N/A",
      });
      setShowContactModal(true);
    }
  };

 
  const contactSeller = async (sellerEmail, subject, message) =>{
    try {
      const response = await fetch(summaryApi.contactSeller.url, {
        method: summaryApi.contactSeller.method,
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          sellerEmail,
          subject,
          message
        })
      });

      if (!response.ok) {
        if (response.status >= 500) {
            navigate("/error-fallback");
        } else {
            const errorData = await response.json();
            toast.error(errorData.message || "Error during login");
        }
        return;
      }

      const dataResponse = await response.json();

      if (dataResponse.success) {
          toast.success(dataResponse?.message);
      } 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }


  const deleteProduct = async () =>{
    try {
    const response = await fetch(summaryApi.dashboardDeleteProduct.url, {
        method: summaryApi.dashboardDeleteProduct.method,
        credentials: 'include',
        headers: {
        'content-type': 'application/json',
        },
        body: JSON.stringify({id : data._id})
    });

    if (!response.ok) {
        
        const errorData = await response.json();
        toast.error(errorData.message || "Error during login");
      
    }

    const dataResponse = await response.json();

    if (dataResponse.success) {
        toast.success(dataResponse?.message);
        updateProductInList(data._id);
        setShowActions(false)
       
    } 
    } catch (error) {
    console.error("Error fetching data:", error);
    }
  }
  return (
    <div className="bg-white p-4 rounded border border-white w-full max-w-md mx-auto mb-4">
      <div className="w-full px-2">
        {/* Product Image */}
        <div className="w-full h-48 flex justify-center items-center relative">
          <img
            src={data?.productImage[0]}
            alt={data?.itemName}
            className="mx-auto object-contain h-full w-full rounded"
          />
          {/* Contact Seller Icon */}
         { user?.role === ROLE.ADMIN && (<MdEmail
            className="absolute top-2 right-2 text-orange-500 cursor-pointer hover:text-orange-600"
            size={22}
            onClick={handleContactClick}
          />)}
        </div>

        {/* Product Details */}
        <h1 className="mt-4 text-lg font-semibold text-ellipsis line-clamp-1">{data?.itemName}</h1>
        <p className="text-sm text-gray-500 text-ellipsis line-clamp-1">{data?.itemDescription}</p>

        {/* Product Price and Edit Button */}
        <div className="mt-2 flex justify-between items-center">
          <div className="font-semibold text-xl">
            {displayPrice(data?.price, data?.currency)}
          </div>
          {/* <div className="w-fit bg-green-100 hover:bg-green-600 hover:text-white p-2 rounded cursor-pointer" onClick={handleEditClick}>
            <CiEdit className="text-lg" />
          </div> */}
           {/* Edit icon triggers the dropdown */}
           <div className="relative">
            <div
              className="w-fit bg-green-100 hover:bg-green-600 hover:text-white p-2 rounded cursor-pointer"
              onClick={toggleActions}
            >
              <CiEdit className="text-lg" />
            </div>

            {/* Dropdown with Edit and Delete options */}
            {showActions && (
              <div className="absolute right-0 mt-2 w-40 bg-white border shadow-lg rounded-md z-10">
                <button
                  className="w-full text-left px-4 py-2 hover:bg-gray-200 flex items-center"
                  onClick={handleEditClick}
                >
                  <CiEdit className="mr-2" /> Edit
                </button>
                <button
                  className="w-full text-left px-4 py-2 text-red-600 hover:bg-red-100 flex items-center"
                  onClick={() => {
                    if (window.confirm("Are you sure you want to delete this product?")) {
                      deleteProduct();
                    }
                  }}
                >
                  <MdDelete className="mr-2" /> Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Edit Product Modal */}
      {editProduct && (
        <EditProduct
          ProductData={data}
          onClose={handleEditClose}
          updateProductInList={updateProductInList}
        />
      )}

      {/* Contact Seller Modal */}
      <ContactSellerModal
        isOpen={showContactModal}
        onClose={() => setShowContactModal(false)}
        sellerInfo={sellerInfo}
        contactSeller={contactSeller} // ✅ Pass the function
      />
    </div>
  );
};

export default ProductCard;
