
import React from 'react';

const CustomerNotice = ({ message, onClose, className="" }) => {
  const formattedMessage = message.split("***").map((part, index) =>
    index % 2 === 1 ? (
      <React.Fragment key={index}>
        <span className="text-orange-600 font-bold">***</span>{part}<span className="text-orange-600 font-bold">***</span>
      </React.Fragment>
    ) : (
      part
    )
  );
  return (
    <div className={`${className} customerNotice w-full bg-slate-800  text-xl py-2 pr-0.5 flex justify-between items-center customMax-800:mt-10 customMax-800:-mb-11`}>
      <div className="flex-1 customMax-800:overflow-hidden customMax-800:whitespace-nowrap">
        <p className="md:animate-pulse inline-block customMax-800:animate-marquee font-semibold text-white"> {formattedMessage} </p> {/* Add animate-pulse for attention */}
      </div>
      <button
        onClick={onClose}
        className="text-lg font-bold hover:text-gray-300 transition-colors text-red-400"
      >
        ✖
      </button>
    </div>
  );
};

export default CustomerNotice;
