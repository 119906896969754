

import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import summaryApi from '../common'
import { FaStar } from "react-icons/fa";
import { FaStarHalf } from "react-icons/fa6";
import displayPrice from '../helpers/FormatPrice';
import RecommendationDisplay from '../components/RecommendationDisplay';
import addToCart from '../helpers/AddToCart';
import Context from '../context';
import { MarketContext } from '../context/MarketContext'; // Import the context
import { toast } from 'react-toastify';


const ProductDetail = () => {
    const { localMarket} = useContext(MarketContext); // Get market from context
    const [data, setData] = useState({
        itemName: "",
        price: "",
        category: "",
        productImage: [],
        itemDescription: "",
        DeliveryTo: [],
        currency:  '' ,
        paymentOption : [],
        sizes : [],
        airShippingCost: "",
        waterShippingCost: ""
    })

    const params = useParams()
    const [loading, setLoading] = useState(false)
    const productImageLoading = new Array(4).fill(null)
    const [activeImage, setActiveImage] = useState("")
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const [availableColors, setAvailableColors] = useState([]);
    const [availableSizes, setAvailableSizes] = useState([]);
    const [selectedPriceType, setSelectedPriceType] = useState({});
    const [forceRender, setForceRender] = useState(false);

   
    const [zoomImageCoordinate, setZoomImageCoordinate] = useState({
        x : 0,
        y : 0
    })
    const [zoomImage, setZoomImage] = useState(false)
    const navigate = useNavigate();

    const { fetchUserAddToCartCount} = useContext(Context)
   
    // const handleSizeSelect = (size) => {
    //     setSelectedSize(size);
    // };

    // const handleAddCart = async(e, id) =>{
    //     await addToCart(e, id)
    //     fetchUserAddToCartCount()
    // }

    const handleAddCart = async (e, id, shouldNavigate = false) => {
        e.preventDefault();
    
        const { _id, sizes, airShippingCost, waterShippingCost } = data;
    
        // Get the selected price type (default to air if not selected)
        let selectedPriceTypeForProduct = null
        if (data.category === 'Services'){
            selectedPriceTypeForProduct = "email"
        } else {
            selectedPriceTypeForProduct = selectedPriceType[_id] ||  data?.initialPriceType  || "air";
        }
    
        // Ensure shippingCost is a number
        let shippingCost = null
            if (selectedPriceTypeForProduct === "water"){
                shippingCost= Number(waterShippingCost) || 0
            } else if (selectedPriceTypeForProduct === "air"){
                shippingCost= Number(airShippingCost) || 0;
            } else {
                shippingCost= 0
            }
    
        if (data?.sizes?.length > 0 && !selectedSize) {
            toast.error("Please select a size before adding to cart.");
            return;
        }
    
        // Ensure the selected color is valid
        if (data?.sizes?.colors > 0) {
            if (!selectedColor || !availableColors.includes(selectedColor)) {
                toast.error("Please select a valid color before adding to cart.");
                return;
            }
        }
    
    
        await addToCart(e, id, selectedSize, selectedColor, selectedPriceTypeForProduct, shippingCost);
    
        fetchUserAddToCartCount();
    
        if (shouldNavigate) {
            navigate('/cart');
        }
    };
    
    
    

    const handleColorSelect = (color) => {
        setSelectedColor(color);
        
        // Filter available sizes for the selected color
        const sizesForColor = data.sizes.filter(sizeObj => sizeObj.color === color);
        setAvailableSizes(sizesForColor.map(sizeObj => sizeObj.size));
        
        // Reset selected size if it's not available
        if (!sizesForColor.some(sizeObj => sizeObj.size === selectedSize)) {
            setSelectedSize(null);
        }
    };

    const handleSizeSelect = (selectedSize) => {
        setSelectedSize(selectedSize);
    
        // Find the size object for the selected size
        const selectedSizeObj = data.sizes.find((sizeObj) => sizeObj.size === selectedSize);
    
        if (selectedSizeObj && selectedSizeObj.colors) {
            const extractedColors = selectedSizeObj.colors.map((colorObj) => colorObj.color);
            setAvailableColors(extractedColors);
    
            // Reset selectedColor if it is not in the newly available colors
            if (!extractedColors.includes(selectedColor)) {
                setSelectedColor(null);
            }
        } else {
            setAvailableColors([]);
            setSelectedColor(null);
        }
    };
    
    
    useEffect(() => {
        if (data) {
            setAvailableColors(data.colors?.map(colorObj => colorObj.color) || []);
            setAvailableSizes(data.sizes?.map(sizeObj => sizeObj.size) || []);
        }
    }, [data]);
    
    

    const fetchProductDetails = async () => {

        try{
            setLoading(true)
            const response = await fetch(summaryApi.productDetails.url, {
                method: summaryApi.productDetails.method,
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    productId: params?.id
                })
            })

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            
            const dataResponse = await response.json()
          
            if (dataResponse?.data) {
                setData(dataResponse?.data)
                setActiveImage(dataResponse?.data?.productImage[0])
            } else {
                console.log("No data found")
            }

        } catch (error) {
            console.error("Error fetching product detail:", error);
            navigate('/error-fallback');
        } finally {
            setLoading(false);
        }
        
    }


    useEffect(() => {
        fetchProductDetails()

        window.scrollTo(0, 0);
    }, [params])

    const handleMouseEnterProduct = (imageURL) =>{
        setActiveImage (imageURL)
    }

    const handleZoomImage = (e) =>{
        setZoomImage(true)
        const { left, top, width, height } = e.target.getBoundingClientRect()
        //console.log("coordinate:", left, top, width, height)

        const x = (e.clientX - left) / width
        const y = (e.clientY - top) / height

        setZoomImageCoordinate({
            x,
            y
        })
    }
    const handleLeaveImageZoom = () =>{
        setZoomImage(false)
    }

    //old method of fetching if air is 60% more than the base price then it will fetch water price

    // const getDisplayedPrice = (product, selectedPriceType) => {
    //     const price = Number(product?.price) || 0;
    //     const totalPriceByAir = Number(product?.totalPriceByAir) || 0;
    //     const totalPriceByWater = Number(product?.totalPriceByWater) || 0;
    
    //     let displayedPrice = displayPrice(price, product?.currency); // Default price
    //     let offerLabel = null;
    //     let deliveryLabel = "Standard Delivery (1-2 weeks)"; // Default shipping
    //     let initialPriceType = "air"; // Default initial state
    
    //     // **🚀 STEP 1: Determine the Correct Initial Price Type**
    //     if (totalPriceByAir === 0) {
    //         displayedPrice = displayPrice(price, product?.currency); // Show base price if air price is unavailable
    //         initialPriceType = "base"; // No air price available
    //     } else if (totalPriceByAir >= 1.6 * price && totalPriceByWater > 0) {
    //         displayedPrice = displayPrice(totalPriceByWater, product?.currency); // Show water price if air is too expensive
    //         deliveryLabel = `Standard Shipping (${product.waterDuration})`;
    //         offerLabel = `Faster Delivery: ${displayPrice(totalPriceByAir, product?.currency)}`;
    //         initialPriceType = "water"; // Set water as default if air is too expensive
    //     } else {
    //         displayedPrice = displayPrice(totalPriceByAir, product?.currency); // Default to air price
    //         deliveryLabel = `Faster Delivery (${product.airDuration})`;
    //         offerLabel = `Save with Water Shipping: ${displayPrice(totalPriceByWater, product?.currency)}`;
    //         initialPriceType = "air"; // Air is the default
    //     }
    
    //     // **🚀 STEP 2: Apply Selected Price Type Only If It's Defined**
    //     let finalPriceType = selectedPriceType !== undefined ? selectedPriceType : initialPriceType;
    
    //     if (totalPriceByAir > 0 && totalPriceByWater > 0) {
    //         if (finalPriceType === "water") {
    //             displayedPrice = displayPrice(totalPriceByWater, product?.currency);
    //             deliveryLabel = "Standard Shipping (4-6 weeks)";
    //             offerLabel = `Choose Faster Shipment: ${displayPrice(totalPriceByAir, product?.currency)}`;
    //         } else if (finalPriceType === "air") {
    //             displayedPrice = displayPrice(totalPriceByAir, product?.currency);
    //             deliveryLabel = "Faster Delivery (5-10 days)";
    //             offerLabel = `Save with Water Shipping: ${displayPrice(totalPriceByWater, product?.currency)}`;
    //         }
    //     }
    
    //     return { displayedPrice, offerLabel, deliveryLabel, initialPriceType };
    // };

    
    const getDisplayedPrice = (product) => {
        const price = Number(product?.price) || 0;
        const totalPriceByAir = Number(product?.totalPriceByAir) || 0;
        const totalPriceByWater = Number(product?.totalPriceByWater) || 0;
        const backendDefaultPriceType = product?.initialPriceType || "air"; // ✅ Get from backend
    
        let displayedPrice = displayPrice(price, product?.currency); // Default price
        let offerLabel = null;
        let deliveryLabel = "Standard Delivery (1-2 weeks)"; // Default shipping
    
        // ✅ Use backend's default price type if available, otherwise fallback
        const initialPriceType = backendDefaultPriceType;
    
        // ✅ Get the selected price type from state or use the backend default
        const finalPriceType = selectedPriceType[product._id] || initialPriceType;
    
        if (totalPriceByAir > 0 && totalPriceByWater > 0) {
            if (finalPriceType === "water") {
                displayedPrice = displayPrice(totalPriceByWater, product?.currency);
                deliveryLabel = `Standard Shipping (${product?.waterDuration || "4-6 weeks"})`;
                offerLabel = `Choose Faster Shipment: ${displayPrice(totalPriceByAir, product?.currency)}`;
            } else if (finalPriceType === "air") {
                displayedPrice = displayPrice(totalPriceByAir, product?.currency);
                deliveryLabel = `Faster Delivery (${product?.airDuration || "5-10 days"})`;
                offerLabel = `Save with Water Shipping: ${displayPrice(totalPriceByWater, product?.currency)}`;
            }
        }
    
        return { displayedPrice, offerLabel, deliveryLabel, initialPriceType };
    };
    
    // `handlePriceSelection` - Keeps track of user-selected price type
    const handlePriceSelection = (productId) => {
        setSelectedPriceType((prev) => ({
            ...prev,
            [productId]: prev[productId] === "water" ? "air" : "water", // Toggle between water and air
        }));
    };
    
    useEffect(() => {
        if (data?._id && selectedPriceType[data._id] === undefined) {
            const { initialPriceType } = getDisplayedPrice(data, selectedPriceType[data._id]);
            setSelectedPriceType((prev) => ({
                ...prev,
                [data._id]: initialPriceType,
            }));
        }
    }, [data, selectedPriceType]);
    
    
    
    // const getDisplayedPrice = (product, selectedPriceType) => {
    //     const price = Number(product?.price) || 0;
    //     const totalPriceByAir = Number(product?.totalPriceByAir) || null;
    //     const totalPriceByWater = Number(product?.totalPriceByWater) || null;
    //     const airShippingCost = Number(product?.airShippingCost) || 0;
    
    //     let displayedPrice = displayPrice(price, product?.currency); // Default price
    //     let offerLabel = null;
    //     let deliveryLabel = "Standard Delivery (1-2 weeks)"; // Default shipping
    
    //     if (!selectedPriceType || selectedPriceType === "default") {
    //         selectedPriceType = "air"; // Default to air
    //     }
    
    //     if (totalPriceByAir && totalPriceByWater) {
    //         if (selectedPriceType === "water") {
    //             displayedPrice = displayPrice(totalPriceByWater, product?.currency);
    //             deliveryLabel = "Standard Shipping (4-6 weeks)";
    //             offerLabel = `Choose Faster Shipment: ${displayPrice(totalPriceByAir, product?.currency)}`;
    //         } else {
    //             displayedPrice = displayPrice(totalPriceByAir, product?.currency);
    //             deliveryLabel = "Faster Delivery (5-10 days)";
    //             offerLabel = `Save with Water Shipping: ${displayPrice(totalPriceByWater, product?.currency)}`;
    //         }
    //     }
    
    //     return { displayedPrice, offerLabel, deliveryLabel };
    // };
   
    

    // useEffect(() => {
    //     if (data?._id) {
    //         setSelectedPriceType((prev) => ({
    //             ...prev,
    //             [data._id]: data.totalPriceByWater ? "water" : "air",
    //         }));
    //     }
    // }, [data]);


    //percentage difference
    const calculatePercentageDifference = (totalPriceByAir, totalPriceByWater) => {
        if (!totalPriceByAir || !totalPriceByWater) return "0%"; // Avoid division errors
    
        const difference = Math.abs(totalPriceByAir - totalPriceByWater);
        const maxPrice = Math.max(totalPriceByAir, totalPriceByWater);
    
        return `${Math.round((difference / maxPrice) * 100)}%`; // Rounded result with % sign
    };
    
    
    const { displayedPrice, offerLabel, deliveryLabel } = getDisplayedPrice(data, selectedPriceType[data?._id]);
   
    return (
        <div  className='container customMin-300:max-w-full w-full mx-auto p-4 customMax-800:mt-9'>
            <div className="mb-4">
                <button
                onClick={() => navigate(-1)} // Navigate back in history
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition"
                >
                Back
                </button>
            </div>
            <div className='min-h-[200px] flex custom-750-1023:flex-row flex-col lg:flex-row gap-4 w-full max-w-full '>
                {/* product image */}
                <div className='h-96 customMax-525:h-full flex flex-col lg:flex-row-reverse gap-4  max-w-full customMax-749:items-center p-2'>
                    <div className='h-96 customMax-525:max-h-full'>
                        <div className='h-[300px] w-[300px] customMax-525:w-full customMax-525:h-full lg:h-96 lg:w-96  relative'>
                            <img src={activeImage} alt='product image' className='h-full w-full object-scale-down mix-blend-multiply' onMouseMove={handleZoomImage} onMouseLeave={handleLeaveImageZoom}/>

                            {/* product image zoom */}
                            {
                                zoomImage && (
                                    <div className='hidden lg:block absolute h-96 w-96 bg-slate-200 p-1 -right-[410px] top-0'>
                                        <div className='w-full h-full bg-cover mix-blend-multiply'
                                        style={{
                                            backgroundImage: `url(${activeImage})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition : `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`,
                                            backgroundSize: "150%", // This controls the zoom level
        
                                        }}>
        
                                        </div>
    
                                    </div>
                                )
                            }
                         
                        </div>
                    </div>
                    <div className='h-full'>
                        {loading ? (
                            <div className='flex  gap-2 lg:flex-col overflow-scroll scrollbar-none h-full max-w-full'>
                                {
                                    productImageLoading.map((el, index)=>{
                                    return (
                                        <div className='h-20 w-20 rounded bg-slate-200 animate-pulse' key={index}>
                                            
                                        </div>
                                    )
                                    })
                                      
                                }
                            </div>
                            ) : (
                                <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full max-w-[310px]'>
                                    {
                                       data.productImage.map((imgURL, index)=>{
                                        return (
                                            <div className='h-20 w-20 rounded bg-slate-200' key={index}>
                                                <img src={imgURL} alt='product link' className='w-full h-full object-scale-down mix-blend-multiply cursor-pointer' onClick={() => handleMouseEnterProduct(imgURL)} onMouseEnter={() => handleMouseEnterProduct(imgURL)}/>
                                            </div>
                                        )
                                        })
                                        
                                    }
                                </div>
                            )}
                        </div>
                </div>

                {/* product details */}
                {
                    loading ? (
                        <div className='flex flex-col gap-4 '>
                            <h2 className='bg-slate-200 text-2xl  md:text-4xl animate-pulse h-6'></h2>
                            <p className='capitalize bg-slate-200 animate-pulse min-w-[150px]'></p>

                            <div className='bg-slate-200 flex animate-pulse h-6 rounded items-center gap-1'>
                                
                            </div>

                            <div className='flex items-center w-[110px] h-5 bg-slate-200 animate-pulse my-1'>
                                    <p className='bg-slate-200 animate-pulse h-full rounded '></p>
                            </div>

                            <div className='flex items-center gap-3 my-2 '>
                                <button className=' bg-slate-200 animate-pulse h-6 w-[70px] rounded'></button>
                                <button className=' px-3 py-1 font-medium h-6 w-[100px] bg-slate-200 animate-pulse'></button>
                            </div>

                            <div>
                                <p className='bg-slate-200 w-[300px] h-24 animate-pulse font-medium my-1'> </p>
                                <p className='bg-slate-200 '></p>
                            </div>
                        </div>
                    ) : (
                        <div className=' customMax-525:text-xl flex flex-col gap-1 customMax-749:p-3'>
                            <h2 className='text-4xl md:text-4xl font-medium'>{data?.itemName}</h2>
                            <p className='capitalize text-slate-400'>{data?.category}</p>

                            <div className='text-orange-600 flex items-center gap-1'>
                                <FaStar/>
                                <FaStar/>
                                <FaStar/>
                                <FaStar/>
                                <FaStarHalf />
                            </div>


                            <div className='flex items-center'>
                                {/* Display Price */}
                                <div className='text-2xl md:text-3xl my-1 font-medium text-orange-900'>
                                {/* If category is "service", show only the base price */}
                                {data?.category === "Services" ? (
                                    <>
                                        <p>{displayPrice(data?.price, data?.currency)}</p>
                                        <p className="text-sm text-gray-500">Email delivery</p>
                                    </>
                                ) : (
                                    <>
                                        {/* Get the displayed price */}
                                        {data && getDisplayedPrice(data, selectedPriceType[data?._id]) && (
                                            <>
                                                <p>{getDisplayedPrice(data, selectedPriceType[data?._id]).displayedPrice}</p>
                                                <p className="text-sm text-gray-500">
                                                    {getDisplayedPrice(data, selectedPriceType[data?._id]).deliveryLabel}
                                                </p>
                                                {getDisplayedPrice(data, selectedPriceType[data?._id]).offerLabel && (
                                                    <p className="text-sm text-green-600 mt-3">
                                                        {getDisplayedPrice(data, selectedPriceType[data?._id]).offerLabel}
                                                    </p>
                                                )}
                                            </>
                                        )}

                                        {/* Toggle Price Selection Button */}
                                        <button
                                            className="mt-2 px-3 py-1 bg-blue-400 text-white rounded hover:bg-blue-700"
                                            onClick={() => handlePriceSelection(data?._id)}
                                        >
                                            {selectedPriceType[data?._id] === "water" ? "Fast Delivery" : `${calculatePercentageDifference(data.totalPriceByAir, data.totalPriceByWater)} discount`}
                                        </button>
                                    </>
                                )}
                            </div>



                                {/* Payment Option (Capitalized) */}
                                {data.category === 'Services' && (<div className='text-2xl md:text-3xl my-1 font-medium text-white ml-2 bg-black p-2 rounded-full'>
                                    <p>{data?.paymentOption?.charAt(0).toUpperCase() + data?.paymentOption?.slice(1)}</p>
                                </div>)}
                            </div>


                            <div className="flex flex-col gap-3">
                                {/* Color Selector */}
                                {data?.sizes?.length > 0 && (
                                    <div className="my-3">
                                        <p className="font-medium">Select Size:</p>
                                        <div className="flex gap-2 flex-wrap">
                                            {data.sizes.map((sizeObj, index) => (
                                                <button
                                                    key={index}
                                                    className={`px-4 py-2 border rounded ${
                                                        selectedSize === sizeObj.size
                                                            ? "bg-orange-600 text-white"
                                                            : "border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white"
                                                    }`}
                                                    onClick={() => handleSizeSelect(sizeObj.size)}
                                                >
                                                    {sizeObj.size}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                )}


                                {/* Available Colors Section */}
                                {availableColors.length > 0 && (
                                    <div className="my-3">
                                        <p className="font-medium">Available Colors:</p>
                                        <div className="flex gap-2 flex-wrap">
                                            {availableColors.map((color, index) => (
                                                <button
                                                    key={index}
                                                    className={`w-8 h-8 rounded-full border-2 transition duration-200
                                                        ${selectedColor === color
                                                            ? "border-orange-600 scale-110" // Slightly enlarge selected color
                                                            : "border-gray-400 hover:border-orange-600"
                                                        }`}
                                                    onClick={() => setSelectedColor(color)}
                                                    style={{ backgroundColor: color }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>



                            {/* Buy button - Redirects to cart */}
                            <Link 
                                to="/cart" 
                                className='border-2 border-orange-600 rounded px-3 py-1 min-w-[120px] text-orange-700 hover:text-white hover:bg-orange-600 font-medium text-center w-10 customMax600:w-[70%]' 
                                onClick={(e) => handleAddCart(e, data?._id, true)}
                            >
                                Buy Now
                            </Link>

                            {/* Add to Cart button - Stays on page */}
                            <button 
                                className='border-2 border-orange-600 rounded px-3 py-1 min-w-[120px] font-medium text-white bg-orange-600 hover:bg-white hover:text-orange-700 w-10 customMax600:w-[70%]' 
                                onClick={(e) => handleAddCart(e, data?._id, false)}
                            >
                                Add to Cart
                            </button>


                            {/* <div className='flex items-center gap-3 my-2' >
                                <Link to="/cart" className='border-2 border-orange-600 rounded px-3 py-1 min-w-[120px] text-orange-700 hover:text-white hover:bg-orange-600 font-medium' >Buy</Link>
                                <button className='border-2 border-orange-600 rounded px-3 py-1 min-w-[120px] font-medium text-white bg-orange-600 hover:bg-white hover:text-orange-700' onClick={((e)=> handleAddCart(e, data?._id))}>Add to Cart</button>
                            </div> */}

                            <div className='md:h-[180px] overflow-auto w- '>
                                <p className='text-slate-600 font-medium my-1'>Description: </p>
                                <p>{data?.itemDescription}</p>
                            </div>
                        </div>

                    )
                }
               
            </div>

             {/* Continue Shopping Button */}
            <div className="flex justify-center mt-6">
                <button
                onClick={() => navigate("/")}
                className="px-6 py-3 bg-green-500 text-white rounded hover:bg-green-600 transition"
                >
                Continue Shopping
                </button>
            </div>

           

            <RecommendationDisplay localMarket={localMarket} category={data?.category} heading={"Recommended Products"} key={`recommend-${localMarket}1`}/>
          
        </div>

        
    )
}

export default ProductDetail
