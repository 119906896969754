// ChooseUserRole component
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import summaryApi from '../common';
import { useLocation, useNavigate } from 'react-router-dom';
import Context from '../context';


function UpdateGoogleUser({ userId, updateSelected }) {
    const [userRole, setUserRole] = useState('');
    const [mobileNumber, setMobileNumber] = useState('')
    const navigate = useNavigate()
    const location = useLocation();
    const {fetchUserdetails, fetchUserAddToCartCount} = useContext(Context)
  
    const handleRoleSubmit = async () => {
        if (!userRole) {
            toast.error("Please select a role.");
            return;
        }

        if (userRole === 'Seller' && (!mobileNumber || mobileNumber.trim() === '')) {
            toast.error("Please provide a valid mobile number for the seller.");
            return;
        }

        try {
            const response = await fetch(summaryApi.googleLoginUpdate.url, {
                method: summaryApi.googleLoginUpdate.method,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId, role: userRole, mobileNumber }),
            });

            const responseData = await response.json();
            if (responseData.success) {
                updateSelected(); // Pass the selected role
                await fetchUserdetails();
                await fetchUserAddToCartCount();
                if(responseData.role === "Seller"){
                  navigate("/dashboard/all-products");
                } else  if (responseData.role === "Freelancer") {
                  navigate("/freelancer");
                }else{
                  navigate('/')
                  toast.success("Account updated!");
                }

                const redirectPath = location.state?.redirect;
                if (redirectPath) {
                    navigate(redirectPath);
                }
                
            } else {
                toast.error("Failed to select role.");
            }
        } catch (error) {
            console.error("Error selecting role:", error);
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-20 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-[50%] customMax-767:w-[75%] max-w-full">
            <h2 className="text-xl font-semibold text-gray-800 mb-6 text-center">Kindly fill this</h2>
            <div className="grid gap-6 place-items-center">
              <div className="w-[70%] customMax430:w-[85%]">
                <label className="block font-medium text-gray-700 mb-2">Account Type</label>
                <select
                  value={userRole}
                  onChange={(e) => setUserRole(e.target.value)}
                  className="w-full border-2 border-gray-300 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                >
                  <option value="" disabled>
                    Select a role
                  </option>
                  <option value="Customer">Customer</option>
                  <option value="Seller">Seller</option>
                </select>
              </div>
      
              {userRole === "Seller" && (
                <div className="w-[70%] customMax430:w-[85%]">
                  <label className="block font-medium text-gray-700 mb-2">WhatsApp Number</label>
                  <div className="flex items-center bg-slate-100 p-2 rounded-lg border border-gray-300">
                    <input
                      type="text"
                      placeholder="Enter WhatsApp number"
                      name="mobileNumber"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      className="w-full bg-transparent outline-none px-2 py-1"
                    />
                  </div>
                </div>
              )}
            </div>
      
            <div className="flex justify-center mt-6">
              <button
                onClick={handleRoleSubmit}
                className="w-[70%] bg-orange-600 text-white py-2 px-6 rounded-lg hover:bg-orange-700 transition duration-200"
              >
                Update Account
              </button>
            </div>
          </div>
        </div>
      );
  }

export default UpdateGoogleUser;

