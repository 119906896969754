

import React, { useContext, useEffect, useState } from 'react';
import summaryApi from '../common';
import Context from '../context';
import displayPrice from '../helpers/FormatPrice';
import { MdDeleteForever } from "react-icons/md";
import { hasMultipleCurrencies, fetchCustomerCurrency, calculateTotal } from '../helpers/CartPriceHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';


const Cart = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true); // Tracks the initial API call
    const [totalPrice, setTotalPrice] = useState(0);
    const [customerCurrency, setCustomerCurrency] = useState(null);
    const [multipleCurrencies, setMultipleCurrencies] = useState(false);
    const context = useContext(Context);
    const loadingCart = new Array(context.cartProductCount).fill(null);
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.user.user);
    const { fetchUserdetails, fetchUserAddToCartCount } = useContext(Context);

    const fetchCartProduct = async () => {
        setLoading(true); // Set loading to true initially
        try {
          const response = await fetch(summaryApi.viewAddToCartProducts.url, {
            method: summaryApi.viewAddToCartProducts.method,
            credentials: "include",
            headers: {
              "content-type": "application/json",
            },
          });
      
          const responseData = await response.json();
       
          if (responseData?.success) {
            setData(responseData?.data || []); // Set fetched data
          } else {
            setData([]); // Set to empty if API response indicates failure
            toast.error(responseData?.message || "cart is empty")
          }
        } catch (error) {
          console.error("Error fetching cart products:", error);
          setData([]); // Clear data in case of error
        } finally {
          setLoading(false); // Stop loading spinner
          setIsInitialLoad(false); // Mark that initial loading is complete
        }
    };


    useEffect(() => {
        fetchCartProduct();
    }, []);

    
    const handlePayment = async () => {
        setLoading(true);
    
        // Check if the user has completed delivery details
        const checkoutCompleted = localStorage.getItem("checkoutCompleted") === "true";

        if (!checkoutCompleted) {
            // If delivery details are not completed, go to customer checkout page first
            navigate('/customer-checkout');
            setLoading(false);
            return;
        }
        const cartProducts = data.map((item) => ({
            productId: item?.productId?._id,  // Extract product ID
            amount: item?.productId?.price,  // Extract price
            convertedAmount: item?.convertedTotalPrice,  // Use convertedPrice item including shipment rate
            shippingCost: item?.shippingCost,
            shippingMethod: item?.shippingMethod,
            quantity: item?.quantity, // Extract quantity
            itemImage: item?.productId?.productImage,  // Get product image
            size: item?.size,  // Use size directly from item (not productId.sizes)
            color: item?.color,  // Use color directly from item
        }));

       
        cartProducts?.forEach((product, index) => {
            if (!product || !product._id) {
                console.error(`❌ Product at index ${index} is invalid:`, product);
            }
        });


        if (cartProducts?.length === 0) {
            console.error('No product found in the cart for payment.');
            toast.error('Your cart is empty.');
            setLoading(false);
            return;
        }

            // Check if total price is at least ₦200,000
        if (!totalEachPrice || isNaN(totalEachPrice) || totalEachPrice < 100000) {
            toast.error("Minimum order is ₦100,000.");
            setLoading(false);
            return;
        }


        //const stripePromise = await loadStripe(process.env.REACT_APP_PAYMENT_PUBLIC_KEY);
        const guestDetails = JSON.parse(localStorage.getItem('guestDetails'));
        const buyerDetails = user
            ? { id: user?._id, email: user?.email, name: user?.name, address: user?.address, type: 'registered' }
            : { ...guestDetails, type: 'guest' };

        if (!buyerDetails || !buyerDetails?.name || !buyerDetails?.email || !buyerDetails?.type || !buyerDetails?.address) {
            navigate('/customer-checkout');
            setLoading(false); // Stop loading since we are redirecting
            return;
        }

        if (!buyerDetails || !buyerDetails?._id) {
            console.error("❌ Buyer details are missing or do not have _id");
        }

        

        try {
            const payload = {
                cartProducts,
                totalBaseAmount: totalPrice,
                totalConvertedAndShipment: totalConvertedPrice,
                buyerDetails,
                totalQty: totalQuantity,
            };

            const response = await fetch(summaryApi.productPurchase.url, {
                method: summaryApi.productPurchase.method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const responseData = await response.json();
        
            if (responseData?.success) {
                // stripePromise.redirectToCheckout({sessionId : responseData.id})
                //if ( window.location.href = responseData.data.checkoutUrl ){
                if (responseData.data.checkoutUrl) {
                await fetchUserdetails()
                await fetchUserAddToCartCount();

                setTimeout(() => {
                    window.location.href = responseData.data.checkoutUrl;
                }, 500);
                //clear localStorage
                localStorage.removeItem("guestDetails")
                //clear guestToken
                };
                
            } 
            if(responseData?.error){
                toast.error(responseData.message)
            }
        } catch (error) {
            console.error('Error during payment process:', error);
            // navigate('/error-fallback');
        } finally {
            setLoading(false);
        }
    };


    const handleLoading = async()=>{
       await fetchCartProduct()
       await fetchUserdetails()
       await fetchUserAddToCartCount();
    }

    useEffect(() => {
        setLoading(true);
        handleLoading()
    }, []);

    useEffect(() => {
        const calculateTotalPrice = async () => {
            try {
                const hasMultiple = hasMultipleCurrencies(data);
                setMultipleCurrencies(hasMultiple);
    
                // Fetch the customer's local currency based on location if multiple currencies
                let currency = null;
    
                if (hasMultiple) {
                    currency = await fetchCustomerCurrency();
                    if (!currency) {
                        console.error("Currency not retrieved. Defaulting to USD.");
                        currency = 'USD'; // Fallback to a default currency
                    }
                } else {
                    currency = data[0]?.productId?.currency;
                }
    
                setCustomerCurrency(currency);
    
                const total = await calculateTotal(data, currency);
                setTotalPrice(total);
            } catch (error) {
                console.error("Error calculating total price:", error);
                navigate('/error-fallback');
            }
        };
    
        if (data.length > 0) {
            calculateTotalPrice();
        }
    }, [data]);
    
    

    const increaseQTY = async (id, qty) => {
        try{
            const response = await fetch(summaryApi.updateCartProducts.url, {
                method: summaryApi.updateCartProducts.method,
                credentials: "include",
                headers: {
                    "content-type": 'application/json',
                },
                body: JSON.stringify({
                    _id: id,
                    quantity: qty + 1
                })
            });
    
            const responseData = await response.json();
            if (responseData.success) {
                fetchCartProduct();
            } else {
                toast.error(responseData.message);
            }
        }catch(error){
            console.error("Error to increase products:", error);
            navigate('/error-fallback');
        }
       
    };

    const decreaseQTY = async (id, qty) => {
        try{
            if (qty >= 2) {
                const response = await fetch(summaryApi.updateCartProducts.url, {
                    method: summaryApi.updateCartProducts.method,
                    credentials: "include",
                    headers: {
                        "content-type": 'application/json',
                    },
                    body: JSON.stringify({
                        _id: id,
                        quantity: qty - 1
                    })
                });
    
                const responseData = await response.json();
                if (responseData.success) {
                    fetchCartProduct();
                } else {
                    toast.error(responseData.message);
                }
            }
        }catch(error){
            console.error("Error to decrease product:", error);
            navigate('/error-fallback');
        }
        
    };

    const deleteCartProduct = async (id) => {
        try{
            const response = await fetch(summaryApi.deleteCartProducts.url, {
                method: summaryApi.deleteCartProducts.method,
                credentials: "include",
                headers: {
                    "content-type": 'application/json',
                },
                body: JSON.stringify({ _id: id })
            });
    
            const responseData = await response.json();
    
            if (responseData?.success) {
                fetchCartProduct();
                context.fetchUserAddToCartCount();
            } else {
                if (responseData.status >= 500) {
                    navigate("/error-fallback");
                }
            }
        }catch(error){
            console.error("Error to delete cart:", error);
            navigate('/error-fallback');
        }
       
    };

    const totalQty = data.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0)
    const totalBaseAmount = data.reduce((previousValue, currentValue) => previousValue + currentValue.price, 0)
    const totalQuantity = data.reduce((acc, item) => acc + item.quantity, 0);
    const totalEachPrice = data.reduce((acc, item) => 
        acc + ((item.totalPrice || 0) * (item.quantity || 1)), 0
    );
    
    // Compute total converted price (using the convertedPrice field)
    const totalConvertedPrice = data.reduce((total, product) => {
        return total + product.convertedTotalPrice * product.quantity ;
    }, 0);

    return (
        <div className='container customMin-300:max-w-full mx-auto customMax-800:mt-11'>
                <div className='flex flex-col md:flex-row gap-10 md:justify-between p-4'>
                    {/* View cart products */}
                    <div className="w-full max-w-3xl">
                        {loading && isInitialLoad ? (
                            // Show spinner during the initial load
                            <div className="flex justify-center items-center space-x-4 my-4 w-[96vw]">
                            {[...Array(3)].map((_, index) => (
                                <div
                                key={index}
                                className="w-4 h-4 bg-orange-500 rounded-full animate-pulse"
                                style={{ animationDelay: `${index * 0.2}s` }}
                                ></div>
                            ))}
                            </div>
                        ) : data.length > 0 ? (
                            data.map((product, index) => (
                              <div
                                key={product?._id || index}
                                className="w-full bg-white max-h-38 my-2 border border-slate-300 rounded grid grid-cols-[128px,1fr]"
                              >
                                <div className="bg-slate-200 w-32 h-38">
                                  <img
                                    src={product?.productId?.productImage[0]}
                                    alt={product?.itemName}
                                    className="w-full h-full object-scale-down mix-blend-multiply"
                                  />
                                </div>
                                <div className="px-4 py-2 relative">
                                  <div
                                    className="absolute right-0 text-orange-900 rounded-full p-2 hover:bg-red-600 hover:text-white cursor-pointer"
                                    onClick={() => deleteCartProduct(product?._id)}
                                  >
                                    <MdDeleteForever />
                                  </div>
                                  <h2 className="text-lg md:text-xl text-ellipsis line-clamp-1">
                                    {product?.productId?.itemName}
                                  </h2>
                                  <p className="capitalize text-slate-500">
                                    {product?.productId?.category}
                                  </p>
                                   {/* Display Size */}
                                    <div className='flex justify-between'>
                                    {product?.size && <p>Size: {product?.size}</p>}
                                    {product?.color && (
                                        <p className="p-0.5 w-6 h-6 rounded-full border" style={{ backgroundColor: product.color }}>
                                        </p>
                                    )}

                                    </div>

                                  <div className="flex justify-between ">
                                    <p className="text-orange-900 font-medium text-lg">
                                      {displayPrice(product?.totalPrice , product?.productCurrency)}
                                    </p>
                                    <p className="text-orange-600 font-semibold text-lg">
                                    {displayPrice(
                                        product?.totalPrice * product?.quantity,
                                        product?.productId?.currency
                                    )}
                                    </p>
                                  </div>
                                  <div className='flex justify-between'>
                                    <p className="text-green-600 font-semibold text-lg">
                                        {displayPrice(product?.convertedTotalPrice, product?.customerCurrency)} 
                                    </p>
                                    <p className="text-green-600 font-semibold text-lg">
                                        {displayPrice(product?.convertedTotalPrice * product?.quantity, product?.customerCurrency)} 
                                    </p>
                                  </div>
                                  <div className="flex items-center gap-3 mt-1">
                                    <button
                                      className="p-1 border border-orange-600 text-orange-600 hover:bg-orange-500 hover:text-white w-6 h-6 flex items-center justify-center rounded"
                                      onClick={() => decreaseQTY(product?._id, product?.quantity)}
                                    >
                                      -
                                    </button>
                                    <span>{product?.quantity}</span>
                                    <button
                                      className="p-1 border border-orange-600 text-orange-600 hover:bg-orange-500 hover:text-white w-6 h-6 flex items-center justify-center rounded"
                                      onClick={() => increaseQTY(product?._id, product?.quantity)}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (<div className='flex items-center justify-center w-[96vw] px-4 rounded-md text-center bg-white'>
                            <p className='p-10'>No product in cart</p>
                        </div>)}
                    </div>

                    {/* Summary */}
                    {  
                        data[0] && (
                        <div className='mt-4 md:mt-2 w-full max-w-sm'>
                        {loading ? (
                            <div className='h-36 bg-slate-200 border-slate-300 animate-pulse'>
                            </div>
                        ) : (
                            <div className='h-36 bg-white'>
                                <h2 className='text-white bg-orange-600 px-4 py-1'>Summary</h2>
                                <div className='flex justify-between items-center px-4 gap-2 font-medium text-lg text-slate-600'>
                                    <p>Quantity</p>
                                    <p>{totalQty}</p>
                                </div>

                                 {/*<div className='flex justify-between items-center px-4 gap-2 font-medium text-lg text-slate-600'>
                                    <p>Total Price</p>
                                  
                                    <p>
                                        {multipleCurrencies && customerCurrency ? (
                                            customerCurrency ? `${displayPrice(totalPrice, customerCurrency)} (Converted)` : "Currency unavailable"
                                        ) : (
                                            data[0]?.productId?.currency ? displayPrice(totalPrice, data[0].productId.currency) : "Price unavailable"
                                        )}
                                    </p>
                                </div>
                                */}

                                <div className='flex justify-between items-center px-4 gap-2 font-medium text-lg text-slate-600'>
                                    <p>Total:</p>
                                    {/* Only display the price if the currency is valid */}
                                    <p>
                                    {displayPrice(totalConvertedPrice, data[0]?.customerCurrency)}  
                                    </p>
                                </div>


                                <button 
                                className='bg-green-600 p-2 text-white w-full' 
                                type='button' 
                                disabled = {loading} 
                                onClick={handlePayment}
                                >
                                    {loading ? "Processing..." : "Payment"}
                                </button>
                            </div>
                        )}
                        </div>
                    )}
                
                </div>
                {/* </>
            )} */}
           
        
        </div>
    );
};

export default Cart;
