import {  createBrowserRouter } from 'react-router-dom'
import App from '../App'
import Home from '../pages/Home'
import Login from '../pages/Login'
import ForgotPassword from '../pages/ForgotPassword'
import SignUp from '../pages/SignUp'
import AdminPanel from '../pages/Admin/AdminPanel'
import AllUsers from '../pages/Admin/AllUsers'
import AllProducts from '../pages/Admin/AllProducts'
import CategoryProduct from '../pages/CategoryProduct'
import ProductDetail from '../pages/ProductDetail'
import Cart from '../pages/Cart'
import SearchProduct from '../pages/SearchProduct'
import ErrorBoundary from '../helpers/ErrorBoundary'
import ErrorFallBack from '../pages/ErrorsFallBack'
import OtpVerification from '../pages/OtpVerification'
import PendingApprovalProduct from '../pages/Admin/PendingApprovalProduct'
import RejectedApprovalProduct from '../pages/RejectedProduct'
import VerifyOTPChangePassword from '../pages/VerifyOTPChangePassword'
import PolicyPage from '../pages/PolicyPage'
import TermsOfService from '../pages/TermsOfService';
import ImageLogo from '../pages/ImageLogo'
import SoldProducts from '../pages/Admin/SoldProducts'
import Transactions from '../pages/Admin/Transactions'
import PaymentSuccess from '../pages/PaymentSuccess'
import PaymentCancel from '../pages/PaymentCancel'
import OrderPage from '../pages/OrderPage'
import PurchaseHistory from '../pages/Admin/PurchaseHistory'
import ShipmentInfo from '../pages/Admin/ShipmentInfo'
import ShipmentForm from '../pages/Admin/ShipmentForm'
import FAQ from '../pages/Admin/FAQ'
import AdminFAQ from '../pages/Admin/AdminFrequentAskQuestion'
import UploadBanner from '../pages/Admin/UploadBanner'
import SellerUpdateAccount from '../pages/Admin/SellerUpdateAccount'
import FreelancerPage from '../pages/FreelancerPage'
import FreelancerDasboard from '../pages/Freelancer/FreelancerDashboard'
import FreelancerUpdateAccount from '../pages/Freelancer/UpdateFreelancerAccount'
import SellerLandingPage from '../pages/SellerLandingPage'
import OG from '../pages/OG'
import AboutAffiliateMarketing from '../pages/AfflicateMarketing'
import AboutDropShipping from '../pages/DropShipping'
import LogisticsInformation from '../pages/Logistics'
import ProductPackaging from '../pages/ProductPackaging'
import FreshProductPreservation from '../pages/FreshProductPreservation'
import { AboutFreelancing, MakeMoneyAsFreelancer, TipToSucceedAsFreelancer } from '../pages/FreelanceNewLetter'
import ErrorLog from '../pages/Admin/ErrorLog'
import CreateShipmentRate from '../pages/Admin/CreateShipmentRate'
import CustomerCheckout from '../pages/CustomerCheckout'



const router = createBrowserRouter([
   {
    path : "/",
    element : <App/>,
    errorElement: <ErrorBoundary />, // Set the ErrorBoundary for any error in children routes
    children: [
        {
            path : "",
            element : <Home/>
        },
        
        { 
            path: "/images/logo",
            element : <ImageLogo/>
        },

        {
            path : "images/og",
            element : <OG />
        },

        {
            path : "login",
            element : <Login/>
        },


        {
            path : "forgot-password",
            element : <ForgotPassword/>
        },

        {
            path : "change-password",
            element : <VerifyOTPChangePassword />
        },

        {
            path : "signup",
            element : <SignUp/>
        },

        {
            path : "verify-otp",
            element : <OtpVerification/>
        },

        {
            path : "product-category",
            element : <CategoryProduct/>,
        },

        {  path : "product/:id",
            element : <ProductDetail/>,
        },

        {
            path : "success",
            element : <PaymentSuccess/>
        },

        {
            path : "cancel",
            element : <PaymentCancel/>
        },

        {
            path : "cancel",
            element : <OrderPage />
        },

        {
            path : "cart",
            element : <Cart /> 
        },

        {
            path : "search",
            element : <SearchProduct />
        },

        {
            path : "error-fallback",
            element : <ErrorFallBack/>
        },

        {
            path : "privacy-policy",
            element : <PolicyPage/>
        },

        {
            path : "terms-of-service",
            element : <TermsOfService />
        },

        {
            path : "customer-checkout",
            element : < CustomerCheckout/>
        },

        {
            path : "purchase-history",
            element : < PurchaseHistory/>
        },

        {
            path : "freelancer-details",
            element: <FreelancerPage/>
        },

        {
            path : 'seller-onboarding',
            element : <SellerLandingPage/>
        },

        {
            path : 'about-affiliate-marketing',
            element : <AboutAffiliateMarketing />
        },

        {
            path : 'learn-about-dropshipping',
            element : <AboutDropShipping />
        },

    
        {
            path : 'logistic-info',
            element : <LogisticsInformation />
        },

        {
            path : 'learn-packaging',
            element : <ProductPackaging />
        },

        {
            path : 'free-product-preservation',
            element : <FreshProductPreservation/>
        },

        {
            path : 'about-freelancing',
            element : <AboutFreelancing />
        },

        {
            path : 'make-money-freelancer',
            element : <MakeMoneyAsFreelancer />
        },

        {
            path : 'tips-to-succeed-as-freelancer',
            element : <TipToSucceedAsFreelancer />
        },

        {
            path : "freelancer",
            element: <FreelancerDasboard />,
            children: [
                {
                    path: "update-account",
                    element: <FreelancerUpdateAccount />
                }
               
            ]
        },

        {
            path : "dashboard",
            element : <AdminPanel/>,
            children : [
                {
                    path : "all-users",
                    element : <AllUsers/>
                },
                {
                    path : "all-products",
                    element : <AllProducts/>
                },
                {
                    path : "pending-approval",
                    element : <PendingApprovalProduct/>
                },
                {
                    path : "rejected-approval",
                    element : <RejectedApprovalProduct/>
                },

                {
                    path : "sold-products",
                    element : < SoldProducts/>
                },

                {
                    path : "transactions",
                    element : < Transactions />
                },

                {
                    path : "seller-info",
                    element : <ShipmentInfo />
                },

                {
                    path : "shipment-form",
                    element : <ShipmentForm />
                },

                {
                    path : "create-faqs",
                    element : <AdminFAQ />
                },

                {
                    path : "faqs",
                    element : <FAQ />
                },

                {
                    path : "create-banner",
                    element : < UploadBanner/>
                }, 

                {
                    path : "seller-update-account",
                    element : <SellerUpdateAccount />
                },

                {
                    path: "logs",
                    element : <ErrorLog />
                },

                {
                    path: "create-shipment-rate",
                    element : <CreateShipmentRate />
                }


            ]
        },
        
    ]

   }
])

export default router