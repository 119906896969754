import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";

const ContactSellerModal = ({ isOpen, onClose, sellerInfo, contactSeller  }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  if (!isOpen) return null;

  const handleSendMessage = async () => {
    if (!subject.trim() || !message.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      await contactSeller(sellerInfo.email, subject, message); // ✅ Call API function
      onClose();
    } catch (error) {
      toast.error("Failed to send message.");
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Contact Seller</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
            <IoMdClose size={24} />
          </button>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold">Seller Name:</label>
          <input
            type="text"
            value={sellerInfo.name}
            disabled
            className="w-full p-2 border border-gray-300 rounded bg-gray-100"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold">Seller Email:</label>
          <input
            type="text"
            value={sellerInfo.email}
            disabled
            className="w-full p-2 border border-gray-300 rounded bg-gray-100"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold">Mobile Number:</label>
          <input
            type="text"
            value={sellerInfo.mobile}
            disabled
            className="w-full p-2 border border-gray-300 rounded bg-gray-100"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold">Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter subject..."
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold">Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded h-24"
            placeholder="Write your message..."
          />
        </div>

        <button
          onClick={handleSendMessage}
          className="w-full bg-orange-500 text-white py-2 rounded hover:bg-orange-600 transition"
        >
          Send Message
        </button>
      </div>
    </div>
  );
};

export default ContactSellerModal;
