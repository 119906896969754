// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import summaryApi from "../common"; // Adjust path as needed

// const ExpandableRow = ({ product, currentSellerId, role, fetchOrderDetails }) => {
//   const [expanded, setExpanded] = useState(false);
//   const [orderStatus, setOrderStatus] = useState(product?.status || "pending");
//   const [orderDetails, setOrderDetails] = useState(null);

//   useEffect(() => {
//     if (expanded && orderDetails === null) {
//       fetchDetails();
//     }
//   }, [expanded, orderDetails]);

//   const fetchDetails = async () => {
//     if (!product?._id) {
//       toast.error("Order ID is missing.");
//       return;
//     }
  
//     try {
//       const response = await fetch(
//         summaryApi.getOrderItem.url.replace(":orderId", product._id),
//         {
//           method: summaryApi.getOrderItem.method,
//           credentials: "include",
//           headers: { "Content-Type": "application/json" },
//         }
//       );
//       console.log("Fetched order details response:", response);
//       const dataResponse = await response.json();
//       console.log("Fetched order details data:", dataResponse);
      
//       if (dataResponse.success && Array.isArray(dataResponse.data)) {
//         // Set the full array of order items
//         setOrderDetails(dataResponse.data);
//       } else {
//         toast.error(dataResponse.message || "Failed to fetch order details");
//         setOrderDetails([]);
//       }
//     } catch (error) {
//       console.error("Error fetching order details:", error);
//       toast.error("Failed to fetch order details.");
//     }
//   };

//   // Safely derive a lowercase version of role
//   const lowerRole = role ? role.toLowerCase() : "";
//   console.log("ExpandableRow - role:", role, "lowerRole:", lowerRole, "currentSellerId:", currentSellerId);

//   // Separate items into owned and not owned groups.
//   // Log the seller ID of each item for debugging.
//   const ownedItems = orderDetails
//     ? orderDetails.filter((item) => {
//         if (lowerRole === "admin") return true;
//         const sellerIdValue =
//           item.sellerId && typeof item.sellerId === "object"
//             ? item.sellerId._id.toString()
//             : item.sellerId;
//         console.log("Comparing order item sellerId:", sellerIdValue, "with currentSellerId:", currentSellerId);
//         return sellerIdValue === currentSellerId;
//       })
//     : [];

//   const notOwnedItems = orderDetails
//     ? orderDetails.filter((item) => {
//         if (lowerRole === "admin") return false;
//         const sellerIdValue =
//           item.sellerId && typeof item.sellerId === "object"
//             ? item.sellerId._id.toString()
//             : item.sellerId;
//         return sellerIdValue !== currentSellerId;
//       })
//     : [];

//   const handleOrderItemStatusChange = async (newStatus, orderItemId) => {
//     try {
//       const response = await fetch(summaryApi.updateOrderItem.url, {
//         method: summaryApi.updateOrderItem.method,
//         credentials: "include",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ orderItemId, status: newStatus }),
//       });
//       const dataResponse = await response.json();
//       if (dataResponse.success) {
//         toast.success("Order Item Status updated successfully");
//         setOrderDetails((prevDetails) =>
//           prevDetails.map((item) =>
//             item._id === orderItemId ? { ...item, status: newStatus } : item
//           )
//         );
//       } else {
//         toast.error(dataResponse.message);
//       }
//     } catch (error) {
//       console.error("Error updating order item status:", error);
//       toast.error("Failed to update status. Please try again.");
//     }
//   };

//   const handleOrderStatusChange = async (newStatus) => {
//     try {
//       const response = await fetch(summaryApi.updateOrder.url, {
//         method: summaryApi.updateOrder.method,
//         credentials: "include",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ orderId: product._id, status: newStatus }),
//       });
//       const dataResponse = await response.json();
//       if (dataResponse.success) {
//         toast.success("Order Status updated successfully");
//         setOrderStatus(newStatus);
//       } else {
//         toast.error(dataResponse.message);
//       }
//     } catch (error) {
//       console.error("Error updating order status:", error);
//       toast.error("Failed to update status. Please try again.");
//     }
//   };

//   return (
//     <>
//       <tr>
//         <td className="border px-4 py-2">{product?.createdAt || "Unknown Item"}</td>
//         <td className="border px-4 py-2">{`OrderId: ${product?._id || "N/A"}`}</td>
//         <td className="border px-4 py-2">{product?.buyerDetails?.buyerName || "N/A"}</td>
//         <td className="border px-4 py-2">{product?.buyerDetails?.buyerAddress || "N/A"}</td>
//         <td className="border px-4 py-2">{product?.totalQty || 0}</td>
//         <td
//           className={`border px-4 py-2 ${
//             product?.paymentStatus === "pending"
//               ? "bg-red-400 text-white"
//               : "bg-green-500 text-white"
//           }`}
//         >
//           <strong>{product?.paymentStatus || "N/A"}</strong>
//         </td>
//         <td className="border px-4 py-2">
//           <select
//             value={orderStatus}
//             onChange={(e) => handleOrderStatusChange(e.target.value)}
//             className="border rounded py-1 px-2"
//           >
//             <option value="pending">Pending</option>
//             <option value="dispatched">Dispatched</option>
//             <option value="delivered">Delivered</option>
//           </select>
//         </td>
//         <td className="border px-4 py-2">
//           <button
//             onClick={() => setExpanded(!expanded)}
//             className="text-blue-500 underline"
//           >
//             {expanded ? "Collapse" : "Expand"}
//           </button>
//         </td>
//       </tr>
//       {expanded && orderDetails && (
//         <tr>
//           <td colSpan="8" className="border px-4 py-2 bg-gray-50">
//             <div className="p-4 border rounded bg-white shadow-md">
//               <h3 className="font-semibold text-lg mb-2">Order Details</h3>
//               <div className="grid grid-cols-2 gap-4">
//                 <div>
//                   <strong>Order ID:</strong> {product?._id || "N/A"}
//                 </div>
//                 <div>
//                   <strong>Total Items:</strong> {product?.totalQty || 0}
//                 </div>
//               </div>
//               <h4 className="mt-4 font-semibold">Owned Products</h4>
//               {ownedItems.length > 0 ? (
//                 <table className="table-auto w-full border-collapse border mt-2">
//                   <thead>
//                     <tr className="bg-gray-200">
//                       <th className="border px-4 py-2">Item ID</th>
//                       <th className="border px-4 py-2">Details</th>
//                       <th className="border px-4 py-2">Status</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {ownedItems.map((item, index) => (
//                       <tr key={index}>
//                         <td className="border px-4 py-2">{item._id || "Unknown"}</td>
//                         <td className="border px-4 py-2">
//                           {item.productId?.name || "N/A"} - ${item.price || 0}
//                         </td>
//                         <td className="border px-4 py-2">
//                           <select
//                             value={item.status}
//                             onChange={(e) =>
//                               handleOrderItemStatusChange(e.target.value, item._id)
//                             }
//                             className="border rounded py-1 px-2 w-full text-center"
//                           >
//                             <option value="pending">Pending</option>
//                             <option value="dispatched">Dispatched</option>
//                             <option value="delivered">Delivered</option>
//                           </select>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               ) : (
//                 <p>No owned products available.</p>
//               )}
//               <h4 className="mt-4 font-semibold">Other Sellers' Products</h4>
//               {notOwnedItems.length > 0 ? (
//                 <div className="overflow-x-auto">
//                   <table className="table-auto w-full border-collapse border mt-2">
//                     <thead>
//                       <tr className="bg-gray-200">
//                         <th className="border px-4 py-2">Item ID</th>
//                         <th className="border px-4 py-2">Quantity Sold</th>
//                         <th className="border px-4 py-2">Status</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {notOwnedItems.map((item, index) => (
//                         <tr key={index}>
//                           <td className="border px-4 py-2">{item._id || "Unknown"}</td>
//                           <td className="border px-4 py-2">{item.quantity || 0}</td>
//                           <td className="border px-4 py-2">
//                             <select
//                               value={item.status}
//                               onChange={(e) =>
//                                 handleOrderItemStatusChange(e.target.value, item._id)
//                               }
//                               className="border rounded py-1 px-2 w-full text-center"
//                               disabled={lowerRole !== "admin"}
//                             >
//                               <option value="pending">Pending</option>
//                               <option value="dispatched">Dispatched</option>
//                               <option value="delivered">Delivered</option>
//                             </select>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               ) : (
//                 <p>No other sellers' products available.</p>
//               )}
//             </div>
//           </td>
//         </tr>
//       )}
//     </>
//   );
// };

// ExpandableRow.defaultProps = {
//   currentSellerId: "",
//   role: ""
// };

// export default ExpandableRow;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import summaryApi from "../common"; // Adjust path as needed

const ExpandableRow = ({ product, currentSellerId, role, fetchOrderDetails }) => {
  const [expanded, setExpanded] = useState(false);
  const [orderStatus, setOrderStatus] = useState(product?.status || "pending");
  const [orderDetails, setOrderDetails] = useState(null);
  const [currentImageIndex, setCurrentImageIndex]= useState(0)
      const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

      // Handle image slider navigation
    const handlePreviousImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? product?.productId?.productId.length - 1 : prevIndex - 1));
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === product?.productId?.productId.length - 1 ? 0 : prevIndex + 1));
    };


  useEffect(() => {
    if (expanded && orderDetails === null) {
      fetchDetails();
    }
  }, [expanded, orderDetails]);

  const fetchDetails = async () => {
    if (!product?._id) {
      toast.error("Order ID is missing.");
      return;
    }
  
    try {
      const response = await fetch(
        summaryApi.getOrderItem.url.replace(":orderId", product._id),
        {
          method: summaryApi.getOrderItem.method,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
        }
      );
      const dataResponse = await response.json();
      
      if (dataResponse.success && Array.isArray(dataResponse.data)) {
        // Set the full array of order items
        setOrderDetails(dataResponse.data);
      } else {
        toast.error(dataResponse.message || "Failed to fetch order details");
        setOrderDetails([]);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
      toast.error("Failed to fetch order details.");
    }
  };

  // Derive lowercase role for consistency
  const lowerRole = role ? role.toLowerCase() : "";

  // Separate items into owned and not owned groups.
  const ownedItems = orderDetails
    ? orderDetails.filter((item) => {
        if (lowerRole === "admin") return true;
        const sellerIdValue =
          item.sellerId && typeof item.sellerId === "object"
            ? item.sellerId._id.toString()
            : item.sellerId;
       
        return sellerIdValue === currentSellerId;
      })
    : [];

  const notOwnedItems = orderDetails
    ? orderDetails.filter((item) => {
        if (lowerRole === "admin") return false;
        const sellerIdValue =
          item.sellerId && typeof item.sellerId === "object"
            ? item.sellerId._id.toString()
            : item.sellerId;
        return sellerIdValue !== currentSellerId;
      })
    : [];

  const handleOrderItemStatusChange = async (newStatus, orderItemId) => {
    try {
      const response = await fetch(summaryApi.updateOrderItem.url, {
        method: summaryApi.updateOrderItem.method,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ orderItemId, status: newStatus }),
      });
      const dataResponse = await response.json();
      if (dataResponse.success) {
        toast.success("Order Item Status updated successfully");
        setOrderDetails((prevDetails) =>
          prevDetails.map((item) =>
            item._id === orderItemId ? { ...item, status: newStatus } : item
          )
        );
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error("Error updating order item status:", error);
      toast.error("Failed to update status. Please try again.");
    }
  };

  const handleOrderStatusChange = async (newStatus) => {
    try {
      const response = await fetch(summaryApi.updateOrder.url, {
        method: summaryApi.updateOrder.method,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ orderId: product._id, status: newStatus }),
      });
      const dataResponse = await response.json();
      if (dataResponse.success) {
        toast.success("Order Status updated successfully");
        setOrderStatus(newStatus);
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
      toast.error("Failed to update status. Please try again.");
    }
  };

  return (
    <>
      <tr>
        <td className="border px-4 py-2">{product?.createdAt || "Unknown Item"}</td>
        <td className="border px-4 py-2">{`OrderId: ${product?._id || "N/A"}`}</td>
        <td className="border px-4 py-2">{product?.buyerDetails?.buyerName || "N/A"}</td>
        <td className="border px-4 py-2">{product?.buyerDetails?.buyerAddress || "N/A"}</td>
        <td className="border px-4 py-2">{product?.totalQty || 0}</td>
        <td
          className={`border px-4 py-2 ${
            product?.paymentStatus === "pending"
              ? "bg-red-400 text-white"
              : "bg-green-500 text-white"
          }`}
        >
          <strong>{product?.paymentStatus || "N/A"}</strong>
        </td>
        <td className="border px-4 py-2">
          <select
            value={orderStatus}
            onChange={(e) => handleOrderStatusChange(e.target.value)}
            className="border rounded py-1 px-2"
          >
            <option value="pending">Pending</option>
            <option value="dispatched">Dispatched</option>
            <option value="delivered">Delivered</option>
          </select>
        </td>
        <td className="border px-4 py-2">
          <button
            onClick={() => setExpanded(!expanded)}
            className="text-blue-500 underline"
          >
            {expanded ? "Collapse" : "Expand"}
          </button>
        </td>
      </tr>
      {expanded && orderDetails && (
        <tr>
          <td colSpan="8" className="border px-4 py-2 bg-gray-50">
            <div className="p-4 border rounded bg-white shadow-md">
              <h3 className="font-semibold text-lg mb-2">Seller Own Products</h3>
              {ownedItems.length > 0 ? (
                ownedItems.length > 1 ? (
                  // Render a horizontal slider if there is more than one owned item.
                  <div className="flex space-x-4 overflow-x-auto pb-2">
                    {ownedItems.map((item, index) => (
                      <div
                        key={index}
                        className="min-w-[220px] border rounded p-4 bg-white shadow relative"
                      >
                        <div className="mb-2 relative h-24">
                          {Array.isArray(item?.productId?.productImage) ? (
                            item.productId.productImage.length > 1 ? (
                              <>
                                <img
                                  src={
                                    item.productId.productImage[
                                      currentImageIndex % item.productId.productImage.length
                                    ]
                                  }
                                  
                                  alt="product"
                                  className="mx-auto object-contain h-full w-full rounded"
                                />
                                <button
                                  onClick={handlePreviousImage}
                                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-2 rounded-full"
                                >
                                  &lt;
                                </button>
                                <button
                                  onClick={handleNextImage}
                                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-2 rounded-full"
                                >
                                  &gt;
                                </button>
                              </>
                            ) : item.productId.productImage[0] ? (
                              <img
                                src={item.productId.productImage[0]}
                                alt="product"
                                className="mx-auto object-contain h-full w-full rounded"
                              />
                            ) : (
                              <div className="w-full h-full flex items-center justify-center bg-gray-200">
                                No Image Available
                              </div>
                            )
                          ) : (
                            <div className="w-full h-full flex items-center justify-center bg-gray-200">
                              No Image Available
                            </div>
                          )}
                        </div>
                        <div className="font-semibold text-sm mb-1">
                          {item.productId?.itemName || "N/A"}
                        </div>
                        <div className="text-sm mb-1">Price: ${item.price || 0}</div>
                        <div className="text-sm mb-2">
                          Qty: {item.quantity || 0}
                        </div>
                        {/* Toggle Description */}
                        <p
                          className={`text-sm text-gray-500 ${
                            isDescriptionExpanded ? "" : "line-clamp-1"
                          }`}
                        >
                          {item?.itemDescription}
                        </p>
                        <button
                          onClick={() =>
                            setDescriptionExpanded(!isDescriptionExpanded)
                          }
                          className="text-blue-500 text-sm mt-1"
                        >
                          {isDescriptionExpanded ? "Show Less" : "Read More"}
                        </button>
                        <div>
                          <select
                            value={item.status}
                            onChange={(e) =>
                              handleOrderItemStatusChange(e.target.value, item._id)
                            }
                            className="border rounded py-1 px-2 w-full text-center text-sm"
                          >
                            <option value="pending">Pending</option>
                            <option value="dispatched">Dispatched</option>
                            <option value="delivered">Delivered</option>
                          </select>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  // Single item card view.
                  <div className="border rounded p-4 bg-white shadow min-w-[220px] max-w-[320px] relative">
                    <div className="mb-2 relative h-24">
                      {Array.isArray(ownedItems[0].productId?.productImage) ? (
                        ownedItems[0].productId.productImage.length > 1 ? (
                          <>
                            <img
                              src={
                                ownedItems[0].productId.productImage[
                                  currentImageIndex %
                                    ownedItems[0].productId.productImage.length
                                ]
                              }
                              alt="product"
                              className="mx-auto object-contain h-full w-full rounded"
                            />
                            <button
                              onClick={handlePreviousImage}
                              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-2 rounded-full"
                            >
                              &lt;
                            </button>
                            <button
                              onClick={handleNextImage}
                              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-2 rounded-full"
                            >
                              &gt;
                            </button>
                          </>
                        ) : ownedItems[0].productId.productImage[0] ? (
                          <img
                            src={ownedItems[0].productId.productImage[0]}
                            alt="product"
                            className="mx-auto object-contain h-full w-full rounded"
                          />
                        ) : (
                          <div className="w-full h-full flex items-center justify-center bg-gray-200">
                            No Image Available
                          </div>
                        )
                      ) : (
                        <div className="w-full h-full flex items-center justify-center bg-gray-200">
                          No Image Available
                        </div>
                      )}
                    </div>
                    <div className="font-semibold text-sm mb-1">
                      {ownedItems[0].productId?.itemName || "N/A"}
                    </div>
                    <div className="text-sm mb-1">
                      Price: ${ownedItems[0].price || 0}
                    </div>
                    <div className="text-sm mb-2">
                      Qty: {ownedItems[0].quantity || 0}
                    </div>
                    {/* Toggle Description */}
                    <p
                      className={`text-sm text-gray-500 ${
                        isDescriptionExpanded ? "" : "line-clamp-1"
                      }`}
                    >
                      {ownedItems[0]?.itemDescription}
                    </p>
                    <button
                      onClick={() => setDescriptionExpanded(!isDescriptionExpanded)}
                      className="text-blue-500 text-sm mt-1"
                    >
                      {isDescriptionExpanded ? "Show Less" : "Read More"}
                    </button>
                    <div>
                      <select
                        value={ownedItems[0].status}
                        onChange={(e) =>
                          handleOrderItemStatusChange(e.target.value, ownedItems[0]._id)
                        }
                        className="border rounded py-1 px-2 w-full text-center text-sm"
                      >
                        <option value="pending">Pending</option>
                        <option value="dispatched">Dispatched</option>
                        <option value="delivered">Delivered</option>
                      </select>
                    </div>
                  </div>
                )
              ) : (
                <p>No owned products available.</p>
              )}
  
              <h4 className="mt-4 font-semibold">Other Sellers' Products</h4>
              {notOwnedItems.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border-collapse border mt-2">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border px-4 py-2">Item ID</th>
                        <th className="border px-4 py-2">Quantity Sold</th>
                        <th className="border px-4 py-2">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notOwnedItems.map((item, index) => (
                        <tr key={index}>
                          <td className="border px-4 py-2">{item._id || "Unknown"}</td>
                          <td className="border px-4 py-2">{item.quantity || 0}</td>
                          <td className="border px-4 py-2">
                            <select
                              value={item.status}
                              onChange={(e) =>
                                handleOrderItemStatusChange(e.target.value, item._id)
                              }
                              className="border rounded py-1 px-2 w-full text-center"
                              disabled={lowerRole !== "admin"}
                            >
                              <option value="pending">Pending</option>
                              <option value="dispatched">Dispatched</option>
                              <option value="delivered">Delivered</option>
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No other sellers' products available.</p>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
  
};

ExpandableRow.defaultProps = {
  currentSellerId: "",
  role: ""
};

export default ExpandableRow;

