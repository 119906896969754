import React, { useContext, forwardRef, useState, useEffect } from 'react'
import scrollTop from '../helpers/scrollTop'
import displayPrice from '../helpers/FormatPrice'
import Context from '../context'
import addToCart from '../helpers/AddToCart'
import { Link, useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify'


// const AllVerticalProductCard = forwardRef(({ loading, data = [] }, ref) => {
//     const loadingList = new Array(13).fill(null)
//     const { fetchUserAddToCartCount } = useContext(Context)
//     const [selectedSize, setSelectedSize] = useState("");
//     const [availableColors, setAvailableColors] = useState([]);
//     const [availableSizes, setAvailableSizes] = useState([]);
//     const [selectedColor, setSelectedColor] = useState(null);
//     // cost[hasSizeOptions, setHasSizeOptions] = useState("")

//      const navigate = useNavigate();

    
//     const handleSizeSelect = (selectedSize) => {
//       setSelectedSize(selectedSize);
  
//       // Find the size object for the selected size
//       const selectedSizeObj = data.sizes.find((sizeObj) => sizeObj.size === selectedSize);
  
//       if (selectedSizeObj && selectedSizeObj.colors) {
//           const extractedColors = selectedSizeObj.colors.map((colorObj) => colorObj.color);
//           setAvailableColors(extractedColors);
  
//           // Reset selectedColor if it is not in the newly available colors
//           if (!extractedColors.includes(selectedColor)) {
//               setSelectedColor(null);
//           }
//       } else {
//           setAvailableColors([]);
//           setSelectedColor(null);
//       }
//   };
  
 
//     const handleAddToCart = async(e, id, shouldNavigate = false) => {
//         e.preventDefault()

//         if (data?.sizes?.length > 0 && !selectedSize) {
//           toast.error("Please select a size before adding to cart.");
//           return;
//         }

//         if(data?.sizes?.colors.length > 0 || data?.sizes.size.length >= 1 ){
//           if (!selectedColor || !availableColors.includes(selectedColor)) {
//               toast.error("Please select a valid color before adding to cart.");
//               return;
//           }
      
//           console.log("Adding to Cart:", { productId: id, size: selectedSize, color: selectedColor });
//         }

//        await addToCart(e, id, selectedSize || null)
//        fetchUserAddToCartCount();

//        if (shouldNavigate) {
//         navigate('/cart');
//     }
//     }

//     // const handleSizeChange = (e) => {
//     //     setSelectedSize(e.target.value);
//     // };

//     return (
//         <div className='flex flex-wrap md:grid grid-cols-[repeat(auto-fit,minmax(260px,300px))] justify-center md:justify-between md:gap-4 gap-[35px] overflow-x-scroll scrollbar-none transition-all customMax-525:flex customMax-525:flex-col customMax-525:border-2 customMax-525:max-w-full customMax-525:w-full items-center customMax-525:p-2'>
//             {
//                 loading ? (
//                     loadingList.map((_, index) => (
//                         <div className='w-full min-w-[280px]  md:min-w-[320px] max-w-[280px] md:max-w-[320px]  bg-white rounded-sm shadow ' key={index}>
//                             <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse' />
//                             <div className='p-4 grid gap-3'>
//                                 <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
//                                 <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200  py-2'></p>
//                                 <div className='flex gap-3'>
//                                     <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full  py-2'></p>
//                                     <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full  py-2'></p>
//                                 </div>
//                                 <button className='text-sm  text-white px-3  rounded-full bg-slate-200  py-2 animate-pulse'></button>
//                             </div>
//                         </div>
//                     ))
//                 ) : (
//                     data.map((product, index) => (
//                         <>
//                         <Link to={`/product/${product?._id}`} 
//                             className='w-full min-w-[280px]  md:min-w-[300px] max-w-[280px] md:max-w-[300px] bg-white rounded-sm shadow customMax-525:max-w-[340px]' 
//                             onClick={scrollTop} 
//                             ref={index === data.length - 1 ? ref : null} // Assign ref to the last item
//                             key={product._id}>
//                             <div className='bg-slate-150 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center '>
//                                 <img src={product?.productImage[0]} alt='product image' className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' />
//                             </div>
//                         </Link>
//                         <div className='flex items-center justify-between p-4 '>
//                             <div className='flex flex-col'>
//                                 <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'>{product?.itemName}</h2>
//                                 <p className='capitalize text-slate-500'>{product?.category}</p>
//                                 <div className='flex gap-3'>
//                                     <p>{displayPrice(product?.price, product?.currency)}</p>
//                                 </div>
//                             </div>
                        
//                             <div className=''>
//                                 {product?.sizes?.length > 0 && (
//                                     <div className="my-3">
//                                         <p className="font-medium">Select Size:</p>
//                                         <div className="flex gap-2 flex-wrap">
//                                             {product.sizes.map((sizeObj, index) => (
//                                                 <button
//                                                     key={index}
//                                                     className={`px-4 py-2 border rounded ${
//                                                         selectedSize === sizeObj.size
//                                                             ? "bg-orange-600 text-white"
//                                                             : "border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white"
//                                                     }`}
//                                                     onClick={() => handleSizeSelect(sizeObj.size)}
//                                                 >
//                                                     {sizeObj.size}
//                                                 </button>
//                                             ))}
//                                         </div>
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                         <div className='p-4 pt-0 grid mt-'>
//                             <button className='bg-orange-600 hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full' 
//                             onClick={(e) => handleAddToCart(e, product?._id)}>Add to Cart
//                             </button>
//                         </div>
                        
//                         </> 
                        
//                     ))
//                 )
//             }
//         </div>
//     )

//     // return (
//     //     <div className='flex flex-wrap md:grid grid-cols-[repeat(auto-fit,minmax(260px,1fr))] justify-center md:gap-4 gap-[35px] overflow-x-scroll scrollbar-none transition-all customMax-525:flex customMax-525:flex-col customMax-525:border-2 customMax-525:max-w-full customMax-525:w-full items-center customMax-525:p-2'>
//     //       {loading ? (
//     //         loadingList.map((_, index) => (
//     //           <div
//     //             className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'
//     //             key={index}
//     //           >
//     //             <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse' />
//     //             <div className='p-4 grid gap-3'>
//     //               <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
//     //               <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2'></p>
//     //               <div className='flex gap-3'>
//     //                 <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
//     //                 <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
//     //               </div>
//     //               <button className='text-sm text-white px-3 rounded-full bg-slate-200 py-2 animate-pulse'></button>
//     //             </div>
//     //           </div>
//     //         ))
//     //       ) : (
//     //         data.map((product, index) => (
//     //           <div
//     //             key={product._id}
//     //             className='w-full min-w-[280px] md:min-w-[300px] max-w-[280px] md:max-w-[300px] bg-white rounded-sm shadow customMax-525:max-w-[340px]'
//     //           >
//     //             {/* Wrap only Image & Title in Link */}
//     //             <Link to={`/product/${product?._id}`} className='block' onClick={scrollTop}>
//     //               <div className='bg-slate-150 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
//     //                 <img
//     //                   src={product?.productImage[0]}
//     //                   alt='product image'
//     //                   className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
//     //                 />
//     //               </div>
//     //             </Link>
      
//     //             {/* Product Details */}
//     //             <div className='flex items-center p-4 py-1'>
//     //               <div className='flex flex-col'>
//     //                 <Link to={`/product/${product?._id}`} onClick={scrollTop}>
//     //                   <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'>
//     //                     {product?.itemName}
//     //                   </h2>
//     //                 </Link>
//     //                 <p className='capitalize text-slate-500'>{product?.category}</p>
//     //                 <p>{displayPrice(product?.price, product?.currency)}</p>
//     //               </div>
//     //             </div>
      
//     //             {/* Size Selection as Dropdown */}
//     //             {product?.sizes?.length > 0 && (
//     //               <div className='p-4 pt-0'>
//     //                 <label className='font-medium' htmlFor={`size-select-${product._id}`}>
//     //                   Select Size:
//     //                 </label>
//     //                 <select
//     //                   id={`size-select-${product._id}`}
//     //                   className='w-full px-4 py-2 border rounded border-orange-600 text-orange-600 focus:outline-none'
//     //                   onChange={(e) => handleSizeSelect(e.target.value)}
//     //                 >
//     //                   <option value='' disabled selected>
//     //                     Choose a size
//     //                   </option>
//     //                   {product.sizes.map((sizeObj, index) => (
//     //                     <option key={index} value={sizeObj.size}>
//     //                       {sizeObj.size}
//     //                     </option>
//     //                   ))}
//     //                 </select>
//     //               </div>
//     //             )}
      
//     //             {/* Add to Cart */}
//     //             <div className='p-4 pt-0 grid'>
//     //               <button
//     //                 className='bg-orange-600 hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full'
//     //                 onClick={(e) => {
//     //                   e.stopPropagation(); // Prevent navigation
//     //                   handleAddToCart(e, product?._id);
//     //                 }}
//     //               >
//     //                 Add to Cart
//     //               </button>
//     //             </div>
//     //           </div>
//     //         ))
//     //       )}
//     //     </div>
//     //   );
      
  
      
// });

// export default AllVerticalProductCard;

const AllVerticalProductCard = forwardRef(({ loading, data = [] }, ref) => {
  const loadingList = new Array(13).fill(null);
  const { fetchUserAddToCartCount } = useContext(Context);
  const [selectedSize, setSelectedSize] = useState({});
  const [availableColors, setAvailableColors] = useState({});
  const [selectedColor, setSelectedColor] = useState({});
  const [selectedPriceType, setSelectedPriceType] = useState({});
  const [forceRender, setForceRender] = useState(false);


  // Handle size selection and dynamically update available colors
  const handleSizeSelect = (productId, size) => {
      setSelectedSize((prev) => ({ ...prev, [productId]: size }));

      // Find the size object for the selected size
      const selectedSizeObj = data.find((prod) => prod._id === productId)?.sizes.find((sizeObj) => sizeObj.size === size);

      if (selectedSizeObj && selectedSizeObj.colors && selectedSizeObj.colors.length > 0) {
          const extractedColors = selectedSizeObj.colors.map((colorObj) => colorObj.color);
          setAvailableColors((prev) => ({ ...prev, [productId]: extractedColors }));

          // Reset selected color if it's not in the newly available colors
          if (!extractedColors.includes(selectedColor[productId])) {
              setSelectedColor((prev) => ({ ...prev, [productId]: null }));
          }
      } else {
          setAvailableColors((prev) => ({ ...prev, [productId]: [] }));
          setSelectedColor((prev) => ({ ...prev, [productId]: null }));
      }
  };

 

    const handleAddToCart = async (e, product) => {
        e.preventDefault();

        const { _id, sizes, airShippingCost, waterShippingCost } = product;
        const size = selectedSize[_id] || null;
        const color = selectedColor[_id] || null;

        // Get the selected price type (default to air if not selected)

        let selectedPriceTypeForProduct = null
        if (product?.category === 'Services'){
            selectedPriceTypeForProduct = "email"
        } else {
            selectedPriceTypeForProduct = selectedPriceType[_id] ||  product?.initialPriceType  || "air";
        }
        

        let shippingCost = null
        if (selectedPriceTypeForProduct === "water"){
            shippingCost= Number(waterShippingCost) || 0
        } else if (selectedPriceTypeForProduct === "air"){
            shippingCost= Number(airShippingCost) || 0;
        } else {
            shippingCost= 0
        }

        // Validate size selection if required
        if (sizes?.length > 0 && !size) {
            toast.error("Please select a size before adding to cart.");
            return;
        }

        // Validate color selection if required
        if (sizes?.length > 0 && availableColors[_id]?.length > 0) {
            if (!color || !availableColors[_id].includes(color)) {
                toast.error("Please select a valid color before adding to cart.");
                return;
            }
        }
        

        await addToCart(e, _id, size, color, selectedPriceTypeForProduct, shippingCost);
        fetchUserAddToCartCount();
    };

    const getDisplayedPrice = (product) => {
        const price = Number(product?.price) || 0;
        const totalPriceByAir = Number(product?.totalPriceByAir) || 0;
        const totalPriceByWater = Number(product?.totalPriceByWater) || 0;
        const backendDefaultPriceType = product?.initialPriceType || "air"; // ✅ Get from backend

        let displayedPrice = displayPrice(price, product?.currency); // Default price
        let offerLabel = null;
        let deliveryLabel = "Standard Delivery (1-2 weeks)"; // Default shipping

        // ✅ Use backend's default price type if available, otherwise fallback
        const initialPriceType = backendDefaultPriceType;

        // ✅ Get the selected price type from state or use the backend default
        const finalPriceType = selectedPriceType[product._id] || initialPriceType;

        if (totalPriceByAir > 0 && totalPriceByWater > 0) {
            if (finalPriceType === "water") {
                displayedPrice = displayPrice(totalPriceByWater, product?.currency);
                deliveryLabel = `Standard Shipping (${product?.waterDuration || "4-6 weeks"})`;
                offerLabel = `Choose Faster Shipment: ${displayPrice(totalPriceByAir, product?.currency)}`;
            } else if (finalPriceType === "air") {
                displayedPrice = displayPrice(totalPriceByAir, product?.currency);
                deliveryLabel = `Faster Delivery (${product?.airDuration || "5-10 days"})`;
                offerLabel = `Save with Water Shipping: ${displayPrice(totalPriceByWater, product?.currency)}`;
            }
        }

        return { displayedPrice, offerLabel, deliveryLabel, initialPriceType };
    };



    const handlePriceSelection = (productId) => {
        setSelectedPriceType((prev) => {
            const newType = prev[productId] === "water" ? "air" : "water";
            return {
                ...prev,
                [productId]: newType,  // ✅ Immediately update the state correctly
            };
        });
    };



    useEffect(() => {
        if (data.length > 0) {
            const initialPriceTypeState = {};
            data.forEach((product) => {
                initialPriceTypeState[product._id] = product?.initialPriceType || "air"; // ✅ Get from backend
            });
            setSelectedPriceType(initialPriceTypeState);
        }
    }, [data]);


    //percentage difference
    const calculatePercentageDifference = (totalPriceByAir, totalPriceByWater) => {
    if (!totalPriceByAir || !totalPriceByWater) return "0%"; // Avoid division errors

    const difference = Math.abs(totalPriceByAir - totalPriceByWater);
    const maxPrice = Math.max(totalPriceByAir, totalPriceByWater);

    return `${Math.round((difference / maxPrice) * 100)}%`; // Rounded result with % sign
    };
    
  return (
      <div className='flex flex-wrap md:grid grid-cols-[repeat(auto-fit,minmax(260px,1fr))] justify-center md:gap-4 gap-[35px] overflow-x-scroll scrollbar-none transition-all customMax-525:flex customMax-525:flex-col customMax-525:border-2 customMax-525:max-w-full customMax-525:w-full items-center customMax-525:p-2'>
          {loading ? (
              loadingList.map((_, index) => (
                  <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
                      <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse' />
                      <div className='p-4 grid gap-3'>
                          <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
                          <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2'></p>
                          <div className='flex gap-3'>
                              <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                              <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                          </div>
                          <button className='text-sm text-white px-3 rounded-full bg-slate-200 py-2 animate-pulse'></button>
                      </div>
                  </div>
              ))
          ) : (
              data.map((product, index) => {
                const { displayedPrice, offerLabel, deliveryLabel } = getDisplayedPrice(product, selectedPriceType[product._id] || "default");

                    return (
                  <div key={product._id} className='w-full min-w-[280px] md:min-w-[300px] max-w-[280px] md:max-w-[300px] bg-white rounded-sm shadow customMax-525:max-w-[340px]'>
                      {/* Product Image */}
                      <Link to={`/product/${product?._id}`} className='block' onClick={scrollTop}>
                          <div className='bg-slate-150 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
                              <img src={product?.productImage[0]} alt='product image' className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' />
                          </div>
                      </Link>

                      {/* Product Details */}
                    

                    <div className='flex items-center p-4 py-1'> 
                        <div className='flex flex-col'>
                            <Link 
                                to={`/product/${product?._id}`} 
                                onClick={scrollTop} 
                                ref={index === data.length - 1 ? ref : null} // ✅ Include the scrolling ref
                                key={product._id}
                            >
                                <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'>
                                    {product?.itemName}
                                </h2>
                            </Link>
                            <p className='capitalize text-slate-500'>{product?.category}</p>

                            {product?.category === "Services" ? (
                                <>
                                    <p className="font-semibold text-gray-800">{displayPrice(product?.price, product?.currency)}</p>
                                    <p className="text-xs text-gray-500">Email delivery</p>
                                </>
                            ) : (
                                <>
                                    <p className="font-semibold text-gray-800">{displayedPrice}</p>
                                    <p className="text-xs text-gray-500">{deliveryLabel}</p>
                                    {offerLabel && (
                                        <div className="bg-orange-100 border-l-4 border-orange-500 p-2 mt-2 rounded-md">
                                            <p className="text-sm text-orange-700 font-semibold">{offerLabel}</p>
                                            <button
                                                className="mt-1 text-xs text-blue-600 underline cursor-pointer"
                                                onClick={() => handlePriceSelection(product._id)} // ✅ Toggle price selection
                                            >
                                                {selectedPriceType[product._id] === "water" 
                                                    ? "Faster Delivery" 
                                                    : `${calculatePercentageDifference(product.totalPriceByAir, product.totalPriceByWater)} discount`}
                                            </button>
                                        </div>
                                    )}
                                
                                </>
                            )}
                        </div>
                    </div>



                      {/* Size & Color Selection */}
                      <div className="p-4 pt-0">
                          {/* Size Selection */}
                          {product?.sizes?.length > 0 && (
                              <div className="my-3">
                                  <p className="font-medium">Select Size:</p>
                                  <div className="flex gap-2 flex-wrap">
                                      {product.sizes.map((sizeObj, index) => (
                                          <button
                                              key={index}
                                              className={`px-4 py-2 border rounded ${
                                                  selectedSize[product._id] === sizeObj.size
                                                      ? "bg-orange-600 text-white"
                                                      : "border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white"
                                              }`}
                                              onClick={() => handleSizeSelect(product._id, sizeObj.size)}
                                          >
                                              {sizeObj.size}
                                          </button>
                                      ))}
                                  </div>
                              </div>
                          )}

                          {/* Color Selection */}
                          {availableColors[product._id]?.length > 0 && (
                              <div className="my-3">
                                  <p className="font-medium">Available Colors:</p>
                                  <div className="flex gap-2 flex-wrap">
                                      {availableColors[product._id].map((color, index) => (
                                          <button
                                              key={index}
                                              className={`w-8 h-8 rounded-full border-2 transition duration-200
                                                  ${
                                                      selectedColor[product._id] === color
                                                          ? "border-orange-600 scale-110"
                                                          : "border-gray-400 hover:border-orange-600"
                                                  }`}
                                              onClick={() => setSelectedColor((prev) => ({ ...prev, [product._id]: color }))}
                                              style={{ backgroundColor: color }}
                                          />
                                      ))}
                                  </div>
                              </div>
                          )}
                      </div>

                      {/* Add to Cart */}
                      <div className='p-4 pt-0 grid'>
                          <button className='bg-orange-600 hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full' onClick={(e) => handleAddToCart(e, product)}>
                              Add to Cart
                          </button>
                      </div>
                  </div>
              )})
          )}
      </div>
  );
});

export default AllVerticalProductCard;



