const backendDomain = //"https://www.api.homemarket2u.com"
process.env.NODE_ENV === "production"
? process.env.REACT_APP_BACKEND_URL_PRODUCTION
: "http://localhost:8000" // process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
// const backendDomain =
//   process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_BACKEND_URL_PRODUCTION
//     : process.env.REACT_APP_BACKEND_URL_STAGING;


const summaryApi = {
    signUp : {
        url : `${backendDomain}/api/signup`,
        method :"post"
    },

    updateUserAccount : {
        url : `${backendDomain}/api/update-profile-detail`,
        method : "PUT"
    },

    
    updateFreelancerAccount : {
        url : `${backendDomain}/api/update-freelancer-account`,
        method : 'PUT'
    },
  
    otp_verification : {
        url : `${backendDomain}/api/verify-otp`,
        method : "post"
    },

    
    resend_otp : {
        url : `${backendDomain}/api/resend-otp`,
        method : "post"
    },

    signIn : {
        url : `${backendDomain}/api/login`,
        method : "post"
    },

    googleLogin : {
        url : `${backendDomain}/api/auth/google`,
        method : "post"
    },

    changePassword : {
        url : `${backendDomain}/api/change-password`,
        method : 'post'
    },

    verifyChangePassword : {
        url : `${backendDomain}/api/verify-change-password`,
        method : 'post'
    },

    googleLoginUpdate: {
        url : `${backendDomain}/api/google-login-update`,
        method : "post"
    },

    current_user : {
        url : `${backendDomain}/api/user-details`,
        method : "get"
    },

    logout_user : {
        url : `${backendDomain}/api/userLogout`,
        method : "get"
    }, 

    allUsers : {
        url : `${backendDomain}/api/all-user`,
        method : "get"
    }, 

    updateUser : {
        url : `${backendDomain}/api/update-user`,
        method : "put"
    },


    productUpload : {
        url : `${backendDomain}/api/upload-product`,
        method : "post"
    },
    
    allProduct : {
        url : `${backendDomain}/api/get-product`,
        method : "get"
    },

    contactSeller: {
        url: `${backendDomain}/api/contact-seller`,
        method: 'post'
    },

    updateProduct : {
        url : `${backendDomain}/api/update-product`,
        method : "post"
    },

    getCategoryProduct : {
        url : `${backendDomain}/api/get-categoryProduct`,
        method : "post"
    },

    ProductsByCategory : {
        url : `${backendDomain}/api/products-bycategory`,
        method : "post"
    },

    productDetails : {
        url : `${backendDomain}/api/product-details`,
        method : "post"
    },

    addToCardProduct : {
        url : `${backendDomain}/api/addtocart`,
        method : "post"
    },

    addToCartProductCount : { 
        url : `${backendDomain}/api/countAddToCartProduct`,
        method : "get"
    },

    viewAddToCartProducts : { 
        url : `${backendDomain}/api/view-cart-product`,
        method : "get"
    },

    updateCartProducts : { 
        url : `${backendDomain}/api/update-cart-product`,
        method : "post"
    },

    deleteCartProducts : { 
        url : `${backendDomain}/api/delete-cart-product`,
        method : "post"
    },

    updateSellerAccount: {
        url: `${backendDomain}/api/seller-update`,
        method: 'post'
    },

    searchProduct : { 
        url : `${backendDomain}/api/product-search`,
        method : "get"
    },

    filterProduct : {
        url : `${backendDomain}/api/filter-product`,
        method : "post"
    },

    productApproval : {
        url :`${backendDomain}/api/product-approval`,
        method : 'post'
    },

    pendingApprovalProd : {
        url :`${backendDomain}/api/pending-approval`,
        method : 'get'
    },

    RejectedProduct : {
        url :`${backendDomain}/api/rejected-prod`,
        method : 'get'
    },

    imageUpload : {
        url : `${backendDomain}/api/upload-images`,
        method : 'post'
    },

    deleteImage : {
        url : `${backendDomain}/api/delete-image`,
        method : 'post'
    },

    productPurchase : {
        url : `${backendDomain}/api/checkout`,
        method : 'post'
    },

    getOrder : {
        url : `${backendDomain}/api/get-order`,
        method : "get"
    },

    getOrderItem : {
        url : `${backendDomain}/api/orders/:orderId/items`,
        method : "get"
    },

    updateOrder : {
        url : `${backendDomain}/api/update-order`,
        method : "PUT"
    },

    updateOrderItem : {
        url : `${backendDomain}/api/update-order-item`,
        method : "PUT"
    },

    getFailedWebhook : {
        url : `${backendDomain}/api/get-failed-webhook`,
        method: 'get'
    },

    retryFailedWebhook : {
        url : `${backendDomain}/api/retry-webhook`,
        method : 'post'
    },

    getFailedEmail : {
        url : `${backendDomain}/api/get-failed-email`,
        method: 'get'
    },

    retryOrderFailedEmail : {
        url : `${backendDomain}/api/retry-order-email`,
        method : 'post'
    },

    sellerTransactionSummary : {
        url : `${backendDomain}/api/seller-transaction-summary`,
        method : 'get'
    },

    adminTransactionSummary : {
        url : `${backendDomain}/api/admin-transaction-summary`,
        method : 'get'
    },

    fundReleaseUpdate : {
        url : `${backendDomain}/api/fund-release-update`,
        method : 'post'
    },

    stripeWebhook : {
        url : `${backendDomain}/api/stripe-webhook`,
        method: 'post'
    },

    guestCheckout : {
        url : `${backendDomain}/api/guest-checkout`,
        method : 'post'
    },

    purchaseHistory : {
        url : `${backendDomain}/api/purchase-history`,
        method : 'get'
    }, 

    shipmentRate : {
        url : `${backendDomain}/api/create-shipment`,
        method : 'post'
    },

    getShipmentRate : {
        url : `${backendDomain}/api/get-shipment-details`,
        method : 'get'
    },

    updateShipment : { 
        url : `${backendDomain}/api/update-shipment`,
        method : 'PUT'
    },

    deleteShipment : {
        url : `${backendDomain}/api/delete-shipment`,
        method : 'DELETE'
    },

    createFaqs : {
        url : `${backendDomain}/api/create-faqs`,
        method : 'post'
    },

    getFaqs : {
        url : `${backendDomain}/api/get-faqs`,
        method : 'get'
    },

    updateFaqs : {
        url : `${backendDomain}/api/update-faqs`,
        method : 'PUT'
    },

    DeleteFaqs : {
        url : `${backendDomain}/api/delete-faqs`,
        method : 'DELETE'
    },

    createBanner : {
        url : `${backendDomain}/api/upload-banner`,
        method: 'post'
    },

    fetchBanner : {
        url : `${backendDomain}/api/get-banner`,
        method : 'get'
    },

    getFreelancerDetail : {
        url : `${backendDomain}/api/get-freelance-details`,
        method: 'get'
    },

    hireFreelancer: {
        url : `${backendDomain}/api/hire-freelancer`,
        method : 'post'
    },

    getOffers: {
        url: `${backendDomain}/api/get-offers`,
        method: 'get'
    },

    acceptHire: {
        url :`${backendDomain}/api/accept-hire`,
        method: 'post'
    },

    rejectHire: {
        url : `${backendDomain}/api/reject-hire`,
        method: 'post'
    },

    vendorSubmitForm: {
        url : `${backendDomain}/api/submit-vendor-form`,
        method: 'post'
    },

    fetchVendorSubmittedForm: {
        url: `${backendDomain}/api/fetch-vendor-submit-form`,
        method: 'get'
    },

    updateVendorStatus: {
        url: `${backendDomain}/api/update-vendor-status`,
        method: 'PUT'
    },

    createShipmentRate: {
        url: `${backendDomain}/api/create-shipment-rate`,
        method: 'post'
    },

    getShippingRate: {
        url: `${backendDomain}/api/get-shipping-rate`,
        method: 'get'
    },

    updateshippingRate: {
        url: `${backendDomain}/api/update-shipping-rate`,
        method: 'put'
    },

    deleteShippingRate: {
        url: `${backendDomain}/api/delete-shipping-rate`,
        method: 'delete'
    },

    computeToatalPriceAndShipment: {
        url: `${backendDomain}/api/compute-totalPrice-shipment`,
        method: 'post'
    },

    dashboardSearchProduct: {
        url: `${backendDomain}/api/dashboard-search-product`,
        method: 'get'
    },

    dashboardDeleteProduct: {
        url: `${backendDomain}/api/dashboard-delete-product`,
        method: 'post'
    }
}

export default summaryApi