import React, { useState, useEffect, useContext } from 'react';
import Context from '../../context';
import summaryApi from '../../common';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdDelete } from 'react-icons/md';
import DisplayImage from '../../components/DisplayImage';

const SellerUpdateAccount = () => {
  const userDetails = useSelector((state) => state?.user?.user);
  const loadingList = new Array(13).fill(null)
  const [formData, setFormData] = useState();
  const [isSellerIdLoading, setIsSellerIdLoading] = useState(false); // Separate state for wall picture
  const [isProfilePicLoading, setIsProfilePicLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetchUserdetails } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true)
  const[fullScreenImage, setFullScreenImage] = useState("")
  const[openFullScreenImage, setOpenFullScreenImage] = useState("")
  const navigate = useNavigate();

  // Fetch existing seller data when the component mounts

  useEffect(() => {
    if (userDetails) {
      setFormData({
        name: userDetails.name || "",
        profilePic: userDetails.profilePic || "",
        mobileNumber: userDetails.mobileNumber || "",
        address: userDetails.address || '',
        companyName: userDetails.companyName || '',
        sellerLegalId: userDetails.sellerLegalId || [],
      });
    }
  }, [userDetails]); // Runs only when userDetails updates


  useEffect(()=>{
    fetchUserdetails();
  },[])

    useEffect(() => {
      // When userDetails & freelancerDetails update, stop loading
      if (userDetails) {
        setIsLoading(false);
      }
    }, [userDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };


  const handleUpload = async (e, type) => {
    const  name  = e.target.name; // Get the input name (profilePic or sellerLegalId)
    const files = e.target.files; // Get selected files
    
    if (!files || files.length === 0) return;

    const isSellerDocument = type === "sellerLegalId";
    const isProfilePic = type === "profilePic";

    if (isProfilePic) {
      console.log("Profile picture upload started..."); // Debugging
      setIsProfilePicLoading(true); // Set loading state for profile pic
    } else if (isSellerDocument) {
      console.log("Seller document upload started..."); // Debugging
      setIsSellerIdLoading(true); // Set loading state for seller document
    }
  
  
    const formData = new FormData();
    for (let file of files) {
      formData.append("files", file);
    }
    
    formData.append("type", name); // Dynamically set the type (profilePic or sellerLegalId)
  
    try {
      const response = await fetch(summaryApi.imageUpload.url, {
        method: summaryApi.imageUpload.method,
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Failed to upload ${name}`);
      }
  
      const { imageUrls } = await response.json();
  
      setFormData((prev) => ({
        ...prev,
        [name]: name === "sellerLegalId" 
          ? [...prev.sellerLegalId, ...imageUrls]  // Append new images if it's sellerLegalId
          : imageUrls[0], // Single image for profilePic
      }));
    } catch (error) {
      console.error(`Error uploading ${name}:`, error.message);
    } finally {
      if(isProfilePic){
        setIsProfilePicLoading(false)
      } else if(isSellerDocument){
        setIsSellerIdLoading(false)
      }
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataCopy = { ...formData };
  
      // Convert files to strings or remove them if unchanged
      ['profilePic'].forEach((fileField) => {
        if (formData[fileField] instanceof File) {
          // Process the file upload (e.g., upload to a server or cloud storage)
          // Example: Replace with the file's URL after upload
          formDataCopy[fileField] = 'uploaded_file_url';
        } else if (typeof formData[fileField] !== 'string') {
          delete formDataCopy[fileField];
        }
      });
  
      const response = await fetch(summaryApi.updateSellerAccount.url, {
        method: summaryApi.updateSellerAccount.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataCopy),
      });
  
      const result = await response.json();
      if (response.ok) {
        toast.success('Profile updated successfully!');
        await fetchUserdetails();
        navigate('/dashboard/all-products');
      } else {
        toast.error(result.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('An unexpected error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteImage = async(index) =>{
    const newProductImage = [...formData.sellerLegalId]
    newProductImage.splice(index,1)

    setFormData((preve) => {
     return {
         ...preve,
         sellerLegalId : [...newProductImage]
     }
    })
 }
  

  return (
    <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg p-6 ">
      { isLoading ? (
          loadingList.map((_, index) => (
            <div
              className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'
              key={index}
            >
              <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse' />
              <div className='p-4 grid gap-3'>
                <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
                <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2'></p>
                <div className='flex gap-3'>
                  <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                  <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                </div>
                <button className='text-sm text-white px-3 rounded-full bg-slate-200 py-2 animate-pulse'></button>
              </div>
            </div>
          ))
        ) : (
          <>
            <h2 className="text-orange-600 text-2xl font-bold text-center mb-6">Update Your Profile</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className='flex gap-3'>
                {isProfilePicLoading ? (
                  <div className="flex items-center justify-center h-24 w-24">
                    <div key={isProfilePicLoading ? "loading" : "loaded"} className="animate-spin rounded-full h-10 w-10 border-t-4 border-blue-500 z-10"></div>
                  </div>
                ) : (
                  <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Profile Picture</label>
                  <img 
                  src={formData.profilePic}
                  alt="profile pic"
                  className='h-24 w-24 object-cover border-white rounded-full border-2 mb-1 '
                  />
                  <input
                    type="file"
                    name="profilePic"
                    onChange={(e) => handleUpload(e, 'profilePic')}  
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
                    accept="image/*"
                  />
                </div>
                )}
                
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Real Names</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name || ''}
                  onChange={handleChange}
                  className="w-full border-gray-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Mobile  Number</label>
                <input
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber|| ''}
                  onChange={handleChange}
                  className="w-full border-gray-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Seller Address</label>
                <textarea
                  type="textarea"
                  name="address"
                  value={formData.address|| ''}
                  onChange={handleChange}
                  className="w-full border-gray-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
                ></textarea>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Buisness Name</label>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName || ''}
                  onChange={handleChange}
                  className="w-full border-gray-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
                />
              </div>
                {/* need seelers to send document via email other than upload here. */}
              {/* {
                isSellerIdLoading ? (
                  <div className="w-full h-full flex items-center justify-center bg-white">
                    <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-blue-500"></div>
                  </div>
                ) : (
                  <div className='flex gap-3'>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Upload ID</label>
                    <input
                      type="file"
                      name="sellerLegalId"
                      multiple 
                      onChange={(e) => handleUpload(e, 'sellerLegalId')}  
                      className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
                      accept="image/*"
                    />
                  </div>
                 <div>
                    {formData?.sellerLegalId[0] ? (
                      <div className='flex items-center gap-3'>
                        {formData.sellerLegalId.map((el, index) => {
                          return (
                            <div className='relative group' key={index}>
                              <img
                                src={el}
                                alt={"Seller Legal ID"}
                                width={100}
                                height={100}
                                className='bg-slate-100 border cursor-pointer'
                                required
                                onClick={() => {
                                  setOpenFullScreenImage(true);
                                  setFullScreenImage(el);
                                }}
                              />
                              <div
                                className='bg-orange-500 text-white rounded-full cursor-pointer absolute bottom-0 right-0 p-1 '
                                onClick={() => handleDeleteImage(index)}
                              >
                                <MdDelete />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <p className='text-red-600 text-xs'>*please upload product image*</p>
                    )}
                  </div>
                </div>
                )
              } */}

              <p className='text-blue-600'>* Please email your prove of Identity and company certificate if have one to contact@homemarket2u.com *</p>

              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full text-white font-semibold py-2 px-4 rounded-md shadow-sm ${
                  isSubmitting
                    ? 'bg-orange-400 cursor-not-allowed'
                    : 'bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
                }`}
              >
                {isSubmitting ? 'Updating...' : 'Update Profile'}
              </button>
            </form>
            {openFullScreenImage && (
              <DisplayImage
                onClose={() => setOpenFullScreenImage(false)}
                imgUrl={fullScreenImage}
              />
            )}
          </>
        )}
      
    </div>
  );
  
};

export default SellerUpdateAccount;
