// import React, { useState } from 'react'
// import summaryApi from '../../common'
// import { toast } from 'react-toastify'
  

// const ErrorLog = () => {
//   const [failedWebhookId, setFailedWebhookId]= useState("")
//   const [failedEmailId, setFailedEmailId]= useState("")

//   const fetchWebhookError = async()=>{
//     try{
//         const response = await fetch(summaryApi.getFailedWebhook.url,{
//           method: summaryApi.getFailedWebhook.method,
//           credentials: 'include',
//         })
//         const responseData = response.json()
//         if(responseData.error){
//           toast.error(responseData.message)
//         } else if(responseData.seccess){
//           setFailedWebhookId(responseData.data?._id)
//         }
//       }catch(error){
//         console.error('Error during payment process:', error);
//         toast.error(error)
//       }
//     }
  
//   const retryWebhookError = async()=>{
//     try{
//       const response = await fetch(summaryApi.retryFailedWebhook.url,{
//         method: summaryApi.retryFailedWebhook.method,
//         credentials: 'include',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(failedWebhookId),
//       })
//       const responseData = response.json()
//       if(responseData.error){
//         toast.error(responseData.message)
//       }
//     }catch(error){
//       console.error('Error during payment process:', error);
//       toast.error(error)
//     }
//   }

//   const fetchFailedEmailLog = async()=>{
//     try{
//         const response = await fetch(summaryApi.getFailedEmail.url,{
//           method: summaryApi.getFailedEmail.method,
//           credentials: 'include',
//         })
//         const responseData = response.json()
//         if(responseData.error){
//           toast.error(responseData.message)
//         } else if(responseData.seccess){
//           setFailedEmailId(responseData.data?._id)
//         }
//       }catch(error){
//         console.error('Error during payment process:', error);
//         toast.error(error)
//       }
//   }

//   const retryFailedEmail = async()=>{
//     try{
//       const response = await fetch(summaryApi.retryOrderFailedEmail.url,{
//         method: summaryApi.retryOrderFailedEmail.method,
//         credentials: 'include',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(failedEmailId),
//       })
//       const responseData = response.json()
//       if(responseData.error){
//         toast.error(responseData.message)
//       }
//     }catch(error){
//       console.error('Error during payment process:', error);
//       toast.error(error)
//     }
//   }
//   return (
//     <div>log</div>
//   )
// }

// export default ErrorLog


import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardContent,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
} from '@mui/material';
import summaryApi from '../../common';

const ErrorLog = () => {
  const [failedWebhooks, setFailedWebhooks] = useState([]);
  const [failedEmails, setFailedEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('webhooks');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    if (activeTab === 'webhooks') {
      fetchWebhookErrors(page, filter);
    } else {
      fetchEmailErrors(page, filter);
    }
  }, [activeTab, page, filter]);

  const fetchWebhookErrors = async (currentPage, filterBy) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${summaryApi.getFailedWebhook.url}?page=${currentPage}&filter=${filterBy}`,
        {
          method: summaryApi.getFailedWebhook.method,
          credentials: "include",
        }
      );
      const data = await response.json();
      if (data.error) {
        toast.error(data.message);
      } else {
        setFailedWebhooks(data.data);
        setTotalPages(data.totalPages);
      }
    } catch (error) {
      console.error("Error fetching webhook logs:", error);
      toast.error("Failed to fetch webhook logs");
    }
    setLoading(false);
  };
  
  const fetchEmailErrors = async (currentPage, filterBy) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${summaryApi.getFailedEmail.url}?page=${currentPage}&filter=${filterBy}`,
        {
          method: summaryApi.getFailedEmail.method,
          credentials: "include",
        }
      );
      const data = await response.json();
      if (data.error) {
        toast.error(data.message);
      } else {
        setFailedEmails(data.data);
        setTotalPages(data.totalPages);
      }
    } catch (error) {
      console.error("Error fetching email logs:", error);
      toast.error("Failed to fetch email logs");
    }
    setLoading(false);
  };
  

  const retryWebhookError = async (id) => {
    try {
      const response = await fetch(summaryApi.retryFailedWebhook.url, {
        method: summaryApi.retryFailedWebhook.method,
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success('Webhook retried successfully');
        fetchWebhookErrors(page, filter);
      }
    } catch (error) {
      console.error('Error retrying webhook:', error);
      toast.error('Failed to retry webhook');
    }
  };

  const retryFailedEmail = async (id) => {
    try {
      const response = await fetch(summaryApi.retryOrderFailedEmail.url, {
        method: summaryApi.retryOrderFailedEmail.method,
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      if (data.error) {
        toast.error(data.message);
      } else {
        toast.success('Email retried successfully');
        fetchEmailErrors(page, filter);
      }
    } catch (error) {
      console.error('Error retrying email:', error);
      toast.error('Failed to retry email');
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Error Logs</h1>

      <div className="flex justify-between items-center mb-4">
        <div>
          <InputLabel>Log Type</InputLabel>
          <Select value={activeTab} onChange={(e) => setActiveTab(e.target.value)}>
            <MenuItem value="webhooks">Webhook Errors</MenuItem>
            <MenuItem value="emails">Email Errors</MenuItem>
          </Select>
        </div>

        <div>
          <InputLabel>Filter</InputLabel>
          <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="last24h">Last 24 Hours</MenuItem>
            <MenuItem value="last7d">Last 7 Days</MenuItem>
            <MenuItem value="last30d">Last 30 Days</MenuItem>
          </Select>
        </div>
      </div>

      <Card className='max-h-[100vh] overflow-y-auto"'>
        <CardContent className="overflow-auto">
          {loading ? (
            <p>Loading...</p>
          ) : activeTab === 'webhooks' ? (
            <div className="overflow-x-auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Error Message</TableCell>
                  <TableCell>Retry</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {failedWebhooks.map((webhook) => (
                  <TableRow key={webhook._id}>
                    <TableCell>{webhook.eventId}</TableCell>
                    <TableCell>{webhook.eventType}</TableCell>
                    <TableCell>{webhook.errorMessage}</TableCell>
                    <TableCell>
                      {!webhook.resolved && (
                        <Button variant="contained" onClick={() => retryWebhookError(webhook._id)}>
                          Retry
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </div>
          ) : (
            <div className="overflow-x-auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Recipient</TableCell>
                  <TableCell>Email Type</TableCell>
                  <TableCell>Error Message</TableCell>
                  <TableCell>Retry</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {failedEmails.map((email) => (
                  <TableRow key={email._id}>
                    <TableCell>{email.recipient}</TableCell>
                    <TableCell>{email.emailType}</TableCell>
                    <TableCell>{email.errorMessage}</TableCell>
                    <TableCell>
                      {!email.resolved && (
                        <Button variant="contained" onClick={() => retryFailedEmail(email._id)}>
                          Retry
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </div>
          )}
        </CardContent>
      </Card>

      <div className="mt-4">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(e, newPage) => setPage(newPage)}
        />
      </div>
    </div>
  );
};

export default ErrorLog;
