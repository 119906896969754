import React, { useState, useEffect } from 'react';

const LocalMarketModal = ({ setMarket, closeModal }) => {
  const [selectedMarket, setSelectedMarket] = useState('');

  useEffect(() => {
    const savedMarket = localStorage.getItem('localMarket');
    if (savedMarket) {
      setSelectedMarket(savedMarket); // Pre-select saved market if exists
    }
  }, []);

  const handleSelection = () => {
    if (selectedMarket) {
      setMarket(selectedMarket); // Update market in context
      localStorage.setItem('localMarket', selectedMarket); // Save to localStorage
      closeModal(); // Close modal after market is selected
    } else {
      alert('Please select a market');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Order From</h2>
        <select
          value={selectedMarket}
          onChange={(e) => setSelectedMarket(e.target.value)}
          className="border p-2 w-full mb-4"
        >
          <option value="">Select Market</option>
          <option value="India">India</option>
          <option value="Nigeria">Nigeria</option>
          <option value="UK">UK</option>
          <option value="USA">USA</option>
        </select>
        <button
          onClick={handleSelection}
          className="bg-blue-500 text-white py-2 px-4 rounded w-full"
        >
          Save Market
        </button>
      </div>
    </div>
  );
};

export default LocalMarketModal;



