import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import summaryApi from "../../common";

const PurchaseHistory = () => {
  const [purchases, setPurchases] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0); // Start at 0 for no data
  const limit = 20;

  const fetchPurchaseHistory = async () => {
    try {
      const response = await fetch(`${summaryApi.purchaseHistory.url}?page=${page}&limit=${limit}`, {
        method: "GET",
        credentials: "include", // Include user session
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      if (data.success) {
        setPurchases(data.data || []);
        setTotalPages(Math.ceil((data.total || 0) / limit)); // Avoid NaN by defaulting to 0
      } else {
        toast.error(data.message || "Failed to fetch purchase history.");
      }
    } catch (err) {
      console.error("Error fetching purchase history:", err);
      toast.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    fetchPurchaseHistory();
  }, [page]);
  
  return (
    <div className="container customMax430:max-w-full mx-auto py-8 w-full customMax-800:mt-10">
      <h1 className="text-2xl font-bold mb-6 pl-3">My Purchase History</h1>
      <div className="overflow-x-auto customMin-300:max-w-full">
        <table className="table-auto w-full border-collapse border border-gray-300 px-3">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2">Purchase ID</th>
              <th className="border px-4 py-2">Item Name</th>
              <th className="border px-4 py-2">Quantity</th>
              <th className="border px-4 py-2">Amount</th>
              <th className="border px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {purchases.length > 0 ? (
              purchases.map((purchase) => (
                <tr key={purchase._id}>
                  <td className="border px-4 py-2">{purchase._id}</td>
                  <td className="border px-4 py-2">
                    <ol className="list-decimal pl-5">
                      {purchase.items.map((item, idx) => (
                        <li key={idx}>{item.productId?.itemName || "Name not available"}</li>
                      ))}
                    </ol>
                  </td>
                  <td className="border px-4 py-2">{purchase.totalQty}</td>
                  <td className="border px-4 py-2">${ (purchase.totalConvertedAndShipment || 0).toFixed(2) }</td>
                  <td className="border px-4 py-2">{purchase.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="border px-4 py-2 text-center">
                  No purchases found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4 mx-5">
        <button
          onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
          disabled={page === 0 || totalPages <= 1} // Disable if totalPages <= 1
          className={`py-2 px-4 rounded ${
            page === 0 || totalPages <= 1 ? "bg-gray-200" : "bg-blue-500 text-white"
          }`}
        >
          Previous
        </button>
        <span>
          Page {page + 1 || 0} of {totalPages || 0} {/* Display 0 if no data */}
        </span>
        <button
          onClick={() => setPage((prev) => Math.min(prev + 1, totalPages - 1))}
          disabled={page === totalPages - 1 || totalPages <= 1} // Disable if totalPages <= 1
          className={`py-2 px-4 rounded ${
            page === totalPages - 1 || totalPages <= 1
              ? "bg-gray-200"
              : "bg-blue-500 text-white"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PurchaseHistory;

