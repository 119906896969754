import { useState, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import loginIcon from '../assest/signin.gif'
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaEyeSlash } from "react-icons/fa";
import imageToBase64  from "../helpers/imageToBase64"
import summaryApi from "../common";
import { toast } from "react-toastify";
import { GoogleLogin } from '@react-oauth/google';
import Context from '../context';
import UpdateGoogleUser from "../components/UpdateGoogleUser";
import PrivacyPolicyDialog from '../components/PrivacyPolicyDialog';
import LoadingIcons from 'react-loading-icons'

function SignUp() {
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [data, setData] = useState({
        name : "",
        email : "",
        password : "",
        confirmPassword : "",
        profilePic : "",
        role : "",
        mobileNumber : "",
        userRole: "", // For Google Sign-In role
        userNumber:"" // seller mobile via google login
    })
    
    const {fetchUserdetails, fetchUserAddToCartCount} = useContext(Context)
    const navigate = useNavigate()
    const [showUpdateGoogleUser, setShowUpdateGoogleUser] = useState(false);
    const [newUserId, setNewUserId] = useState(null); // Track new Google user ID
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [loading, setLoading] = useState(false)
    
    
    const handleChange = (e) => {
        const {name, value} = e.target

        setData((preve)=>{
            return{
                ...preve,
                [name] : value
            }
        })
    }

    const handleUploadPic = async(e) =>{
        const file = e.target.files[0]

        const imagePic = await imageToBase64(file)

        setData((preve)=>{
            return{
                ...preve,
                profilePic : imagePic
            }
        })

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (data.password !== data.confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
    
        try {
            const dataResponse = await fetch(summaryApi.signUp.url, {
                method: summaryApi.signUp.method,
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(data),
            });
    
            if (!dataResponse.ok) {
                if (dataResponse.status >= 500) {
                    navigate("/error-fallback");
                } else {
                    const errorData = await dataResponse.json();
                    toast.error(errorData.message || "Error during registration");
                    setLoading(false)
                }
                return;
            }
    
            const dataApi = await dataResponse.json();
    
            if (dataApi.success) {
                toast.success(dataApi.message);
                
                // Save the token in local storage
                if (dataApi.token) {
                    localStorage.setItem("verificationToken", dataApi.token);
                } else {
                    console.error("Token is missing in the response");
                }
                
                navigate("/verify-otp");
            } else {
                toast.error(dataApi.message);
                setLoading(false)
            }
    
        } catch (error) {
            console.error("Network or server error:", error);
            navigate("/error-fallback");
            setLoading(false)
        }
    };

     // Google login handler
    const handleLoginSuccess = (response) => {
        const googleToken = response.credential;
        loginWithGoogle(googleToken);
    };

    const loginWithGoogle = async (googleToken) => {
        try {
            const response = await fetch(summaryApi.googleLogin.url, {
                method: summaryApi.googleLogin.method,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: googleToken }),
            });
    
           
            const dataResponse = await response.json(); // Parse JSON once
            
            // Check for 400 status to display error message
            if (dataResponse.status === 400) {
                toast.error(data.message || "An error occurred during Google Sign-In.");
                return; // Stop execution here if there's an error
            }
            
            // Handle success case if the response is OK
            if (response.ok && dataResponse.success) {
                setNewUserId(dataResponse.user?._id);
                if (!dataResponse.user?.role) {
                    setShowUpdateGoogleUser(true); // Show role selection for new users    
                } else {
                    //Existing user with a role
                    await fetchUserdetails();
                    await fetchUserAddToCartCount();
                    if (dataResponse.user.role === "Seller") {
                        navigate("/dashboard/all-products");
                    } else  if (dataResponse.user.role === "Freelancer") {
                        navigate("/freelancer");
                     } else {
                        navigate("/");
                    }
                }
            } else {
                // Handle unexpected cases if status isn't 400 but also not OK
                console.log("Unexpected response status or success flag:", response.status);
            }
    
        } catch (error) {
            console.error("Google Sign-In error:", error);
        }
    };
    
    const handleOpenPolicy = () => setShowPrivacyPolicy(true);
    const handleClosePolicy = () => setShowPrivacyPolicy(false);
    

  return (
    <section id='signup'>
    <div className='mx-auto mt-5 container customMin-300:max-w-full p-4'>
        <div 
        className='p-5 w-full bg-white max-w-sm mx-auto customMax-800:mt-5'
        style={{
            opacity: showUpdateGoogleUser ? 0.1 : 1, 
            transition: 'opacity 0.3s ease',
            pointerEvents: showUpdateGoogleUser ? 'none' : 'auto', // Disable interaction with the form when role selection is shown
          }}>
            <div className='w-20 mx-auto h-20 relative overflow-hidden rounded-full'>
                <div>
                    <img src={data.profilePic || loginIcon} alt='loginIcon'/>
                </div>
                <form >
                    <label>
                        <input 
                        type="file" 
                        className="hidden" 
                        onChange={handleUploadPic}
                        />

                        <div className="text-xs bg-opacity-80 bg-slate-200 pb-4 pt2 cursor-pointer text-center absolute bottom-0 w-full">
                        Upload Photo
                        </div>
                    </label>
                    
                </form>
            </div>

            <form className='pt-6 flex flex-col gap-2 ' onSubmit={handleSubmit}>

                <div className='grid'>
                    <label>Name :</label>
                    <div className='bg-slate-100 p-2 w'>
                        <input 
                        type='text' 
                        placeholder='Enter name' 
                        className='w-full h-full bg-transparent outline-none'
                        name='name'
                        value={data.name}
                        onChange={handleChange}
                        required
                        />
                        
                    </div>
                </div>
                <div className='grid'>
                    <label>Email :</label>
                    <div className='bg-slate-100 p-2 w'>
                        <input 
                        type='email' 
                        placeholder='Enter email' 
                        className='w-full h-full bg-transparent outline-none'
                        name='email'
                        value={data.email}
                        onChange={handleChange}
                        required
                        />
                        
                    </div>
                </div>

                <div className='grid'>
                    <label> Password :</label>
                    <li className="text-sm">Minimum 6 character</li> 
                    <li className="text-sm">Must have CAPITAL letter</li> 
                    <li className="text-sm">Must have small letter</li> 
                    <li className="text-sm">Must have number</li> 
                    <div className='bg-slate-100 p-2 flex'>
                        <input 
                        type={showPassword ? "text" : "password" } 
                        placeholder='Enter password' 
                        name='password'
                        value={data.password}
                        onChange={handleChange}
                        className='w-full h-full bg-transparent outline-none'/>
                        <div className='cursor-pointer text-xl'onClick={()=> setShowPassword((preve)=>!preve)}>
                            <span>

                                {
                                    showPassword ? (
                                        <FaEyeSlash/>
                                    )
                                    :
                                    (
                                        <MdOutlineRemoveRedEye/>
                                    )
                                    
                                }

                            </span>
                        </div>
                    </div> 
                    
                </div>  

                <div className='grid'>
                    <label>Confirm Password :</label>
                    <div className='bg-slate-100 p-2 flex'>
                        <input 
                        type={showConfirmPassword ? "text" : "password" } 
                        placeholder='Confirm password' 
                        name='confirmPassword'
                        value={data.confirmPassword}
                        onChange={handleChange}
                        className='w-full h-full bg-transparent outline-none'
                        required/>
                        <div className='cursor-pointer text-xl'onClick={()=> setShowConfirmPassword((preve)=>!preve)}>
                            <span>

                                {
                                    showConfirmPassword ? (
                                        <FaEyeSlash/>
                                    )
                                    :
                                    (
                                        <MdOutlineRemoveRedEye/>
                                    )
                                    
                                }

                            </span>
                        </div>
                    </div>  
                </div> 

                        {/* Account Type Select */}
                <div className="grid">
                <label>Account Type:</label>
                <select
                    type="text"
                    name="role"
                    value={data.role} // Default value set to "Customer"
                    onChange={handleChange}
                    className="font-medium shadow-md rounded border border-gray-300 p-2 bg-slate-100 "
                    required
                >
                    <option value="">Pls Choose</option>
                    <option value="Customer">Customer</option>
                    <option value="Seller">Product Seller</option>
                    {/* <option value="Freelancer">Freelancer</option> */}
                </select>
                </div>
                {(data.role === 'Seller' || data.role === 'freelancer') && (
                <div className="grid">
                    <label>WhatsApp no.</label>
                    <div className='bg-slate-100 p-2 flex'>
                        <input 
                        type= "text"
                        placeholder='enter whatsapp number' 
                        name='mobileNumber'
                        value={data.mobileNumber}
                        onChange={handleChange}
                        className='w-full h-full bg-transparent outline-none'
                        required/>
                    </div>
                </div>)
                }
                
                <div className='bg-orange-500 hover:bg-orange-700  text-white cursor-pointer mt-7 px-6 py-2 mx-auto rounded'>
                    <button type="submit" disabled={loading}>
                        {
                            loading ? (<LoadingIcons.Bars className="text-orange-600 h-[1.33em]"/>) : ('Signup')
                        }
                    </button>
                </div>

        
                 {/* Google Sign-in Button */}
                 <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        onError={() => console.log('Login Failed')}
                />
                

            </form>
            <p className='my-4 '> 
             Do you have account ?  <Link to={"/login"} className="text-red-500 hover:underline hover:text-orange-700 cursor-pointer" >Login</Link>
            </p>

            <button onClick={handleOpenPolicy} className='hover:underline hover:text-orange-600'>Privacy Policy</button>

              {/* Privacy Policy Dialog */}
              <PrivacyPolicyDialog open={showPrivacyPolicy} onClose={handleClosePolicy} />
        </div>

    </div>

    {/* Role Selection Dialog */}

    {/* Role Selection for Google Sign-In */}
            {showUpdateGoogleUser && (
                <UpdateGoogleUser
                    userId={newUserId}
                    updateSelected={(role, mobileNumber) => {
                        // Update state for role and mobile number
                        setData((prev) => ({
                            ...prev,
                            userRole: role,
                            userNumber: role === "Seller" ? mobileNumber : "",
                        }));
                    }}
                    
                />
            )}


    {/* {showUpdateGoogleUser && (
          <ChooseUserRole userId={newUserId} onRoleSelected={(role) => handleRoleSelected(role)} />

        )} */}

</section>
  )
}

export default SignUp