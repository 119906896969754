import { useState, useEffect } from "react";
import summaryApi from "../../common";
import { toast } from "react-toastify";
import { CiEdit } from "react-icons/ci";
import { MdDeleteForever } from "react-icons/md";
//import { duration } from "moment/moment";


const CreateShipmentRate = ({ initialData = {}}) => {
    const [data, setData] = useState({ 
        costPerKg: initialData.costPerKg || "" , 
        localMarket: initialData.localMarket || "", 
        mode: initialData.mode || "", 
        destination: initialData.destination || "",
        shipmentDuration: initialData.shipmentDuration || "",
        height: initialData.height || "", 
        length: initialData.length || "", 
        width: initialData.width || "" 
    });
    const [shipmentRates, setShipmentRates] = useState([]);
    const [loading, setLoading] = useState(false);
  

    useEffect(() => {
        fetchShipmentRate();
    }, []);

        // Sync initialData when it changes
 
        

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const fetchShipmentRate = async () => {
        try {
            const response = await fetch(summaryApi.getShippingRate.url, {
                method: summaryApi.getShippingRate.method,
            });
            const dataResponse = await response.json();
            setShipmentRates(dataResponse || []);
        } catch (error) {
            toast.error("Failed to fetch shipment rates.");
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
    

        if (!data.costPerKg || !data.localMarket || !data.mode || !data.destination || !data.shipmentDuration ) {
            toast.error("Please complete all required fields");
            return;
        }

        setLoading(true);

        const dimension = `${data.length} x ${data.width} x ${data.height}`;

        try {
            const response = await fetch(summaryApi.createShipmentRate.url, {
                method: summaryApi.createShipmentRate.method,
                credentials : "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({...data, dimension}),
            });
            const dataResponse = await response.json();

            if (dataResponse.error) {
                toast.error(dataResponse.message || "Something went wrong");
            } else {
                toast.success(dataResponse.message);
                fetchShipmentRate();
                setData({
                    costPerKg: "",
                    localMarket: "",
                    mode: "",
                    destination: "",
                    shipmentDuration: "",
                    height: "",
                    length: "",
                    width: ""
                });
                
            }
        } catch (error) {
            toast.error("Network error, please try again");
        } finally {
            setLoading(false);
        }
    };
    
    const deleteShippingRate = async(id) =>{
            try {
                const response = await fetch(`${summaryApi.deleteShippingRate.url}/${id}`, {
                    method: summaryApi.deleteShippingRate.method,
                    headers: { "Content-Type": "application/json" },
                    credentials: 'include'
                 
                });
                const result = await response.json();
                if (result.success) {
                  toast.success('Shipment deleted successfully');
                  setShipmentRates(shipmentRates.filter((rate) => rate._id !== id));
                } else {
                  toast.error(result.message);
                }
            } catch (error) {
                toast.error("Network error, please try again");
            } finally {
                setLoading(false);
            }
    }


    return (
        <div className="flex flex-col items-center justify-center pt-10 border max-w-lg mx-auto bg-white shadow-lg rounded-lg p-6"> 
            <h1 className="text-xl font-bold mb-4">Shipment Rate Form</h1>
            <form onSubmit={handleSubmit} className="w-full space-y-4">
                <input
                    type="number"
                    name="costPerKg"
                    value={data.costPerKg}
                    placeholder="Cost per kg"
                    className="p-3 w-full border rounded-md focus:ring-2 focus:ring-blue-500"
                    onChange={handleChange}
                    required
                />
                
                <select
                    name="localMarket"
                    value={data.localMarket}
                    onChange={handleChange}
                    className="p-3 w-full border rounded-md focus:ring-2 focus:ring-blue-500"
                    required
                >
                    <option value="">Choose Market</option>
                    <option value="India">India</option>
                    <option value="Nigeria">Nigeria</option>
                </select>

                <select
                    name="mode"
                    value={data.mode}
                    onChange={handleChange}
                    className="p-3 w-full border rounded-md focus:ring-2 focus:ring-blue-500"
                    required
                >
                    <option value="">Shipment Mode</option>
                    <option value="Air">Air</option>
                    <option value="Water">Water</option>
                </select>

                <select
                    name="destination"
                    value={data.destination}
                    onChange={handleChange}
                    className="p-3 w-full border rounded-md focus:ring-2 focus:ring-blue-500"
                    required
                >
                    <option value="">Choose Destination</option>
                    <option value="USA">US</option>
                    <option value="UK">GB</option>
                </select>

                <input
                    type="text"
                    name="shipmentDuration"
                    value={data.shipmentDuration}
                    placeholder="Delivery duration"
                    className="p-3 w-full border rounded-md focus:ring-2 focus:ring-blue-500"
                    onChange={handleChange}
                    required
                />

                {/* Dimensions Section */}
                <div className="mb-4">
                    <label className="block font-bold mb-1">Dimensions (Length x Width x Height)</label>
                    <div className="grid grid-cols-3 gap-2">
                    <input
                        type="text"
                        name="length"
                        value={data.length}
                        onChange={handleChange}
                        className="border rounded p-2"
                        placeholder="Length"
                        
                    />
                    <input
                        type="text"
                        name="width"
                        value={data.width}
                        onChange={handleChange}
                        className="border rounded p-2"
                        placeholder="Width"
                        
                    />
                    <input
                        type="text"
                        name="height"
                        value={data.height}
                        onChange={handleChange}
                        className="border rounded p-2"
                        placeholder="Height"
                        
                    />
                    </div>
                </div>
                            
                <button
                    type="submit"
                    className="bg-blue-600 text-white p-3 rounded-md w-full hover:bg-blue-700 transition"
                    disabled={loading}
                >
                    {loading ? "Submitting..." : "Submit"}
                </button>
            </form>

            {/* Display Shipment Rates */}
            <div className="mt-6 w-full bg-gray-50 p-4 rounded-lg shadow-md">
                <h2 className="text-lg font-semibold mb-4 text-gray-700">Shipment Rates</h2>

                {shipmentRates.length > 0 ? (
                    <ul className="space-y-3">
                        {shipmentRates.map((rate) => (
                        <li
                            key={rate._id}
                            className="p-4 border rounded-lg bg-white flex items-center justify-between shadow-sm"
                        >
                    {/* Left Section: Rate Details */}
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500 font-bold">
                            Mode: {rate.mode}
                        </span>
                        <span className="font-semibold text-gray-700">
                            {rate.localMarket}
                        </span>
                        <span className="text-sm text-gray-600 font-semibold">
                            {rate.costPerKg} per Kg
                        </span>

                        <span className="text-sm text-gray-600 font-semibold">
                           Destination: {rate.destination}
                        </span>

                        <span className="text-sm text-gray-600 font-semibold">
                           Duration: {rate.shipmentDuration} 
                        </span>
                        
                    </div>

                    {/* Right Section: Delete Button */}
                    <MdDeleteForever
                        className="text-red-600 text-2xl cursor-pointer hover:text-red-800 transition"
                        onClick={() => deleteShippingRate(rate._id)}
                    />
                </li>
            ))}
        </ul>
    ) : (
        <p className="text-gray-500 text-center py-4">
            No shipment rates available.
        </p>
    )}
            </div>

        </div>
    );
};

export default CreateShipmentRate;
