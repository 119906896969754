import React, { useState } from 'react';
import displayPrice from '../helpers/FormatPrice';
import summaryApi from '../common';
import ROLE from '../common/role';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ReviewProductCard = ({ data, updateProductInList }) => {
    const [comment, setComment] = useState("");
    const [approveLoading, setApproveLoading] = useState(false)
    const [rejectLoading, setRejectLoading] = useState(false)
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
    const user = useSelector((state) => state?.user?.user);

    // Handle image slider navigation
    const handlePreviousImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? data.productImage.length - 1 : prevIndex - 1));
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === data.productImage.length - 1 ? 0 : prevIndex + 1));
    };

    // Handle admin approval
    const handleApprove = async () => {
        await handleAction("approved");
    };

    // Handle admin rejection
    const handleReject = async () => {
        if (comment.trim() === "") {
            setShowCommentBox(true); // Show the comment box if no comment is provided
            return;
        }
        await handleAction("rejected");
    };

    // Function to handle approve/reject actions
    const handleAction = async (status) => {
        try {
            setRejectLoading(true)
            setApproveLoading(true)
            const response = await fetch(summaryApi.productApproval.url, {
                method: summaryApi.productApproval.method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _id: data?._id,
                    productStatus: status,
                    comment: status === "rejected" ? comment : "", // Include comment only for rejection
                }),
            });

            const result = await response.json();
           
            if (result.success) {
                updateProductInList(data._id); // Pass product ID to remove from the pending list
                toast.success(result?.message);
                
            }
        } catch (error) {
            console.error(`Error updating product status to ${status}:`, error);
        } finally {
            setComment(""); // Reset comment after action
            setShowCommentBox(false); // Hide comment box after action
            setRejectLoading(false)
            setApproveLoading(false)
        }
    };

    // return (
    //     <div className="bg-white p-4 rounded border border-white w-full max-w-md sm:max-w-full sm:px-4 md:px-0 mx-auto mb-4">
    //         <div className="w-full">
    //             {/* Product Image Slider */}
    //             <div className="w-full h-48 flex justify-center items-center relative">
    //                 {data?.productImage.length > 1 && (
    //                     <>
    //                         <button
    //                             onClick={handlePreviousImage}
    //                             className="absolute left-2 bg-gray-700 text-white px-2 rounded-full"
    //                         >
    //                             &lt;
    //                         </button>
    //                         <button
    //                             onClick={handleNextImage}
    //                             className="absolute right-2 bg-gray-700 text-white px-2 rounded-full"
    //                         >
    //                             &gt;
    //                         </button>
    //                     </>
    //                 )}
    //                 <img
    //                     src={data?.productImage[currentImageIndex]}
    //                     alt={data?.itemName}
    //                     className="mx-auto object-contain h-full w-full rounded"
    //                 />
    //             </div>

    //             <div className='px-2'>
    //                     {/* Product Details */}
    //                 <h1 className="mt-4 text-lg font-semibold text-ellipsis line-clamp-1">{data.itemName}</h1>
                    
    //                 {/* Toggle Description */}
    //                 <p className={`text-sm text-gray-500 ${isDescriptionExpanded ? '' : 'line-clamp-1'}`}>
    //                     {data.itemDescription}
    //                 </p>
    //                 {data.sizes.map((siz, index)=>{
    //                     return (
    //                     <p>{siz}</p>
                        
    //                 )})}
    //                 <p>{data?.stockLevel}</p>
    //                 <button
    //                     onClick={() => setDescriptionExpanded(!isDescriptionExpanded)}
    //                     className="text-blue-500 text-sm mt-1"
    //                 >
    //                     {isDescriptionExpanded ? "Show Less" : "Read More"}
    //                 </button>

    //                 {/* Product Price and Approval Actions */}
    //                 <div className="mt-2 flex-row justify-between items-center">
    //                     <div className="font-semibold text-xl">
    //                         {displayPrice(data?.price, data?.currency)}
    //                     </div>

    //                     {user?.role === ROLE.ADMIN && (
    //                         <div className="flex gap-2 mt-2">
    //                             <button
    //                                 className="bg-green-500 text-white py-1 px-2 rounded"
    //                                 onClick={handleApprove}
    //                                 disabled={approveLoading}
    //                             >
    //                               {approveLoading ? 'please wait' : 'Approve'}
    //                             </button>
    //                             <button
    //                                 className="bg-red-500 text-white py-1 px-2 rounded"
    //                                 onClick={() => setShowCommentBox(!showCommentBox)}
    //                             >
    //                                 Reject
    //                             </button>
    //                         </div>
    //                     )}
    //                 </div>

    //                 {/* Comment Box for Reject Action */}
    //                 {showCommentBox && (
    //                     <div className="mt-2">
    //                         <textarea
    //                             placeholder="Add a comment (required for rejection)"
    //                             value={comment}
    //                             onChange={(e) => setComment(e.target.value)}
    //                             className="w-full p-2 border rounded mb-2"
    //                         />
    //                         <button
    //                             className="bg-red-600 text-white py-1 px-4 rounded"
    //                             onClick={handleReject}
    //                             disabled={rejectLoading}
    //                         >
    //                             { rejectLoading ? 'loading...' : 'Confirm Reject'}
    //                         </button>
    //                     </div>
    //                 )}
    //             </div>

               
    //         </div>
    //     </div>
    // );
    return (
        <div className="bg-white p-4 rounded border border-white w-full max-w-md sm:max-w-full sm:px-4 md:px-0 mx-auto mb-4">
            <div className="w-full">
                {/* Product Image Slider */}
                <div className="w-full h-48 flex justify-center items-center relative">
                    {Array.isArray(data?.productImage) && data.productImage.length > 1 && (
                        <>
                            <button
                                onClick={handlePreviousImage}
                                className="absolute left-2 bg-gray-700 text-white px-2 rounded-full"
                            >
                                &lt;
                            </button>
                            <button
                                onClick={handleNextImage}
                                className="absolute right-2 bg-gray-700 text-white px-2 rounded-full"
                            >
                                &gt;
                            </button>
                        </>
                    )}
                    {data?.productImage?.[currentImageIndex] && (
                        <img
                            src={data?.productImage[currentImageIndex]}
                            alt={data?.itemName}
                            className="mx-auto object-contain h-full w-full rounded"
                        />
                    )}
                </div>

                <div className="px-2">
                    {/* Product Details */}
                    <h1 className="mt-4 text-lg font-semibold text-ellipsis line-clamp-1">
                        {data?.itemName}
                    </h1>

                    {/* Toggle Description */}
                    <p className={`text-sm text-gray-500 ${isDescriptionExpanded ? '' : 'line-clamp-1'}`}>
                        {data?.itemDescription}
                    </p>

                    {/* Display Sizes, Colors, and Stock Levels */}
                    {data?.sizes?.map((siz, index) => (
                        <div key={siz._id || index} className="mt-3 p-3 border border-gray-300 rounded-lg shadow-sm">
                            <p className="font-medium text-gray-700">
                                {siz.sizeType}: <span className="font-semibold text-gray-900">{siz.size}</span> 
                            </p>
                            
                            <div className="flex flex-wrap gap-2 mt-2">
                                {siz.colors?.map((color, colorIndex) => {
                                    const bgColor = color.color.toLowerCase(); // Converts color text to lowercase for class usage
                                    return (
                                        <span 
                                            key={colorIndex} 
                                            className="text-white text-sm px-3 py-1 rounded-lg shadow-md"
                                            style={{ backgroundColor: bgColor }}
                                        >
                                            {color.color} - {color.stockLevel}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    ))}

                    <div className='flex justify-between'>
                        <p>Total stock: {data?.stockLevel}</p>
                        <p className='text-green-600'>Product Weight: {data?.productWeight}</p>
                    </div>
                    

                    <button
                        onClick={() => setDescriptionExpanded(!isDescriptionExpanded)}
                        className="text-blue-500 text-sm mt-1"
                    >
                        {isDescriptionExpanded ? "Show Less" : "Read More"}
                    </button>

                    {/* Product Price and Approval Actions */}
                    <div className="mt-2 flex justify-between items-center">
                    <div className="space-y-1">
                        <p className="font-semibold text-xl text-gray-800">
                         {displayPrice(data?.price, data?.currency)}
                        </p>
                        <p className="text-lg text-blue-600 font-medium">
                         {displayPrice(data?.totalPriceByAir, data?.currency)}
                        </p>
                    </div>

                        {user?.role === ROLE.ADMIN && (
                            <div className="flex gap-2 mt-2">
                                <button
                                    className="bg-green-500 text-white py-1 px-2 rounded"
                                    onClick={handleApprove}
                                    disabled={approveLoading}
                                >
                                    {approveLoading ? 'Please wait' : 'Approve'}
                                </button>
                                <button
                                    className="bg-red-500 text-white py-1 px-2 rounded"
                                    onClick={() => setShowCommentBox(!showCommentBox)}
                                >
                                    Reject
                                </button>
                            </div>
                        )}
                    </div>
                </div>

    
                 <div className="px-2">
                    {/* Product Details */}
                    {/* <h1 className="mt-4 text-lg font-semibold text-ellipsis line-clamp-1">
                        {data?.itemName}
                    </h1>
    
                    {/* Toggle Description */}
                    {/* <p className={`text-sm text-gray-500 ${isDescriptionExpanded ? '' : 'line-clamp-1'}`}>
                        {data?.itemDescription}
                    </p> */}
                    
                    {/* {data?.sizes?.map((siz, index) => (
                    <p key={siz._id || index}>
                        {siz.sizeType}: {siz.size} (Stock: {siz.stockLevel})
                    </p>
                    ))} */}

    
                    {/* <p>Total stock: {data?.stockLevel}</p>
    
                    <button
                        onClick={() => setDescriptionExpanded(!isDescriptionExpanded)}
                        className="text-blue-500 text-sm mt-1"
                    >
                        {isDescriptionExpanded ? "Show Less" : "Read More"}
                    </button> */}
    
                    {/* Product Price and Approval Actions */}
                    {/* <div className="mt-2 flex justify-between items-center">
                        <div className="font-semibold text-xl">
                            {displayPrice(data?.price, data?.currency)}
                        </div>
    
                        {user?.role === ROLE.ADMIN && (
                            <div className="flex gap-2 mt-2">
                                <button
                                    className="bg-green-500 text-white py-1 px-2 rounded"
                                    onClick={handleApprove}
                                    disabled={approveLoading}
                                >
                                    {approveLoading ? 'Please wait' : 'Approve'}
                                </button>
                                <button
                                    className="bg-red-500 text-white py-1 px-2 rounded"
                                    onClick={() => setShowCommentBox(!showCommentBox)}
                                >
                                    Reject
                                </button>
                            </div>
                        )}
                    </div>   */}
    
                    {/* Comment Box for Reject Action */}
                    {showCommentBox && (
                        <div className="mt-2">
                            <textarea
                                placeholder="Add a comment (required for rejection)"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                className="w-full p-2 border rounded mb-2"
                            />
                            <button
                                className="bg-red-600 text-white py-1 px-4 rounded"
                                onClick={handleReject}
                                disabled={rejectLoading}
                            >
                                {rejectLoading ? 'Loading...' : 'Confirm Reject'}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
    
};

export default ReviewProductCard;



// import React, { useState } from 'react';
// import displayPrice from '../helpers/FormatPrice';
// import summaryApi from '../common';
// import ROLE from '../common/role';
// import { useSelector } from 'react-redux'
// import { toast } from 'react-toastify';

// const ReviewProductCard = ({ data, updateProductInList }) => {
//     const [comment, setComment] = useState("");
//     const [showCommentBox, setShowCommentBox] = useState(false);
//     const user = useSelector(state => state?.user?.user)
  
//     // Handle admin approval
//     const handleApprove = async () => {
//         await handleAction("approved");
//     };

//     // Handle admin rejection
//     const handleReject = async () => {
//         if (comment.trim() === "") {
//             setShowCommentBox(true); // Show the comment box if no comment is provided
//             return;
//         }
//         await handleAction("rejected");
//     };

//     // Function to handle approve/reject actions
//     const handleAction = async (status) => {
//         try {
//             const response = await fetch(summaryApi.productApproval.url, {
//                 method: summaryApi.productApproval.method,
//                 credentials: 'include',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     _id: data?._id,
//                     productStatus: status,
//                     comment: status === "rejected" ? comment : "", // Include comment only for rejection
//                 }),
//             });
//             console.log('response:', response)

//             const result = await response.json();
//             if (result.success) {
//                 updateProductInList(result.data); // Update product in the parent component
//                 toast.success(result?.message);
//             }
//         } catch (error) {
//             console.error(`Error updating product status to ${status}:`, error);
//         } finally {
//             setComment(""); // Reset comment after action
//             setShowCommentBox(false); // Hide comment box after action
//         }
//     };

//     return (
//         <div className='bg-white p-4 rounded border border-white w-full max-w-md sm:max-w-full sm:px-4 md:px-0 mx-auto mb-4'>
//             <div className='w-full'>
//                 {/* Product Image */}
//                 <div className='w-full h-48 flex justify-center items-center'>
//                     <img
//                         src={data?.productImage[0]}
//                         alt={data?.itemName}
//                         className='mx-auto object-contain h-full w-full rounded'
//                     />
//                 </div>

//                 {/* Product Details */}
//                 <h1 className='mt-4 text-lg font-semibold text-ellipsis line-clamp-1'>{data.itemName}</h1>
//                 <p className='text-sm text-gray-500 text-ellipsis line-clamp-1'>{data.itemDescription}</p>

//                 {/* Product Price and Approval Actions */}
//                 <div className='mt-2 flex-row justify-between items-center'>
//                     <div className='font-semibold text-xl'>
//                         {displayPrice(data?.price, data?.currency)}
//                     </div>

//                     {user?.role === ROLE.ADMIN && (
//                         <div className='flex gap-2 mt-2'>
//                             <button
//                                 className='bg-green-500 text-white py-1 px-2 rounded'
//                                 onClick={handleApprove}
//                             >
//                                 Approve
//                             </button>
//                             <button
//                                 className='bg-red-500 text-white py-1 px-2 rounded'
//                                 onClick={() => setShowCommentBox(!showCommentBox)}
//                             >
//                                 Reject
//                             </button>
//                         </div>
//                     )}
//                 </div>

//                 {/* Comment Box for Reject Action */}
//                 {showCommentBox && (
//                     <div className='mt-2'>
//                         <textarea
//                             placeholder='Add a comment (required for rejection)'
//                             value={comment}
//                             onChange={(e) => setComment(e.target.value)}
//                             className='w-full p-2 border rounded mb-2'
//                         />
//                         <button
//                             className='bg-red-600 text-white py-1 px-4 rounded'
//                             onClick={handleReject}
//                         >
//                             Confirm Reject
//                         </button>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ReviewProductCard;
