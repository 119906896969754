import React, { useState, useEffect, useContext } from 'react';
import Context from '../../context';
import summaryApi from '../../common';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ServiceCategory from '../../helpers/ServiceCategory';
import subCategories from '../../helpers/ServiceSubCategory';
import { MdDelete } from 'react-icons/md';
import DisplayImage from '../../components/DisplayImage';
import { useSelector } from 'react-redux';
import { FaPen } from "react-icons/fa";

const FreelancerUpdateAccount = () => {
  const loadingList = new Array(13).fill(null)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetchUserdetails, fetchFreelancerdetails } = useContext(Context);
  const userDetails = useSelector((state) => state?.user?.user);
  const freelancerDetails = useSelector((state) => state.freelancer.freelancer);


  //console.log("Redux freelancer details:", freelancerDetails); 
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategories, setSelectedSubcategories] = useState();
  const [fullScreeImage, setFullScreenImage] = useState("")
  const [openFullScreenImage, setOpenFullScreenImage] = useState(false)
  const navigate = useNavigate();
  // Initialize state only when data is available
  const [formData, setFormData] = useState({});
  const [freelancerFormData, setFreelancerFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isWallPictureLoading, setIsWallPictureLoading] = useState(false); // Separate state for wall picture
  const [isProfilePicLoading, setIsProfilePicLoading] = useState(false); // Separate state for profile picture


  useEffect(() => {
    if (userDetails) {
      setFormData({
        name: userDetails.name || "",
        profilePic: userDetails.profilePic || "",
        mobileNumber: userDetails.mobileNumber || "",
      });
    }
  }, [userDetails]); // Runs only when userDetails updates

  useEffect(() => {
    if (freelancerDetails) {
      setFreelancerFormData({
        wallPicture: freelancerDetails.wallPicture || "",
        workTitle: freelancerDetails.workTitle || "",
        categories: freelancerDetails.categories || [],
        subcategories: freelancerDetails.subcategories || [],
        skills: freelancerDetails.skills || "",
        experiences: freelancerDetails.experiences || [],
        pastProjects: freelancerDetails.pastProjects || [],
        references: freelancerDetails.references || "",
        education: freelancerDetails.education || "",
        qualifications: freelancerDetails.qualifications || "",
        tools: freelancerDetails.tools || "",
        currency: freelancerDetails.currency || "",
        hourlyRate: freelancerDetails.hourlyRate || "",
        weeklyRate: freelancerDetails.weeklyRate || "",
        monthlyRate: freelancerDetails.monthlyRate || "",
      });

      setSelectedCategory(freelancerDetails.categories?.[0] || ""); // Set first category as default
      setSelectedSubcategories(freelancerDetails.subCategories || []);
    }
  }, [freelancerDetails]); // Runs only when freelancerDetails updates

  useEffect(() => {
    fetchUserdetails();  
    fetchFreelancerdetails();
  }, []); 

  useEffect(() => {
    // When userDetails & freelancerDetails update, stop loading
    if (userDetails && freelancerDetails) {
      setIsLoading(false);
    }
  }, [userDetails, freelancerDetails]);

  
  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFreelanChange = (e) => {
    const { name, value } = e.target;
    setFreelancerFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleUpload = async (e, type) => {
    const name = e.target.name;
    const files = e.target.files;
    
    if (!files || files.length === 0) {
      console.error("No files selected");
      return;
    }
  
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("files", file);
    });
  
    formData.append("type", name);
  
    if (type === "wallPicture") setIsWallPictureLoading(true);
    if (type === "profilePic") setIsProfilePicLoading(true);
  
    try {
      const response = await fetch(summaryApi.imageUpload.url, {
        method: summaryApi.imageUpload.method,
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Failed to upload ${type}`);
      }
  
      const result = await response.json();
  
      if (!result.imageUrls) {
        throw new Error("Invalid API response: Missing imageUrls");
      }
  
      setFreelancerFormData((prev) => {
        const updatedState = { ...prev, [type]: result.imageUrls[0] };
        return updatedState;
      });
      
      if (type === "profilePic") {
        setFormData((prev) => {
          const updatedState = { ...prev, profilePic: result.imageUrls[0] };
          return updatedState;
        });
      }
      
  
      await fetchFreelancerdetails();
      await fetchUserdetails();
      setIsLoading(false);
    } catch (error) {
      console.error(`Error uploading ${type}:`, error.message);
      setIsLoading(false);
    } finally {
      if (type === "wallPicture") setIsWallPictureLoading(false);
      if (type === "profilePic") setIsProfilePicLoading(false);
    }
  };
  
  // const handleUpload = async (e, type) => {
  //   const files = e.target.files;
  //   if (!files.length) return;
  
  //   const isWallPicture = type === "wallPicture";
  //   const isProfilePic = type === "profilePic";
    
  //   if (isWallPicture) {
  //     setIsWallPictureLoading(true); // Set loading state for wall picture
  //   } else if (isProfilePic) {
  //     setIsProfilePicLoading(true); // Set loading state for profile picture
  //   }
  
  //   const formData = new FormData();
  //   Array.from(files).forEach((file) => {
  //     formData.append("files", file, file.name);
  //   });
  //   formData.append("type", type);
  
  //   try {
  //     const response = await fetch(summaryApi.imageUpload.url, {
  //       method: summaryApi.imageUpload.method,
  //       body: formData,
  //     });
  
  //     if (!response.ok) {
  //       throw new Error(`Failed to upload ${type}`);
  //     }
  
  //     const { imageUrls } = await response.json();
  //     console.log('imageUrls:', imageUrls)
  //     console.log("Full Response:", await response.json());
      
  //     if (imageUrls && imageUrls.length > 0) {
  //       if (isWallPicture) {
  //         setFreelancerFormData((prev) => ({
  //           ...prev,
  //           wallPicture: imageUrls[0], // Update the wall picture URL
  //         }));
  //         await fetchFreelancerdetails();
  //       } else if (isProfilePic) {
  //         setFormData((prev) => {
  //           const updatedFormData = { ...prev, profilePic: imageUrls[0] };
  //           console.log("Updated formData:", updatedFormData);
  //           return updatedFormData;
  //         });
  //         await fetchUserdetails();
  //       }
  //     } else {
  //       console.error("No image URL returned from API.");
  //     }
      
      
  //   } catch (error) {
  //     console.error(`Error uploading ${type}:`, error.message);
  //   } finally {
  //     if (isWallPicture) {
  //       setIsWallPictureLoading(false); // Hide loading for wall picture
  //     } else if (isProfilePic) {
  //       setIsProfilePicLoading(false); // Hide loading for profile picture
  //     }
  //   }
  // };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const formDataCopy = { ...freelancerFormData };

      const response = await fetch(summaryApi.updateFreelancerAccount.url, {
        method: summaryApi.updateFreelancerAccount.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataCopy),
      });
  
      const result = await response.json();
      if (response.ok) {
        toast.success('Profile updated successfully!');
        await fetchUserdetails();
        navigate('/freelancer');
      } else {
        toast.error(result.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('An unexpected error occurred');
      setIsLoading(false)
    } finally {
      setIsSubmitting(false);
      setIsLoading(false)
    }

    try {
      const UserDetailResponse = await fetch(summaryApi.updateUserAccount.url, {
        method: summaryApi.updateUserAccount.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          profilePic: formData.profilePic,
          name: formData.name,
          mobileNumber : formData.mobileNumber
        }),
      });
  
      const result = await UserDetailResponse.json();
  
      if (UserDetailResponse.ok) {
        toast.success('Profile picture updated successfully!');
        await fetchUserdetails();
      } else {
        toast.error(result.message || 'An error occurred');
      }

    } catch (error) {
      console.error('Error updating profile picture:', error);
      toast.error('An unexpected error occurred');
    }
    
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;

    setSelectedCategory(category);

    // Initialize subcategories for the new category if not already present
    setSelectedSubcategories((prev) => ({
      ...prev,
      [freelancerFormData.categories]: prev[category] || [],
    }));
  };

  const handleSubcategoryChange = (e) => {
    const { value, checked } = e.target;

    setSelectedSubcategories((prev) => {
      const currentSubs = prev[selectedCategory] || [];
      const updatedSubs = checked
        ? [...currentSubs, value]
        : currentSubs.filter((sub) => sub !== value);

      return { ...prev, [selectedCategory]: updatedSubs };
    });

    freelancerFormData.categories((prevData) => {
      // Update `formData.subcategories` with all selected subcategories from all categories
      const updatedAllSubcategories = Object.values({
        ...selectedSubcategories,
        [selectedCategory]: checked
          ? [...(selectedSubcategories[selectedCategory] || []), value]
          : (selectedSubcategories[selectedCategory] || []).filter(
              (sub) => sub !== value
            ),
      }).flat();

      return { ...prevData, subcategories: updatedAllSubcategories };
    });
  };


  const handleDeleteProductImage = async(index) =>{
    const newProductImage = [...freelancerFormData?.pastProjects]
    newProductImage.splice(index,1)

    setFormData((preve) => {
     return {
         ...preve,
         productImage : [...newProductImage]
     }
    })
 }


  return (
    <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg p-6 md:p-[40px]">
      <h2 className="text-slate-500 text-2xl font-bold text-center mb-6 border p-2 md:px-10 rounded-md bg-orange-300">Update Your Profile</h2> 
    <div className="relative w-full h-48 md:h-64 bg-gray-200 rounded-md overflow-hidden">
      {isWallPictureLoading ? (
        <div className="w-full h-full flex items-center justify-center bg-white">
          <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-blue-500"></div>
        </div>
      ) : freelancerFormData.wallPicture ? (
        <img
        src={`${freelancerFormData.wallPicture}?t=${Date.now()}` || "default-wall.png"}
          alt="Wall Picture"
          className="w-full h-full object-cover"
          onError={(e) => (e.target.src = "default-wallPicture.png")} 
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center text-gray-500">
          Upload Wall Picture
        </div>
      )}
      {/* Edit Icon for Wall Picture */}
      <button
        onClick={() => document.getElementById("wallPictureInput").click()}
        className="absolute top-2 left-2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
      >
        <FaPen size={14} />
      </button>
      <input
        id="wallPictureInput"
        type="file"
        name="wallPicture"
        onChange={(e) => handleUpload(e, "wallPicture")}
        className="hidden"
      />

      {/* Profile Picture*/}
      <div className="absolute right-4 bottom-[-40px] transform -translate-y-1/2 w-24 h-24 md:w-32 md:h-32 border-4 border-white rounded-full overflow-hidden bg-gray-300">
          {isProfilePicLoading  ? (
        <div className="w-full h-full flex items-center justify-center bg-white">
          <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-blue-500"></div>
        </div>
        ) : formData.profilePic ? (
          <img
            src={`${formData.profilePic}?t=${Date.now()}` || "default-profile.png"} 
            alt="Profile Picture"
            className="w-full h-full object-cover"
            onError={(e) => (e.target.src = "default-profile.png")} 
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center text-gray-500">
            Upload Profile
          </div>
        )}
        {/* Edit Icon for Profile Picture */}
        <button
          onClick={() => document.getElementById("profilePicInput").click()}
          className="absolute bottom-0 right-11  bg-slate-800 bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
        >
          <FaPen size={12} />
        </button>
        <input
          id="profilePicInput"
          type="file"
          name="profilePic"
          onChange={(e) => handleUpload(e, "profilePic")}
          className="hidden"
        />
      </div>
    </div>

    <div className="mt-12"> {/* Pushes form content below the profile pic */}
      {
        isLoading ? (
          loadingList.map((_, index) => (
            <div
              className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'
              key={index}
            >
              <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse' />
              <div className='p-4 grid gap-3'>
                <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
                <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2'></p>
                <div className='flex gap-3'>
                  <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                  <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                </div>
                <button className='text-sm text-white px-3 rounded-full bg-slate-200 py-2 animate-pulse'></button>
              </div>
            </div>
          ))
        ) 
        : 
        ( <>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Real Names</label>
              <input
                type="text"
                name="name"
                value={formData.name || ''}
                onChange={handleUserChange}
                className="block w-full text-xl rounded border p-1 text-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Mobile Number</label>
              <input
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber || ''}
                onChange={handleUserChange}
                className="block w-full text-xl rounded border p-1 text-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
              />
            </div>
            {/* <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Profile Picture</label>
              <input
                type="file"
                name="profilePic"
                onChange={(e) => handleUpload(e, "profilePic")}
                className="block w-full text-sm text-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Wall Picture</label>
              <input
                type="file"
                name="wallPicture"
                
                onChange={(e) => handleUpload(e, "wallPicture")}
                className="block w-full text-sm text-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
              />
            </div> */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Work Title</label>
              <input
                type="text"
                name="workTitle"
                value={freelancerFormData.workTitle || ''}
                onChange={handleFreelanChange}
                className="w-full border-blue-300 p-1 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>

            {/* Category Selection */}
            <div>
              <label className="block font-medium mb-2">Select Category:</label>
              <select
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="w-full border-blue-300 p-2 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              >
                <option value="">Choose a category</option>
                {ServiceCategory.map((category) => (
                  <option key={category.id} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Subcategory Selection */}
            {selectedCategory && (
              <div>
                <label className="block font-medium mb-2 mt-4">Select Subcategories:</label>
                <div className="grid grid-cols-2 gap-2">
                  {subCategories[selectedCategory]?.map((subCategory) => (
                    <div key={subCategory} className="flex items-center">
                      <input
                        type="checkbox"
                        id={subCategory}
                        name="subcategories"
                        value={subCategory}
                        checked={selectedSubcategories[selectedCategory]?.includes(
                          subCategory
                        )}
                        onChange={handleSubcategoryChange}
                        className="w-4 h-4 mr-2"
                      />
                      <label htmlFor={subCategory} className="text-gray-700">
                        {subCategory}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Display Selected Subcategories */}
            <div className="mt-4">
              <label className="block font-medium mb-2">Selected Subcategories:</label>
              <div className="flex flex-wrap gap-2">
                {freelancerFormData.subcategories.map((subcategory) => (
                  <div
                    key={subcategory}
                    className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full border border-blue-300 text-sm"
                  >
                    {subcategory}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Skills</label>
              <textarea
                name="skills"
                value={freelancerFormData.skills || ''}
                onChange={handleFreelanChange}
                placeholder="E.g., JavaScript, React, Node.js"
                className="w-full p-2 border-blue-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              ></textarea>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Experiences and projects</label>
              <textarea
                name="experiences"
                value={freelancerFormData.experiences || ''}
                onChange={handleFreelanChange}
                className="w-full p-2 border-blue-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              ></textarea>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Completed Projects</label>
              <input
                type="file"
                name="pastProjects"
                onChange={(e) => handleUpload(e, "pastProjects")}
                multiple
                className="block w-full text-sm text-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
              />
                {
                  freelancerFormData?.pastProjects[0] ? (
                    <div className='flex items-center gap-3 mt-3'>
                        
                        { freelancerFormData?.pastProjects.map((el)=>{
                                return (
                                    <div key={el} className='relative group'>
                                        <img src={el} 
                                            alt={el} 
                                            width={80} 
                                            height={80} 
                                            className='bg-slate-100 border cursor-pointer'
                                            required
                                            onClick={() => {
                                            setOpenFullScreenImage(true)
                                            setFullScreenImage(el)
                                        }} />

                                        <div className='bg-orange-500 text-white rounded-full cursor-pointer absolute bottom-0 right-0 p-1 hidden group-hover:block' onClick={() => handleDeleteProductImage(el)}> 
                                            <MdDelete /> 
                                        </div>
                                    </div>
                                    
                                    
                                )
                        })}
                    </div>
                )
                
                : 
                (
                    <p className='text-red-600 text-xs'> *please upload product image* </p>
                )
            }
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">References</label>
              <textarea
                name="references"
                value={freelancerFormData.references || ''}
                onChange={handleFreelanChange}
                className="w-full p-2 border-blue-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              ></textarea>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Education</label>
              <textarea
                name="education"
                value={freelancerFormData.education || ''}
                onChange={handleFreelanChange}
                className="w-full p-2 border-blue-300 border  rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              ></textarea>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Qualification</label>
              <textarea
                name="qualifications"
                value={freelancerFormData.qualifications || ''}
                onChange={handleFreelanChange}
                className="w-full p-2 border-blue-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              ></textarea>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Tools</label>
              <textarea
                name="tools"
                value={freelancerFormData.tools || ''}
                onChange={handleFreelanChange}
                className="w-full p-2 border-blue-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              ></textarea>
            </div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Hourly Rate</label>
            <div className='flex item-center'>
              <select
                name='currency'
                value={freelancerFormData.currency}
                onChange={handleFreelanChange}
                className='p-2 bg-slate-100 border rounded mr-2'
                required
            >
                <option value='USD'>$ (USD)</option>
                <option value='INR'>₹ (INR)</option>
                <option value='NGN'>₦ (NGN)</option>
                
              </select>
              <input
                type="number"
                name="hourlyRate"
                value={freelancerFormData.hourlyRate || ''}
                onChange={handleFreelanChange}
                className=" border-blue-300 p-2 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Weekly Rate</label>
            <div className='flex items-center'>
              <select
                name='currency'
                value={freelancerFormData.currency}
                onChange={handleFreelanChange}
                className='p-2 bg-slate-100 border rounded mr-2'
                required
            >
                <option value='USD'>$ (USD)</option>
                <option value='INR'>₹ (INR)</option>
                <option value='NGN'>₦ (NGN)</option>
                
              </select>
              <input
                type="number"
                name="weeklyRate"
                value={freelancerFormData.weeklyRate || ''}
                onChange={handleFreelanChange}
                className=" border-blue-300 p-2 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>

            <label className="block text-sm font-medium text-gray-700 mb-1">Monthly Rate</label>
            <div>
            <select
                name='currency'
                value={freelancerFormData.currency}
                onChange={handleFreelanChange}
                className='p-2 bg-slate-100 border rounded mr-2'
                required
            >
                <option value='USD'>$ (USD)</option>
                <option value='INR'>₹ (INR)</option>
                <option value='NGN'>₦ (NGN)</option>
                
              </select>
              <input
                type="number"
                name="monthlyRate"
                value={freelancerFormData.monthlyRate || ''}
                onChange={handleFreelanChange}
                className=" border-blue-300 p-2 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full text-white font-semibold py-2 px-4 rounded-md shadow-sm ${
                isSubmitting
                  ? 'bg-orange-400 cursor-not-allowed'
                  : 'bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
              }`}
            >
              {isSubmitting ? 'Updating...' : 'Update Profile'}
            </button>
          </form>
        
            {/**display image full screen */}
          {  openFullScreenImage && (
          <DisplayImage 
          onClose={()=> setOpenFullScreenImage(false)} 
          imgUrl={fullScreeImage}/>
          )}
        </>
      )}
    </div>
    </div>

  );
  
};

export default FreelancerUpdateAccount;