
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import summaryApi from '../common';
import { toast } from 'react-toastify';
import Context from '../context';

const CustomerCheckout = () => {
  const user = useSelector((state) => state.user.user);
  const { fetchUserdetails, fetchUserAddToCartCount } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [loadingZip, setLoadingZip] = useState(false);

  // Store customer details (used as buyerDetails)
  const [customerDetails, setCustomerDetails] = useState({
    name: user?.name || '',
    email: user?.email || '',
    mobileNumber: user?.mobileNumber || '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
  });

  // Parse the address from user.address (if exists)
  useEffect(() => {
    if (user?.address) {
      const addressParts = user.address.split(',').map((part) => part.trim());
      let street = addressParts[0] || '';
      let city = addressParts[1] || '';
      let stateAndPostcode = addressParts[2] || '';

      let state = '';
      let postalCode = '';
      const stateParts = stateAndPostcode.split(' ');

      if (stateParts.length > 1) {
        state = stateParts[0];
        postalCode = stateParts.slice(1).join(' ');
      } else {
        state = stateAndPostcode;
      }

      setCustomerDetails((prev) => ({
        ...prev,
        name: prev.name || user.name || '',
        email: prev.email || user.email || '',
        mobileNumber: prev.mobileNumber || user.mobileNumber || '',
        street,
        city,
        state,
        postalCode,
      }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value ?? '',
    }));
  };

  const validateAddress = () => {
    const { street, city, state, postalCode } = customerDetails;
    return street && city && state && postalCode ? null : 'All address fields are required.';
  };

  // Update existing user details via API if logged in
  const updateExistingUser = async () => {
    try {
      const combinedAddress = `${customerDetails.street}, ${customerDetails.city}, ${customerDetails.state} ${customerDetails.postalCode}`;
      const payload = {
        ...customerDetails,
        address: combinedAddress,
      };

      const response = await fetch(summaryApi.updateUser.url, {
        method: summaryApi.updateUser.method,
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      
      if (responseData.success) {
        await fetchUserdetails();
        navigate('/cart');
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error('Error updating user details:', error);
      toast.error('Something went wrong while updating your details.');
    }
  };

  // Handle checkout: if user exists, update user via API; otherwise, store guest details in localStorage.
  const handleCustomerCheckout = async (e) => {
    e.preventDefault();

    if (!customerDetails.name || !customerDetails.email) {
      alert('Please fill in all the fields.');
      return;
    }

    const addressError = validateAddress();
    if (addressError) {
      toast.error(addressError);
      return;
    }

    // Combine address properties into a single string
    const combinedAddress = `${customerDetails.street}, ${customerDetails.city}, ${customerDetails.state} ${customerDetails.postalCode}`;

    // Create guestData with the combined address
    const guestData = {
      name: customerDetails.name,
      email: customerDetails.email,
      address: combinedAddress,
      mobile: customerDetails.mobileNumber,
    };

    // If user exists, update their details via API; otherwise, store as guest details
    if (user) {
      await updateExistingUser();
    } else {
      localStorage.setItem('guestDetails', JSON.stringify(guestData));
      navigate('/cart');
    }

    // Mark checkout as complete and notify user
    localStorage.setItem('checkoutCompleted', 'true');
    toast.success('Delivery details saved! Returning to cart.');
  };

  const handleLogin = () => {
    navigate('/login', { state: { redirect: '/customer-checkout' } });
  };

  return (
    <div className="container mx-auto max-w-md p-6 mt-10 border rounded shadow-lg bg-white">
      <h1 className="text-xl font-semibold text-center text-orange-600 mb-4">
        Customer Delivery Details
      </h1>
      <p className="text-slate-700 text-center mb-6">
        You can log in to your account or continue as a guest to complete your purchase.
      </p>

      <div className="flex flex-col gap-4">
        <button
          className="bg-orange-600 hover:bg-orange-500 text-white font-semibold py-2 px-4 rounded"
          onClick={() => navigate('/login', { state: { redirect: '/customer-checkout' } })}
        >
          Log In
        </button>

        <form onSubmit={handleCustomerCheckout}>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            className="w-full mb-4 px-3 py-2 border rounded"
            value={customerDetails.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="w-full mb-4 px-3 py-2 border rounded"
            value={customerDetails.email}
            onChange={handleChange}
            required
          />
          <label className="font-bold">Optional</label>
          <input
            type="tel"
            name="mobileNumber"
            placeholder="Mobile Number"
            className="w-full mb-4 px-3 py-2 border rounded"
            value={customerDetails.mobileNumber}
            onChange={handleChange}
          />

          <label className="font-bold">Delivery Address</label>
          <input
            type="text"
            name="street"
            placeholder="Street Address"
            className="w-full mb-4 px-3 py-2 border rounded"
            value={customerDetails.street}
            onChange={handleChange}
            required
          />
          <div className="flex gap-2">
            <input
              type="text"
              name="city"
              placeholder="City"
              className="w-1/2 mb-4 px-3 py-2 border rounded"
              value={customerDetails.city}
              onChange={handleChange}
              disabled={loadingZip}
              required
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              className="w-1/4 mb-4 px-3 py-2 border rounded"
              value={customerDetails.state}
              onChange={handleChange}
              disabled={loadingZip}
              required
            />
            <input
              type="text"
              name="postalCode"
              placeholder="ZIP Code"
              className="w-1/4 mb-4 px-3 py-2 border rounded"
              value={customerDetails.postalCode}
              onChange={handleChange}
              required
            />
          </div>
          <button
            type="submit"
            className={`bg-green-500 hover:bg-green-400 text-white font-semibold py-2 px-4 rounded ${loadingZip ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loadingZip}
          >
            Proceed to payment
          </button>
        </form>
      </div>
    </div>
  );
};

export default CustomerCheckout;




// import React, { useContext, useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate, useLocation } from 'react-router-dom';
// import summaryApi from '../common';
// import { toast } from 'react-toastify';
// import Context from '../context';

// const CustomerCheckout = () => {
//     const user = useSelector((state) => state.user.user);
//     const { fetchUserdetails, fetchUserAddToCartCount } = useContext(Context);
//     const navigate = useNavigate();
//     const location = useLocation();

//     const [loading, setLoading] = useState(false);
//     const [loadingZip, setLoadingZip] = useState(false);
  
    
//     // Store customer details (used as buyerDetails)
//     const [customerDetails, setCustomerDetails] = useState({
//         name: user?.name || '',
//         email: user?.email || '',
//         mobileNumber: user?.mobileNumber || '',
//         street: '',
//         city: '',
//         state: '',
//         postalCode: '',
//     });

//     // Function to parse the address when fetching from the DB
//     useEffect(() => {
//         if (user?.address) {
//             const addressParts = user.address.split(',').map(part => part.trim()); // Splitting by ", "
            
//             let street = addressParts[0] || '';
//             let city = addressParts[1] || '';
//             let stateAndPostcode = addressParts[2] || '';
    
//             // Extract state and postal code properly
//             let state = '';
//             let postalCode = '';
    
//             const stateParts = stateAndPostcode.split(' '); // Splitting state and postal code
    
//             if (stateParts.length > 1) {
//                 state = stateParts[0]; // First part is state
//                 postalCode = stateParts.slice(1).join(' '); // Remaining parts form the postal code
//             } else {
//                 state = stateAndPostcode; // Fallback in case no postal code is found
//             }
    
//             setCustomerDetails(prev => ({
//                 ...prev,
//                 name: prev.name || user.name || '',
//                 email: prev.email || user.email || '',
//                 mobileNumber: prev.mobileNumber || user.mobileNumber || '',
//                 street,
//                 city,
//                 state,
//                 postalCode
//             }));
//         }
//     }, [user]);
    


//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setCustomerDetails((prevDetails) => ({
//             ...prevDetails,
//             [name]: value ?? '',
//         }));
//     };

//     const validateAddress = () => {
//         const { street, city, state, postalCode } = customerDetails;
//         return street && city && state && postalCode ? null : 'All address fields are required.';
//     };

//     const updateExistingUser = async () => {
//         try {
//             const combinedAddress = `${customerDetails.street}, ${customerDetails.city}, ${customerDetails.state} ${customerDetails.postalCode}`;
//             const payload = {
//                 ...customerDetails,
//                 address: combinedAddress,
//             };

//             const response = await fetch(summaryApi.updateUser.url, {
//                 method: summaryApi.updateUser.method,
//                 credentials: 'include',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify(payload),
//             });

//             const responseData = await response.json();
//             console.log('update user response:', responseData)
//             if (responseData.success) {
//                 await fetchUserdetails();
//                 navigate('/cart');
//             } else {
//                 toast.error(responseData.message);
//             }
//         } catch (error) {
//             console.error('Error updating user details:', error);
//         }
//     };

//     const handleCustomerCheckout = (e) => {
//         e.preventDefault();

//         if (!customerDetails.name || !customerDetails.email) {
//             alert('Please fill in all the fields.');
//             return;
//         }

//         const addressError = validateAddress();
//         if (addressError) {
//             toast.error(addressError);
//             return;
//         }

//         // Combine address properties into a single string
//         const combinedAddress = `${customerDetails.street}, ${customerDetails.city}, ${customerDetails.state} ${customerDetails.postalCode}`;

//         // Create guestData with the combined address
//         const guestData = {
//             name: customerDetails?.name,
//             email: customerDetails?.email,
//             address: combinedAddress,
//             mobile: customerDetails?.mobileNumber
//         };

//         // Store in localStorage and navigate to cart
//         localStorage.setItem('guestDetails', JSON.stringify(guestData));
//           // Store checkout completion in LocalStorage
//         localStorage.setItem("checkoutCompleted", "true");

//         toast.success("Delivery details saved! Returning to cart.");
//         navigate('/cart');
//     };

//     const handleLogin = () => {
//         navigate('/login', { state: { redirect: '/customer-checkout' } });
//     };


//     return (
//         <div className="container mx-auto max-w-md p-6 mt-10 border rounded shadow-lg bg-white">
//             <h1 className="text-xl font-semibold text-center text-orange-600 mb-4">Customer Delivery Details</h1>
//             <p className="text-slate-700 text-center mb-6">
//                 You can log in to your account or continue as a guest to complete your purchase.
//             </p>

//             <div className="flex flex-col gap-4">
//                 <button
//                     className="bg-orange-600 hover:bg-orange-500 text-white font-semibold py-2 px-4 rounded"
//                     onClick={() => navigate('/login', { state: { redirect: '/customer-checkout' } })}
//                 >
//                     Log In
//                 </button>

//                 <form onSubmit={handleCustomerCheckout}>
//                     <input
//                         type="text"
//                         name="name"
//                         placeholder="Full Name"
//                         className="w-full mb-4 px-3 py-2 border rounded"
//                         value={customerDetails.name}
//                         onChange={handleChange}
//                         required
//                     />
//                     <input
//                         type="email"
//                         name="email"
//                         placeholder="Email"
//                         className="w-full mb-4 px-3 py-2 border rounded"
//                         value={customerDetails.email}
//                         onChange={handleChange}
//                         required
//                     />
//                     <label className='font-bold'>Optional</label>
//                      <input
//                         type="tel"
//                         name="mobileNumber"
//                         placeholder="mobileNumber"
//                         className="w-full mb-4 px-3 py-2 border rounded"
//                         value={customerDetails.mobileNumber}
//                         onChange={handleChange}
//                     />

//                     <label className='font-bold'>Delivery Address</label>
//                     <input
//                         type="text"
//                         name="street"
//                         placeholder="Street Address"
//                         className="w-full mb-4 px-3 py-2 border rounded"
//                         value={customerDetails.street}
//                         onChange={handleChange}
//                         required
//                     />
//                     <div className="flex gap-2">
//                         <input
//                             type="text"
//                             name="city"
//                             placeholder="City"
//                             className="w-1/2 mb-4 px-3 py-2 border rounded"
//                             value={customerDetails.city}
//                             onChange={handleChange}
//                             disabled={loadingZip}
//                             required
//                         />
//                         <input
//                             type="text"
//                             name="state"
//                             placeholder="State"
//                             className="w-1/4 mb-4 px-3 py-2 border rounded"
//                             value={customerDetails.state}
//                             onChange={handleChange}
//                             disabled={loadingZip}
//                             required
//                         />
//                         <input
//                             type="text"
//                             name="postalCode"
//                             placeholder="ZIP Code"
//                             className="w-1/4 mb-4 px-3 py-2 border rounded"
//                             value={customerDetails.postalCode}
//                             onChange={handleChange}
//                             required
//                         />
//                     </div>
//                     <button
//                         type="submit"
//                         className={`bg-green-500 hover:bg-green-400 text-white font-semibold py-2 px-4 rounded ${loadingZip ? 'opacity-50 cursor-not-allowed' : ''}`}
//                         disabled={loadingZip}
//                     >
//                         Proceed to payment
//                     </button>
//                 </form>
//             </div>
//         </div>
//     );

   
// };

// export default CustomerCheckout;




